export function trimLeadingZeros(officeNum) {
    if (officeNum) {
        return officeNum.toUpperCase().trim().replace(/^0+/, '');
    }
    return officeNum;
}
export function getOffice(officesInfo, officeNum, officeType) {
    if (officesInfo && officesInfo.length > 0) {
        let filteredOffices = officesInfo.filter(office => trimLeadingZeros(office.officeNumber) === trimLeadingZeros(officeNum) &&
            office.officeType.toUpperCase() === officeType.toUpperCase());
        return filteredOffices[0];
    }
}
export function getOfficeCustomizedInfo(contactInfo, vanityContactField, selectedContactField) {
    if (contactInfo != null && contactInfo.statusContactDetails != null) {
        if (contactInfo.statusContactDetails[vanityContactField] !== "") {
            return contactInfo.statusContactDetails[vanityContactField];
        }
        return contactInfo.statusContactDetails[selectedContactField];
    }
    return null;
}
