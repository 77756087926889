import React from 'react';
import { Col, Radio, RadioGroup, Row, themes } from '@digitools/honest-react';
import { fieldRequired } from 'src/packages/eoi/utils/FormFieldValidations';
import useCliDataContext from '../../../hooks/useCliDataContext';
import AboutYourAbsenceText from 'src/packages/cli/constants/AboutYourAbsenceText';
import useTranslate from 'src/hooks/useTranslate';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import useExperience from 'src/hooks/useExperience';
import { Experience } from 'src/types/Experience';
//TODO: May need to hide & default for certain formState!
const HospitalizationIndicator = (props) => {
    const { absenceData } = useCliDataContext();
    const { experience } = useExperience();
    const { t } = useTranslate();
    const { formLabels } = AboutYourAbsenceText;
    const hospitalizationIndicatorLabel = CustomEmployeeText(experience === Experience.EMPLOYEE
        ? t(formLabels.hospitalizationIndicatorEE)
        : `${t(formLabels.hospitalizationIndicatorER)}`);
    return (<>
      <Row data-testid='hospitalizationIndicator' className='mb-3'>
        <Col xs={12}>
          <RadioGroup label={hospitalizationIndicatorLabel} field={'hospitalizationIndicator'} 
    // TODO: pending honest change to use existing optional label styling for optional
    // optional={experience === Experience.EMPLOYER}
    // language={language}
    legendStyles={{ marginBottom: '.5rem' }} theme={themes.lincoln} validateOnBlur={true} data-private={true} validateOnChange={true} validate={experience === Experience.EMPLOYEE ? fieldRequired : undefined} initialValue={absenceData && absenceData.hospitalizationIndicator}>
            <Radio containerStyle={{ marginBottom: '.5rem' }} data-testid={'hi-yes'} key={'hi-yes'} data-private={true} name='hospitalizationIndicator' label={t(AboutYourAbsenceText.formLabels.yes)} value={'Y'}/>
            <Radio containerStyle={{ marginBottom: '.5rem' }} data-testid={'hi-no'} key={'hi-no'} data-private={true} name='hospitalizationIndicator' label={'No'} value={'N'}/>
          </RadioGroup>
        </Col>
      </Row>
    </>);
};
export default HospitalizationIndicator;
