import { Row, Col, Button, Icon, Spinner, Alert } from "@digitools/honest-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TEALIUM_EVENT } from "src/constants/tealiumConstants";
import useTealium from "src/hooks/useTealium";
import ReviewField from "src/packages/cli/pages/review/ReviewField";
import checkDate from "src/packages/status/utilities/checkDate";
import titleCase from "src/packages/status/utilities/titleCase";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Card from "src/components/Cards/Card";
import StatusBadge, { getStatusColor } from "src/components/Cards/StatusBadge";
import WatchlistCheckBox from "../../WatchlistCheckBox/WatchlistCheckBox";
import CategorizationDocumentUpload from "src/components/documentUpload/CategorizationDocumentUpload";
import DocumentUpload from "src/components/documentUpload/DocumentUpload";
import { getEnvVariable } from "src/utils/envUtils";
import useExperience from '../../../hooks/useExperience';
import axios from "axios";
import FileSaver from "file-saver";
import useAuthentication from "src/hooks/useAuthentication";
const StatusMessage = styled('div') `
    font-size: .9rem;
    margin-left: .5rem;
    margin-right: .5rem;
`;
const StyledCol = styled(Col) `
    @media (max-width: 480px){
        display: flex;
        justify-content: space-around;
        flex-direction: column-reverse;
    }
`;
const LeaveInfoCard = ({ leave, associatedClaim, status, type, reason, category, statusDef, print, leaveReports, leaveId, empNum, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useTealium();
    const { experience } = useExperience();
    const { authHeader } = useAuthentication();
    const [isDownloadError, setIsDownloadError] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const downloadLeaveDetails = async () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.DOWNLOAD,
            event_name: 'download leave details',
            event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
            event_version: 'details'
        });
        try {
            setIsDownloading(true);
            const response = await axios.get(`/status-service/report/${empNum}/${leaveId}/leaveSummary`, {
                responseType: 'blob',
                headers: { Authorization: authHeader() },
            });
            const reportName = `${leaveId}-LeaveDetails.pdf`;
            const blob = new Blob([response.data]);
            setIsDownloadError(false);
            setIsDownloading(false);
            FileSaver.saveAs(blob, reportName);
        }
        catch (error) {
            setIsDownloadError(true);
            setIsDownloading(false);
        }
    };
    const DownloadLeaveDetailsError = () => (<Alert data-testid={'download-leave-details-alert'} type={'error'} closeable={true} onClose={() => setIsDownloadError(false)}>
            <span>
                <strong>Error!</strong> There was a problem downloading the leave details. Please try again later.
            </span>
        </Alert>);
    return (<div data-testid={'leaveInfoCard'} className="mb-3">
            <Card heading={`${titleCase(t('leaveDetailsText'))} #${leave.lveNum}`} secondaryHeading={<StatusBadge status={status}>{status}</StatusBadge>} selectable={false} fontSize='large' elemType='h2' borderColor={getStatusColor(status)}>
                {statusDef &&
            <Row className="mb-2">
                        <StatusMessage>
                            <Icon size='sm' name='important-message' color='secondary' className='ml-2 mr-2'/>
                                {statusDef}
                        </StatusMessage>
                    </Row>}
                <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                    {/* @ts-ignore */}
                    <ReviewField style={{ flexGrow: '.5', minWidth: '25%' }} small label={titleCase(t('statusText').toString())} value={status}/>
                    {/* @ts-ignore */}
                    <ReviewField style={{ flexGrow: '.5', minWidth: '25%' }} small label={titleCase(t('coverageText').toString())} value={'leave'}/>
                    {leave.lveCloseDte &&
            //@ts-ignore
            <ReviewField style={{ flexGrow: '.5', minWidth: '25%' }} small label={titleCase(t('closedDateText').toString())} value={checkDate(leave.lveCloseDte)}/>}
                    {/* @ts-ignore */}
                    <ReviewField style={{ flexGrow: '.5', minWidth: '25%' }} small label={titleCase(t('leaveTypeText').toString())} value={type}/>
                    {/* @ts-ignore */}
                    <ReviewField style={{ flexGrow: '.5', minWidth: '25%' }} small label={titleCase(t('leaveReasonText').toString())} value={reason}/>
                    {/* @ts-ignore */}
                    <ReviewField style={{ flexGrow: '.5', minWidth: '25%' }} small label={titleCase(t('leaveCategoryText').toString())} value={category}/>
                    <ReviewField 
    //@ts-ignore
    style={{ flexGrow: '1' }} small label={titleCase(t('associatedClaimText').toString())} value={Object.keys(associatedClaim).length !== 0 ? (<Link to={associatedClaim.to} onClick={() => trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
                event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
                event_name: associatedClaim.claimNum,
                event_results: 'claim',
                event_version: 'details',
            })}>
                                    {associatedClaim.claimNum}
                                </Link>) : null}/>
                </div>
                <Row className="mt-3">
                    {leaveReports && leaveReports.length > 0 && (<StyledCol md={3} size={12} sm={12} className={'mb-3'}>
                            <Button data-testid={'leaveAppliedReportButton'} onClick={() => print()} size={'medium'} type={'button'} buttonType="secondary" className="mb-2" style={{ textWrap: 'balance' }}>
                                {t('leaveAppliedReportText')}
                            </Button>
                        </StyledCol>)}
                    {(getEnvVariable('enableCategorizationForS1') && experience === 'EE') ? (<StyledCol md={leaveReports && leaveReports.length > 0 ? 3 : 4} size={12} sm={12} className={'mb-3'}>
                            <CategorizationDocumentUpload eventNum={leaveId} productName={'LEAVE'} displayLabel={true} renderAsLink={false} key={'CategorizationDocumentUploadLeave' + leaveId} data-testid="CategorizationDocumentUploadLeave" page='details'/>
                        </StyledCol>) : (<StyledCol md={leaveReports && leaveReports.length > 0 ? 3 : 4} size={12} sm={12} className={'mb-3'}>
                            <div style={{ textWrap: leaveReports?.length > 0 ? 'balance' : 'nowrap' }}>
                            <DocumentUpload eventNum={leaveId} productName={'LEAVE'} displayLabel={true}/>
                            </div>
                        </StyledCol>)}
                    {experience === 'ER' && (<>
                            <StyledCol md={leaveReports && leaveReports.length > 0 ? 3 : 4} size={12} sm={12} className={'mb-3'}>
                                <Button data-testid={'downloadLeaveDetails'} buttonType={'secondary'} size={'medium'} type={'button'} onClick={downloadLeaveDetails} disabled={isDownloading} aria-label='Download Leave Details' style={{ textTransform: 'inherit', textWrap: leaveReports?.length > 0 ? 'balance' : 'nowrap' }}>
                                        {isDownloading ? (<Spinner id='leave-info-spinner' size='small' style={{ float: 'left', margin: '0px', marginTop: '-.25rem', marginRight: '.5rem' }}/>) : (<Icon name='download' color={'secondary'} style={{ paddingRight: '.25rem' }}/>)}
                                        Download Leave Details
                                </Button>
                            </StyledCol>
                            <StyledCol md={leaveReports && leaveReports.length > 0 ? 3 : 4} size={12} sm={12} className={'mb-3'}>
                                <WatchlistCheckBox eventId={leaveId} eventType={'LEAVE'} isNewCard={true}/>
                            </StyledCol>
                        </>)}
                </Row>
                {isDownloadError && <DownloadLeaveDetailsError />}
            </Card>
        </div>);
};
export default LeaveInfoCard;
