const StatusText = {
    STATUS: {
        open: {
            english: 'Open',
            spanish: 'Open',
        },
        closed: {
            english: 'Closed',
            spanish: 'Closed',
        },
        pending: {
            english: 'Pending',
            spanish: 'Pending',
        },
        approved: {
            english: 'Approved',
            spanish: 'Approved',
        },
        denied: {
            english: 'Denied',
            spanish: 'Denied',
        },
    },
    LINKS: {
        returnToWork: {
            english: 'Return to work',
            spanish: 'volver al trabajo',
        },
        viewDetails: {
            english: 'View details',
            spanish: 'Ver detalles',
        },
        uploadDocuments: {
            english: 'Upload documents',
            spanish: 'Subir documentos',
        },
    },
    ALERTS: {
        noClaimsOrLeaves: {
            english: 'No claims or leaves were found',
            spanish: 'No se encontraron reclamaciones ni hojas.',
        },
        fetchClaimsErrorER: {
            english: "Something went wrong while retrieving claims and leaves for this employee",
            spanish: 'Algo salió mal al recuperar reclamos y licencias para este empleado',
        },
        fetchClaimsErrorEE: {
            english: "Something went wrong while retrieving your claims and leaves",
            spanish: 'Algo salió mal al recuperar tus reclamos y permisos.',
        }
    }
};
export default StatusText;
