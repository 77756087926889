import React from 'react';
import useGet from '../../../../../hooks/useGet';
import { useTranslation } from 'react-i18next';
import { Heading, Alert, Spinner, } from '@digitools/honest-react';
import getLeaveBalanceAsOfDate from 'status/utilities/getLeaveBalanceAsOfDate';
import LeaveBalanceCard from './LeaveBalanceCard';
import titleCase from 'src/packages/status/utilities/titleCase';
const Error = ({ error, noData }) => {
    const notFound = noData || (error.response && error.response.status === 404);
    return (<Alert type={notFound ? 'info' : 'error'}>
      {notFound ? ('There is no leave balance data to display at this time.') : (<span>
          <strong>Error!</strong>&nbsp;Something went wrong when loading balance.
        </span>)}
    </Alert>);
};
const LeaveBalance = ({ leaveId, leave }) => {
    const { t } = useTranslation();
    const { error: leaveRemainError, loading: loadingLeaveRemain, data: leaveRemainData, } = useGet({
        url: '/status-service/storproc/search/leaveRemain',
        options: {
            params: {
                leaveId,
                leaveType: leave.lveType,
            },
        },
    });
    if (loadingLeaveRemain) {
        return <Spinner id='leave-balance-spinner'/>;
    }
    if (leaveRemainError || (leaveRemainData && leaveRemainData.content.length === 0)) {
        return <Error error={leaveRemainError} noData={leaveRemainData && leaveRemainData.content.length === 0}/>;
    }
    return (<div>
      <div className='mb-2'>
        <Heading color={'primaryDark'} elemType='h2' style={{ fontSize: '1.625rem' }}>
          {' '}
          {`${titleCase(t('leaveBalanceText'))}`}
        </Heading>
        <Heading className='mt-1' color={'grayDarker'} elemType='h3' style={{ fontSize: '1.125rem' }}>
          {`${t('asOfText')} ${getLeaveBalanceAsOfDate(leave)}`}
        </Heading>
      </div>
      <hr className='mt-2'/>
      <LeaveBalanceCard leaveBalanceList={leaveRemainData.content}/>
      <div>{t('programHoursDescriptionText')}</div>
    </div>);
};
export default LeaveBalance;
