// import pdfMake from 'pdfmake';
import getProductAttributeCode from 'status/utilities/getProductAttributeCode';
import getLeaveProgramType from 'status/utilities/getLeaveProgramType';
import getLeaveStatus from 'status/utilities/getLeaveStatus';
import getLeaveStatusReason from 'status/utilities/getLeaveStatusReason';
import getLeaveBalanceAsOfDate from 'status/utilities/getLeaveBalanceAsOfDate';
import checkDate from 'status/utilities/checkDate';
import getDay from 'status/utilities/getDay';
import mmddyyyyToDate from 'status/utilities/mmddyyyyToDate';
import { createPdf } from 'pdfmake/build/pdfmake.min'; //Have to import just the minified file or ENTIRE dependency comes into the bundle

const generatePDF = (headerTab, tabContent, widthDefinitions, fileName) => {
  let docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [40, 60, 40, 60],
    content: [
      {
        table: {
          widths: ['*', '*'],
          body: headerTab,
        },
        layout: 'noBorders',
      },
      '\n\n',
      {
        table: {
          headerRows: 1,
          widths: widthDefinitions,
          body: tabContent,
        },
        layout: 'noBorders',
      },
    ],
    footer: function (currentPage) {
      return { text: currentPage.toString(), style: ['footer'] };
    },
    styles: {
      header: {
        fontSize: 10,
        bold: true,
        margin: [0, 0, 0, 0],
      },
      secHeader: {
        alignment: 'right',
        bold: true,
        fontSize: 10,
      },
      footer: {
        fontSize: 8,
        bold: true,
        alignment: 'center',
      },
    },
  };
  const fonts = {
    // download default Roboto font from cdnjs.com
    Roboto: {
      normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
      bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
      italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
      bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
  }
  createPdf(docDefinition, null, fonts).download(fileName);
  // pdfMake.createPdf(docDefinition).download(fileName); //TODO: Cleanup 
};

const downloadLeaveAppliedReport = (reports, language, prodAttCodes, empName, t) => {
  let widthDefinitions = ['8%', '10%', '25%', '15%', '10%', '8%', '8%', '8%', '8%'];

  let lveList = [];
  let lveArray = [];

  lveArray.push({ text: t('leaveIdText'), bold: true, fontSize: 9 });
  lveArray.push({ text: t('leaveTypeText'), bold: true, fontSize: 9 });
  lveArray.push({ text: t('leaveReasonText'), bold: true, fontSize: 9 });
  lveArray.push({ text: t('leaveCatagoryText'), bold: true, fontSize: 9 });
  lveArray.push({ text: t('dateText'), bold: true, fontSize: 9 });
  lveArray.push({ text: t('dayText'), bold: true, fontSize: 9 });
  lveArray.push({ text: t('programText'), bold: true, fontSize: 9 });
  lveArray.push({ text: t('hoursText'), bold: true, fontSize: 9 });
  lveArray.push({ text: t('leaveAppliedReceivedText') + '\n\n', bold: true, fontSize: 9 });

  lveList.push(lveArray);

  reports.forEach(report => {
    lveArray = [];
    lveArray.push({ text: report.leaveId, fontSize: 8 });

    lveArray.push({
      text: getProductAttributeCode(language, prodAttCodes, 'Leave', 'Type', report.leaveType),
      fontSize: 8,
    });

    lveArray.push({
      text: getProductAttributeCode(language, prodAttCodes, 'Leave', 'Reason', report.leaveReason),
      fontSize: 8,
    });

    lveArray.push({
      text: getProductAttributeCode(language, prodAttCodes, 'Leave', 'Category', report.leaveCategory),
      fontSize: 8,
    });

    lveArray.push({ text: report.date === '01/01/1900' ? '' : report.date, fontSize: 8 });

    const day = report.date && report.date != '01/01/1900' ? getDay(mmddyyyyToDate(report.date)) : '';

    lveArray.push({ text: day, fontSize: 8 });

    lveArray.push({ text: report.program, fontSize: 8 });

    lveArray.push({ text: report.leaveHours, fontSize: 8 });

    lveArray.push({
      text: report.leaveReceivedDate === '01/01/1900' ? '' : report.leaveReceivedDate,
      fontSize: 8,
    });
    lveList.push(lveArray);
  });

  let headerTab = [];
  let headContent = [];
  headContent.push({ text: t('leaveAppliedReportText'), style: ['header'] });
  headContent.push({ text: empName, style: ['secHeader'] });
  headerTab.push(headContent);

  generatePDF(headerTab, lveList, widthDefinitions, 'TimeAppliedReport.pdf');
};

const downloadLeaveDates = (leaveDates, leave, empName, t) => {
  let widthDefinitions = ['12%', '12%', '12%', '12%', '12%', '12%', '12%', '16%'];

  let lveList = [];
  let lveArray = [];

  lveArray.push({ text: t('dateText'), bold: true, fontSize: 9 });
  lveArray.push({ text: t('hoursText'), bold: true, fontSize: 9 });
  lveArray.push({ text: t('programText'), bold: true, fontSize: 9 });
  lveArray.push({ text: t('statusText'), bold: true, fontSize: 9 });
  lveArray.push({ text: t('reasonText'), bold: true, fontSize: 9 });
  lveArray.push({ text: t('reportedText'), bold: true, fontSize: 9 });
  lveArray.push({ text: t('addedToLeaveText'), bold: true, fontSize: 9 });
  lveArray.push({ text: t('lastModifiedText'), bold: true, fontSize: 9 });

  lveList.push(lveArray);

  leaveDates.forEach(leaveDate => {
    lveArray = [];
    lveArray.push({ text: leaveDate.date, fontSize: 8 });
    lveArray.push({
      text: `${Math.floor(leaveDate.duration / 60)}:${(leaveDate.duration % 60).toString().padStart(2, '0')}`,
      fontSize: 8,
    });
    lveArray.push({ text: getLeaveProgramType(leaveDate), fontSize: 8 });
    lveArray.push({ text: getLeaveStatus(leaveDate), fontSize: 8 });
    lveArray.push({ text: getLeaveStatusReason(leaveDate), fontSize: 8 });
    lveArray.push({ text: checkDate(leaveDate.reportedOn), fontSize: 8 });
    lveArray.push({ text: checkDate(leaveDate.addedOn), fontSize: 8 });
    lveArray.push({ text: checkDate(leaveDate.updatedOn), fontSize: 8 });

    lveList.push(lveArray);
  });

  let headerTab = [];
  let headContent = [];
  headContent.push({
    text: `${t('datesTakenText')} ${t('asOfText')} ${getLeaveBalanceAsOfDate(leave)}`,
    style: ['header'],
  });
  headContent.push({ text: empName, style: ['secHeader'] });
  headerTab.push(headContent);

  generatePDF(headerTab, lveList, widthDefinitions, 'LeaveDatesTaken.pdf');
};

export { downloadLeaveDates, downloadLeaveAppliedReport };
