import React from 'react';
import { Alert, TabContainer, TabSection } from '@digitools/honest-react';
import { useAuth0, useExperience } from 'status/hooks';
import AllTable from './AllTable';
import Accommodation from './Accommodation';
import UATable from './UnplannedAbsence/UATable';
import EoiInfo from 'status/components/pages/EOI/EoiInfo';
import queryString from 'query-string';
import useGoogleAnalytics from '../../../../../hooks/useGoogleAnalytics';
import removeDuplicates from 'status/utilities/removeDuplicates';
import { useTranslation } from 'react-i18next';
import { urlCheckAndReplaceEncodedValues } from '../../../../../utils/gaUtils';
import ClaimsAndLeaves from './ClaimsAndLeaves';
import useStatusDataContext from 'src/packages/status/hooks/useStatusDataContext';
import { getEnvVariable } from 'src/utils/envUtils';
import LegacyPreference from './LegacyPreference';
import { ExperienceCode } from 'src/types/Experience';
// Helper function to determine if we should show claims and leaves
const showClaimsAndLeavesTabs = (userMeta, experience) => {
    return experience === 'ER' || userMeta.applicationPermissions.indexOf('statusEE.claimLeaveStatus') > -1;
};
// Helper function to determine if we should show Accommodations Tab
const showAccommodationsTab = (userMeta, experience) => {
    return experience === 'ER'
        ? userMeta.applicationPermissions.includes('statusER.viewAccommodationStatus')
        : userMeta.applicationPermissions.includes('statusEE.viewAccommodationStatus');
};
const CoverageTabs = ({ claimData, leaveData, 
// accommodationData, was never used
empNum, employeeData, claimError, leaveError, 
// accommodationError, was never used
// statusDefinitionList, // come from context
// prodAttCodes, // come from context
eoiData, eoiError, showEOITab, empId = undefined, // can be undefined
fromTeamView, }) => {
    const { userMeta } = useAuth0();
    const { experience } = useExperience();
    const { t } = useTranslation();
    const { trackClickWithPage } = useGoogleAnalytics();
    const parsed = queryString.parse(location.search);
    const { productAttributeCodes, statusDefinitionList, legacyPref } = useStatusDataContext();
    const claims = claimData
        ? removeDuplicates([...claimData._embedded.claims, ...claimData._embedded['disability-claims']], 'clmNum')
        : [];
    const leaves = leaveData
        ? removeDuplicates([...leaveData._embedded['leave-claims'], ...leaveData._embedded.leaves], 'lveNum')
        : [];
    let tabs = [];
    if (showClaimsAndLeavesTabs(userMeta, experience)) {
        const unexpectedClaimError = claimError && claimError.response.status != '404' && claimError.response.status != '403';
        const unexpectedLeaveError = leaveError && leaveError.response.status != '404' && leaveError.response.status != '403';
        const noClaims = (claims && claims.length === 0) || (claimError && !unexpectedClaimError);
        const noLeaves = (leaves && leaves.length === 0) || (leaveError && !unexpectedLeaveError);
        // We show this tab if we have claims or leaves
        if (!noClaims || !noLeaves) {
            let headerText = 'Claims & Leaves';
            if (noClaims)
                headerText = 'Leaves';
            if (noLeaves)
                headerText = 'Claims';
            let tabContent = <></>;
            if (!getEnvVariable('statusNewCoverageDetails') || (getEnvVariable('statusNewCoverageDetails') && legacyPref && experience === ExperienceCode.EMPLOYER)) {
                tabContent = (<AllTable params={parsed} error={unexpectedClaimError || unexpectedLeaveError} claims={claims} leaves={leaves} empNum={empNum} prodAttCodes={productAttributeCodes} statusDefinitionList={statusDefinitionList} fromTeamView={fromTeamView}/>);
            }
            else if (getEnvVariable('statusNewCoverageDetails') && !legacyPref) {
                tabContent = (<ClaimsAndLeaves claims={claims} leaves={leaves} error={unexpectedClaimError || unexpectedLeaveError}/>);
            }
            tabs.push(
            // @ts-ignore
            <TabSection name='Claims &amp; Leaves' label={headerText} key='claimsleaves-tab'>
          {(getEnvVariable('statusNewCoverageDetails') && (experience === ExperienceCode.EMPLOYER && <LegacyPreference />))}
          {tabContent}
        </TabSection>);
        }
    }
    const handleTabClick = event => {
        if (event.target.type === 'button' && event.target.getAttribute('role') === 'tab') {
            const clickedTab = event.target.getAttribute('id');
            trackClickWithPage(clickedTab, urlCheckAndReplaceEncodedValues(window.location.pathname));
        }
    };
    if (empNum && showAccommodationsTab(userMeta, experience)) {
        tabs.push(
        // @ts-ignore
        <TabSection name={'Accommodation'} label={t('accommodationHeaderText')} key='accommodation-tab'>
        <Accommodation empNum={empNum} fromTeamView={fromTeamView}/>
      </TabSection>);
    }
    // We dont show eoi if the flag is false or no EOI data came back
    // If there was an error loading EOI data we want to notify the user
    if ((showEOITab && eoiError) || (showEOITab && eoiData && eoiData.length > 0)) {
        tabs.push(
        //@ts-ignore
        <TabSection name={'Evidence of insurability'} label={t('eoiHeaderText')} key='eoi-tab'>
        <EoiInfo eoi={eoiData} error={eoiError}/>
      </TabSection>);
    }
    const hasUACustomerAppPermissions = userMeta.applicationPermissions.includes('teamView') ||
        userMeta.applicationPermissions.includes('uaER') ||
        userMeta.applicationPermissions.includes('uaEE');
    if (hasUACustomerAppPermissions) {
        tabs.push(
        //@ts-ignore
        <TabSection name={'Unplanned Absence (UA)'}>
        <UATable employeeData={employeeData} empId={empId}/>
      </TabSection>);
    }
    // We have no tabs so render alert
    if (tabs.length === 0) {
        return (<>
        {/* TODO update this to check if employer has team view enabled!!! */}
        <Alert type={'error'}>There is no information to show for this employee.</Alert>
      </>);
    }
    return (<div className='mb-4 mt-4'>
      {/* TODO update this to check if employer has team view enabled!!! */}
      {/* {experience === 'ER' && <div className='mb-4 mt-4'>{t('statusAvailableText')}</div>}  //TODO: check with denise - if this should go away for ERs too*/}
      <div className='mb-4' onClick={handleTabClick}>
        <TabContainer>
          {tabs.map(tab => {
            return tab;
        })}
        </TabContainer>
      </div>
    </div>);
};
export default CoverageTabs;
