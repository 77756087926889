import React from 'react';
import useTranslate from '../../../../hooks/useTranslate';
import Heading from '@digitools/honest-react/dist/lib/components/Heading';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import QuestionTable from './QuestionTable';
import useEoiContext from '../../hooks/useEoiContext';
import { RadioGroup, Radio } from '@digitools/honest-react';
import { EoiGeneralText } from '../../i18n/EoiText';
import { Scope } from 'informed';
const getInitialValue = (questionCode, interviewAnswers) => {
    const answers = questionCode.indexOf('CRIT') !== -1 ?
        interviewAnswers.criticalIllnessAnswers :
        interviewAnswers.medicalInformationAnswers;
    if (answers) {
        const matchingAnswer = answers.filter((answer) => answer.questionCode === questionCode && answer.role === 'EMPTY').shift();
        if (matchingAnswer) {
            return matchingAnswer.value;
        }
    }
    return undefined;
};
const validateRadio = (value) => {
    return (value === undefined ? 'Required' : undefined);
};
const QuestionSection = ({ heading, tableHeading, headerNote, footerNote, description, questions, footerQuestions, yesOnly = false, hideEmployee = false, hideSpouse = false, hideDependents = false, }) => {
    const { t } = useTranslate();
    const { interviewAnswers } = useEoiContext();
    // tslint:disable-next-line: one-variable-per-declaration
    let employee = false, spouse = false, dependent = false;
    if (interviewAnswers && interviewAnswers.applicants && interviewAnswers.coverageElections) {
        employee = interviewAnswers.applicantTypes.employee && !hideEmployee;
        spouse = interviewAnswers.applicantTypes.spouse && !hideSpouse;
        dependent = interviewAnswers.applicantTypes.dependent && !hideDependents;
    }
    // TODO: make the employee/spouse/child connected to actual applicants in current interview (context)
    return (<>
      <Heading elemType={'h3'} color='grayDark' theme={themes.lincoln} data-testid={'section-heading'}>
        {t(heading)}
      </Heading>
      {description && <p>{t(description)}</p>}
      {headerNote && <p>{t(headerNote)}</p>}
      <QuestionTable title={tableHeading} employee={employee} spouse={spouse} dependent={dependent} questions={questions} yesOnly={yesOnly}/>

      {footerQuestions && footerQuestions.length > 0 && footerQuestions.map((question, i) => {
            return (<>
            {/* @ts-ignore */}
            <Scope key={i} scope={question.questionCode}>
              <RadioGroup label={t(question.text)} legendStyles={{ fontWeight: 'normal' }} field={'all'} data-private={true} validate={validateRadio} validateOnChange={true} initialValue={getInitialValue(question.questionCode, interviewAnswers)} style={{ fontWeight: 'normal' }}>
                <Radio name='field' label={t(EoiGeneralText.yes)} value={true} data-private={true} theme={themes.lincoln} data-testid={'footerQuestion' + i + 'yes'} containerStyle={{ marginBottom: '.5rem' }}/>
                <Radio name='field' label={t(EoiGeneralText.no)} value={false} data-private={true} theme={themes.lincoln} data-testid={'footerQuestion' + i + 'no'}/>
              </RadioGroup>
            </Scope>
          </>);
        })}

      {footerNote && <p>{t(footerNote)}</p>}
    </>);
};
export default QuestionSection;
