import React, { useEffect, useState } from "react";
import StatusDataContext from "../context/StatusDataContext";
import useProductAttributeCodes from '../hooks/useProductAttributeCodes';
import useStatusDefinitionList from '../hooks/useStatusDefinitionList';
const StatusDataProvider = ({ children }) => {
    const { productAttributeCodes, loading: codesLoading, error: codeError } = useProductAttributeCodes();
    const { statusDefinitionList, loading: definitionsLoading, error: definitionsError } = useStatusDefinitionList();
    const [legacyPref, setLegacyPref] = useState(false);
    useEffect(() => {
        console.log('init pref');
        const storedVal = sessionStorage.getItem('coverageDetailsPref') === 'legacy';
        console.log("storedVal", storedVal);
        setLegacyPref(storedVal);
    }, []);
    return (<StatusDataContext.Provider value={{
            productAttributeCodes,
            statusDefinitionList,
            legacyPref,
            setLegacyPref,
        }}>
      {children}
    </StatusDataContext.Provider>);
};
export default StatusDataProvider;
