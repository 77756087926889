import React from 'react';
import { Row, Col, TextInput, SelectInput, Heading, themes } from '@digitools/honest-react';
import useTranslate from '../../../hooks/useTranslate';
import useCliDataContext from '../hooks/useCliDataContext';
import { fieldRequired, selectInputErrorMessage } from '../../eoi/utils/FormFieldValidations';
import { Scope, useFormState } from 'informed';
import { ResidentialInformationText } from '../constants/ResidentialInformationText';
import { validateCity } from '../../../utils/validationUtils';
import { validateAddress1, validateAddress2, validatePostalCode } from '../utils/validationUtils';
import { CLI_COUNTRIES, CLI_PROVINCES, CLI_PROVINCES_VALUES, CLI_STATES } from '../constants/CliConstants';
import { BorderLessGrayBox } from 'src/components/Formatting/StyledComponents';
/**
 * Section of fields for residential information, for use within a <Form>
 */
const ResidentialInformation = () => {
    const { t } = useTranslate();
    const { claimantData } = useCliDataContext();
    const { values } = useFormState();
    let currentCountry = 'USA';
    // @ts-ignore
    const isCountryUS = values && values.claimantData && values.claimantData['selectedCountryID'] === 'USA' ? true : false;
    if (values && values.claimantData && values.claimantData['selectedCountryID'] === 'CAN') {
        currentCountry = 'CAN';
    }
    // Eligibility coming back with province ID but not country, this sets default country accordingly
    // @ts-ignore
    if ((!claimantData?.selectedCountryID) && CLI_PROVINCES_VALUES.includes(claimantData?.selectedStateID)) {
        currentCountry = 'CAN';
    }
    return (<div data-public={true} data-testid='residential-information' style={{ marginTop: '1rem', marginBottom: '1rem' }}>
      <BorderLessGrayBox>
        <Heading elemType='h3' elemStyle='h4'>{t(ResidentialInformationText.heading)}</Heading>
      </BorderLessGrayBox>
      {/* @ts-ignore */}
      <Scope scope='claimantData'>
        <Row>
          <Col md={6} xs={12}>
            <TextInput data-testid='residential-add-1' field={'residentialAdd1'} label={t(ResidentialInformationText.address1)} ariaLabelText={t(ResidentialInformationText.address1)} theme={themes.lincoln} type={'text'} validateOnBlur={true} validate={validateAddress1} initialValue={claimantData && claimantData.residentialAdd1}/>
          </Col>
          <Col md={6} xs={12}>
            <TextInput data-testid='residential-add-2' field={'residentialAdd2'} label={t(ResidentialInformationText.address2)} ariaLabelText={t(ResidentialInformationText.address2)} theme={themes.lincoln} type={'text'} validateOnBlur={true} validate={validateAddress2} initialValue={claimantData && claimantData.residentialAdd2}/>
          </Col>
          <Col md={6}>
            <TextInput data-testid='residential-city' field={'residentialCity'} label={t(ResidentialInformationText.city)} ariaLabelText={t(ResidentialInformationText.city)} theme={themes.lincoln} type={'text'} validateOnBlur={true} validate={validateCity} initialValue={claimantData && claimantData.residentialCity}/>
          </Col>
          <Col md={6}>
            <TextInput maxLength={10} data-testid='residential-postal-code' field={'postalCode'} label={t(ResidentialInformationText.postal)} ariaLabelText={t(ResidentialInformationText.postal)} theme={themes.lincoln} type={'tel'} validateOnBlur={true} validate={validatePostalCode} initialValue={claimantData && claimantData.postalCode}/>
          </Col>
          <Col md={6} xs={12}>
            <SelectInput data-testid='residential-country' ariaLabelText={t(ResidentialInformationText.country)} field={'selectedCountryID'} label={t(ResidentialInformationText.country)} validateOnBlur={true} validate={fieldRequired} errorMessage={selectInputErrorMessage} options={CLI_COUNTRIES} initialValue={currentCountry || claimantData && claimantData.selectedCountryID}/>
          </Col>
          <Col md={6} xs={12}>
            {isCountryUS && (<SelectInput data-testid='residential-state' ariaLabelText={t(ResidentialInformationText.state)} field={'selectedStateID'} label={t(ResidentialInformationText.state)} placeholderText={t(ResidentialInformationText.state)} validateOnBlur={true} validate={fieldRequired} errorMessage={selectInputErrorMessage} options={CLI_STATES} initialValue={claimantData && claimantData?.selectedStateID?.trim()}/>)}
            {currentCountry === 'CAN' && (<SelectInput data-testid='residential-province' ariaLabelText={t(ResidentialInformationText.province)} field={'selectedStateID'} label={t(ResidentialInformationText.province)} placeholderText={t(ResidentialInformationText.province)} validateOnBlur={true} validate={fieldRequired} errorMessage={selectInputErrorMessage} options={CLI_PROVINCES} initialValue={claimantData && claimantData?.selectedStateID?.trim()}/>)}
          </Col>
        </Row>
      </Scope>
    </div>);
};
export default ResidentialInformation;
