import React from 'react';
import { QuestionsText } from '../../../../i18n/QuestionsText';
import useEoiContext from '../../../../hooks/useEoiContext';
import { EoiGeneralText } from '../../../../i18n/EoiText';
import MedicalQuestionAnswerSection from './MedicalQuestionAnswerSection';
import { Label, themes, } from '@digitools/honest-react';
import useTranslate from '../../../../../../hooks/useTranslate';
import Row from '@digitools/honest-react/dist/lib/components/Row';
import Col from '@digitools/honest-react/dist/lib/components/Col';
import { RedBulletList } from '../../../../../../components/Formatting/StyledComponents';
const lookupAndTranslateResponseValue = (responseCode, responseOptions) => {
    const responseOption = responseOptions.filter((option) => option.responseCode.toString() === responseCode).shift();
    if (responseOption) {
        return responseOption.text;
    }
};
/**
 * Display Component for a Reflexive Questions' additional Question value.
 * uses the typeCode and value to determine what text and format to display
 * @param param0
 */
const AdditionalAnswerDisplay = ({ questionAndResponse }) => {
    const { t } = useTranslate();
    if (!questionAndResponse.value) {
        return null;
    }
    else if (questionAndResponse.responseFormatTypeCode === 'SCD') {
        const responseCode = questionAndResponse.value[0];
        return <strong>{t(lookupAndTranslateResponseValue(responseCode, questionAndResponse.responseOptions))}</strong>;
    }
    else if (questionAndResponse.responseFormatTypeCode === 'MCD') {
        return (<RedBulletList>
        {questionAndResponse.value.map((value, i) => {
                const displayValue = t(lookupAndTranslateResponseValue(value, questionAndResponse.responseOptions));
                return <li key={i} data-private={true}><span><strong>{displayValue}</strong></span></li>;
            })}
      </RedBulletList>);
    }
    else {
        if (questionAndResponse.value[0] === 'true') {
            return <strong data-private={true}>{t({ english: 'Yes', spanish: 'Si' })}</strong>;
        }
        else if (questionAndResponse.value[0] === 'false') {
            return <strong data-private={true}>{t({ english: 'No', spanish: 'No' })}</strong>;
        }
        else {
            return <strong data-private={true}>{questionAndResponse.value[0]}</strong>;
        }
    }
};
const getReflectiveAnswersForApplicant = (applicantType, interviewAnswers, dependentId) => {
    let applicantReflexiveAnswers;
    switch (applicantType) {
        case 'employee':
            applicantReflexiveAnswers = (interviewAnswers && interviewAnswers.reflexiveAnswers && interviewAnswers.reflexiveAnswers.employee && interviewAnswers.reflexiveAnswers.employee);
            break;
        case 'spouse':
            applicantReflexiveAnswers = interviewAnswers && interviewAnswers.reflexiveAnswers && interviewAnswers.reflexiveAnswers.spouse && interviewAnswers.reflexiveAnswers.spouse;
            break;
        case 'dependent':
            applicantReflexiveAnswers = interviewAnswers && interviewAnswers.reflexiveAnswers && interviewAnswers.reflexiveAnswers.dependents && interviewAnswers.reflexiveAnswers.dependents.length > 0 && interviewAnswers.reflexiveAnswers.dependents.filter((dependentReflexiveAnswer) => dependentReflexiveAnswer.dependentId === dependentId).shift();
            break;
        default:
            break; // no default here
    }
    return applicantReflexiveAnswers;
};
const getReflexiveQuestionsForApplicant = (applicantType, reflexiveQuestions, dependentId) => {
    let applicantReflexiveQuestions;
    switch (applicantType) {
        case 'employee':
            applicantReflexiveQuestions = reflexiveQuestions && reflexiveQuestions.employee;
            break;
        case 'spouse':
            applicantReflexiveQuestions = reflexiveQuestions && reflexiveQuestions.spouse;
            break;
        case 'dependent':
            // dependent questions are stored in a wrapper with a dependent Id, need to filter for it and pull out the questions
            const dependentReflexiveQuestions = reflexiveQuestions && reflexiveQuestions.dependents && reflexiveQuestions.dependents.filter((dependentReflexiveQuestion) => dependentReflexiveQuestion.dependentId === dependentId).shift();
            applicantReflexiveQuestions = dependentReflexiveQuestions ? dependentReflexiveQuestions.questions : undefined;
            break;
        default:
    }
    return applicantReflexiveQuestions;
};
const ReflexiveQuestions = ({ applicantType, dependentId, }) => {
    const { interviewAnswers, reflexiveQuestions } = useEoiContext();
    const { t } = useTranslate();
    const applicantReflexiveAnswers = getReflectiveAnswersForApplicant(applicantType, interviewAnswers, dependentId);
    const applicantReflexiveQuestions = getReflexiveQuestionsForApplicant(applicantType, reflexiveQuestions, dependentId);
    // Build Questions and Answers object to facilitate display
    const reflexiveQuestionsAndAnswers = [];
    if (applicantReflexiveAnswers !== undefined && reflexiveQuestions && applicantReflexiveQuestions !== undefined) {
        applicantReflexiveQuestions.map((reflexiveQuestionSet) => {
            // Iterate over all questions asked 
            reflexiveQuestionSet.questions.forEach((reflexiveQuestion) => {
                // look for a matching reflexive answer for this question
                if (applicantReflexiveAnswers && applicantReflexiveAnswers.responses) {
                    // This is the questionAndResponse Object -- we iterate over a list of these in render - they will contain all question & anwer data needed to display properly
                    const questionAndResponse = {
                        questionText: reflexiveQuestion.questionText,
                        answer: { english: '', spanish: '' },
                        additionalQuestionsAndResponses: [],
                        questionNote: reflexiveQuestion.questionNote
                    };
                    const matchingResponse = applicantReflexiveAnswers.responses.filter((reflexiveAnswer) => {
                        return reflexiveAnswer.questionCode === reflexiveQuestion.questionCode;
                    }).shift();
                    // Have an answer (or a lack of one indiacating false) create qiestionAndResponse object here and add to final list
                    if (matchingResponse !== undefined) {
                        questionAndResponse.answer = matchingResponse.value[0] === 'true' ? EoiGeneralText.yes : EoiGeneralText.no;
                        // Check for additionalQuestions 
                        if (matchingResponse.additionalQuestions && matchingResponse.additionalQuestions.length > 0) {
                            // Build a questionAndAnswer object for each additionalQuestion
                            matchingResponse.additionalQuestions.forEach((additionalQuestionResponse) => {
                                const matchingAdditionalQuestion = reflexiveQuestion.additionalQuestions.filter((additionalQuestion) => additionalQuestion.questionCode === additionalQuestionResponse.questionCode).shift();
                                // push questionAndResponse to result - we let Render figure out how to display this dynamic object
                                questionAndResponse.additionalQuestionsAndResponses.push({
                                    questionText: matchingAdditionalQuestion ? matchingAdditionalQuestion.text : undefined,
                                    responseOptions: matchingAdditionalQuestion.responseOptions,
                                    responseFormatTypeCode: matchingAdditionalQuestion.responseFormatTypeCode,
                                    value: additionalQuestionResponse.value,
                                    answer: additionalQuestionResponse.value[0] ? EoiGeneralText.yes : EoiGeneralText.no
                                });
                            });
                        }
                    }
                    else {
                        // No matching response found - indicates false/no
                        questionAndResponse.answer = EoiGeneralText.no;
                    }
                    // push result into QuestionAndResponse list
                    reflexiveQuestionsAndAnswers.push(questionAndResponse);
                }
            });
        });
    }
    /* Status - all data logic above works for both spouse and employee
    *        dependents will need one little variant to filter for dependentId before the big answer/question logic block
    *        can try getting rid of both the boolean and the actual reflective answers - may simplify some null checking
    *        Need to spice up the display - give it a section header, divider, bolded text
    */
    // TODO: translate that heading/static text
    return (<>
      {reflexiveQuestionsAndAnswers.length > 0 &&
            <div style={{ marginLeft: '1rem' }}>
          <Label theme={themes.lincoln} style={{ marginBottom: '0.5rem', }}>{t(QuestionsText.additionalQuestions)}</Label>
          {reflexiveQuestions.questionInstructions ? <p>{t(reflexiveQuestions.questionInstructions[applicantType])}</p> : (reflexiveQuestions && <p>{t(reflexiveQuestions.instructions)}</p>)}
          <div>
            {reflexiveQuestionsAndAnswers.map((questionAndResponse, i) => {
                    return (<>
                  <MedicalQuestionAnswerSection key={i} questionText={questionAndResponse.questionText} questionAnswer={questionAndResponse.answer} questionNote={t(questionAndResponse.questionNote)}/>
                    
                  {questionAndResponse.additionalQuestionsAndResponses.length > 0 &&
                            <>
                    <Row style={{ marginBottom: '1rem' }} data-private={true}>
                      <Col sm={1}/>
                      <Col sm={10} style={{ borderLeft: `6px solid ${themes.lincoln.palette.grayLightest}` }}>
                      {questionAndResponse.additionalQuestionsAndResponses.map((additionalQuestionAndResponse, aqrIndex) => {
                                    return (<Row key={aqrIndex}>
                            <Col sm={6}>
                              <RedBulletList><li><span>{t(additionalQuestionAndResponse.questionText)}</span></li></RedBulletList>
                            </Col>
                            <Col sm={6} style={{ borderLeft: `6px solid ${themes.lincoln.palette.grayLightest}` }}>
                              <AdditionalAnswerDisplay questionAndResponse={additionalQuestionAndResponse}/>
                            </Col>
                          </Row>);
                                })}
                      </Col>
                      <Col sm={1}/>
                    </Row>
                    <Row style={{ marginBottom: '1rem' }}>
                      <Col sm={1}/>
                      <Col sm={10}>
                        {questionAndResponse.questionNote
                                    && (questionAndResponse.questionNote.spanish || questionAndResponse.questionNote.spanish)
                                    && <strong><em>{t(questionAndResponse.questionNote)}</em></strong>}
                      </Col>
                      <Col sm={1}/>
                    </Row>
                    </>}
                </>);
                })}
          </div>
        </div>}
    </>);
};
export default ReflexiveQuestions;
