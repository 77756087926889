import React from 'react';
import Table from '@digitools/honest-react/dist/lib/components/Table';
import TableRow from '@digitools/honest-react/dist/lib/components/Table/TableRow';
import TableHeaderCol from '@digitools/honest-react/dist/lib/components/Table/TableHeaderCol';
import TableHeader from '@digitools/honest-react/dist/lib/components/Table/TableHeader';
import useTranslate from '../../../../hooks/useTranslate';
import RadioGroup from '@digitools/honest-react/dist/lib/components/Radio/RadioGroup';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import TableData from '@digitools/honest-react/dist/lib/components/Table/TableData';
import CheckboxGroup from '@digitools/honest-react/dist/lib/components/Checkbox/CheckboxGroup';
import Checkbox from '@digitools/honest-react/dist/lib/components/Checkbox';
import Radio from '@digitools/honest-react/dist/lib/components/Radio';
import { Scope, useFormState } from 'informed';
import { customize } from '../../utils/HelperFunctions';
import { EoiGeneralText } from '../../i18n/EoiText';
import useEoiContext from '../../hooks/useEoiContext';
const validateDependentCheckboxes = (value) => {
    return value === undefined || value.length === 0 ? 'Select at least 1 dependent.' : undefined;
};
const validateRadio = (value) => {
    return value === undefined ? 'Required' : undefined;
};
const getInitialValue = (questionCode, interviewAnswers, applicantType, dependentId) => {
    const answers = questionCode.indexOf('CRIT') !== -1
        ? interviewAnswers.criticalIllnessAnswers
        : interviewAnswers.medicalInformationAnswers;
    if (answers) {
        const matchingAnswer = answers
            .filter((answer) => answer.questionCode === questionCode && answer.role === applicantType)
            .shift();
        if (matchingAnswer) {
            // we found a match!
            if (dependentId && matchingAnswer.dependentIds) {
                // need both of these cases because of initialValue quirks with display and form value of Checkbox and Checkbox Group
                if (dependentId === 'all') {
                    // this is for dependent checkbox Group initial values
                    const x = matchingAnswer.dependentIds.map(depId => `${questionCode}-${depId}`);
                    return x;
                }
                else {
                    // this is for the individual dependent checkbox values
                    return matchingAnswer.dependentIds && matchingAnswer.dependentIds.indexOf(dependentId) > -1;
                }
            }
            // else return the value of the matching answer
            return matchingAnswer.value;
        }
    }
    // no match found leave value blank
    return undefined;
};
const YesNo = ({ field, yesOnly, initialValue }) => {
    const { t } = useTranslate();
    // TODO: I think name can be optional/not needed for honest
    return (<div style={{ display: 'flex', justifyContent: 'center', minWidth: '86px' }} data-testid={field} data-private={true}>
      <RadioGroup label='Yes or No' hideLabel={true} field={field} data-private={true} validate={validateRadio} validateOnChange={true} initialValue={initialValue}>
        <Radio name='field' label={t(EoiGeneralText.yes)} value={true} data-private={true} theme={themes.lincoln} data-testid={field + 'yes'}/>
        {!yesOnly && (<Radio name='field' label={t(EoiGeneralText.no)} value={false} data-private={true} theme={themes.lincoln} data-testid={field + 'no'} containerStyle={{ marginTop: '.5rem' }}/>)}
      </RadioGroup>
    </div>);
};
const QuestionTable = ({ title, employee, spouse, dependent, questions, yesOnly = false, }) => {
    const { t } = useTranslate();
    const formState = useFormState();
    const { interviewAnswers, customContent, initialInformation } = useEoiContext();
    // FIXME: hover coler & stripe color are the same - slightly not ideal look to not see the hover on the banded rows
    // FIXME: gross to apply themee to every element individually - look into Theme providing to clean up
    return (<Table theme={themes.lincoln} rowBanding={true}>
      <TableHeader theme={themes.lincoln}>
        <TableRow theme={themes.lincoln}>
          <TableHeaderCol theme={themes.lincoln} style={{ minWidth: '13rem' }}>
            {title ? t(title).toUpperCase() : ''}
          </TableHeaderCol>
          {employee && (<TableHeaderCol theme={themes.lincoln}>{t(EoiGeneralText.employeeLabel).toUpperCase()}</TableHeaderCol>)}
          {spouse && (<TableHeaderCol theme={themes.lincoln} style={{ minWidth: '9rem' }}>
              {t(customize(EoiGeneralText.spouseLabel, customContent, initialInformation)).toUpperCase()}
            </TableHeaderCol>)}
          {dependent && (<TableHeaderCol theme={themes.lincoln} style={{ minWidth: '11rem' }}>
              {t(EoiGeneralText.dependentLabel).toUpperCase()}
            </TableHeaderCol>)}
        </TableRow>
        {questions &&
            questions.map((data, i) => {
                //split note from question text
                var splitQuestionText = t(data.text).split('Note: ');
                return (
                // @ts-ignore
                <Scope key={i} scope={data.questionCode} data-private={true}>
                <TableRow theme={themes.lincoln} key={data.questionCode} data-private={true}>
                  <TableData theme={themes.lincoln} data-private={true}>
                    {splitQuestionText[0]}
                    {splitQuestionText[1] && (<>
                        <br />
                        <div data-testid='question-note' style={{ marginTop: '.5rem' }} data-private={true}>
                          <em>
                            {t(EoiGeneralText.note)}
                            {splitQuestionText[1]}
                          </em>
                        </div>
                      </>)}
                  </TableData>
                  {employee && (<TableData theme={themes.lincoln} data-private={true}>
                      <YesNo index={i} field={'employee'} yesOnly={yesOnly} initialValue={getInitialValue(data.questionCode, interviewAnswers, 'employee')}/>
                    </TableData>)}
                  {spouse && (<TableData theme={themes.lincoln} data-private={true}>
                      <YesNo field={'spouse'} yesOnly={yesOnly} initialValue={getInitialValue(data.questionCode, interviewAnswers, 'spouse')}/>
                    </TableData>)}
                  {dependent && (<TableData theme={themes.lincoln} data-private={true}>
                      <YesNo field={'dependent'} questionCode={data.questionCode} yesOnly={yesOnly} initialValue={getInitialValue(data.questionCode, interviewAnswers, 'dependent')}/>
                      {
                        // @ts-ignore
                        !yesOnly &&
                            formState &&
                            formState.values &&
                            formState.values.medicalInformation &&
                            formState.values.medicalInformation[data.questionCode] &&
                            formState.values.medicalInformation[data.questionCode].dependent && (<CheckboxGroup field={data.questionCode + '-' + 'dependents'} validate={validateDependentCheckboxes} validateOnBlur={true} validateOnChange={true} data-private={true} 
                        //@ts-ignore FIXME: type error
                        initialValue={getInitialValue(data.questionCode, interviewAnswers, 'dependent', 'all')}>
                              {interviewAnswers.applicants &&
                                interviewAnswers.applicants.dependents &&
                                interviewAnswers.applicants.dependents.map((kid, j) => {
                                    return (<Checkbox theme={themes.lincoln} key={j} field={data.questionCode + '-' + kid.dependentId} data-private={true} label={`${kid.firstName} ${kid.lastName ? kid.lastName.substring(0, 1) : ''}.`} 
                                    //@ts-ignore FIXME: type error
                                    initialValue={getInitialValue(data.questionCode, interviewAnswers, 'dependent', kid.dependentId)}/>);
                                })}
                            </CheckboxGroup>)}
                    </TableData>)}
                </TableRow>
              </Scope>);
            })}
      </TableHeader>
    </Table>);
};
export default QuestionTable;
