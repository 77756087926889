import React from 'react';
import { Alert, Heading, Row, Col, themes, Collapse, Spinner } from '@digitools/honest-react';
import { useTranslation } from 'react-i18next';
import useGet from '../../../../hooks/useGet';
import titleCase from '../../utilities/titleCase';
import checkDate from './../../../status/utilities/checkDate';
import formatPhone from './../../../status/utilities/formatPhone';
import formatAddress from './../../../status/utilities/formatAddress';
// @ts-ignore
import styles from './styles.scss';
import { isNotEmpty } from '../../../../utils/fieldValidators';
import useAuthentication from '../../../../hooks/useAuthentication';
import useExperience from 'src/hooks/useExperience';
import { Experience } from 'src/types/Experience';
const mapGender = (gender) => {
    if (gender) {
        if (gender === 'M') {
            return 'Male';
        }
        else if (gender === 'F') {
            return 'Female';
        }
        else {
            return '-';
        }
    }
};
export const Info = ({ info, text, restricted, ...rest }) => {
    if (info === null && restricted) {
        return null;
    }
    return (<div className={styles.info} style={{ display: 'flex', paddingLeft: '.5rem', paddingRight: '.5rem' }} {...rest}>
      <strong className={styles.infoLabel}>{text}</strong>
      <span style={{ marginLeft: 'auto', textAlign: 'right' }}>
        {(info === null || info === '') ? '-' : info}</span>
    </div>);
};
const EmployeeDetails = ({ empNum, empEmailAddress, noBenefitClass, updateSubsAndLocsCallback }) => {
    const { t } = useTranslation();
    const { error, loading, data,
    // @ts-ignore TODO: type useGet Hook
     } = useGet({
        url: `/status-service/employee/overview/${empNum}`,
    });
    const { user } = useAuthentication();
    const { experience } = useExperience();
    // determine if we should show additional details
    const showDetails = experience === Experience.EMPLOYEE || user?.applicationPermissions?.includes('statusER.claimLeaveStatus.viewEmployeeDetails');
    // If we dont have an empNum then we dont show this component
    if (!empNum) {
        return null;
    }
    if (loading) {
        // @ts-ignore TODO: type Spinner component
        return <Spinner id='employee-details-spinner'/>;
    }
    if (error) {
        return (<Alert type={'error'} closeable={true}>
        <span>
          <strong>Error!</strong>&nbsp;Something went wrong when retrieving employee information.
        </span>
      </Alert>);
    }
    const employeeFullName = `${titleCase(data?.firstName)} ${titleCase(data?.lastName)}`;
    const subsAndLocs = {
        location: data?.location,
        subsidiary: data?.subsidiary,
        employeeFullName,
    };
    if (updateSubsAndLocsCallback) {
        updateSubsAndLocsCallback(subsAndLocs);
    }
    return (<div data-testid={'employeeBox'} data-public={true} style={{ border: `1px solid ${themes.lincoln.palette.grayLighter}`, boxShadow: `${themes.lincoln.palette.grayLighter} 1px 1px 1px` }}>
      <Row style={{ paddingBottom: '0px', margin: '0px' }}>
        <Col lg={3} md={12} sm={12} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          <div style={{ backgroundColor: `${themes.lincoln.palette.primaryDark}`, height: '100%', padding: '1rem' }}>
            <Heading data-private={true} elemType={'h1'} elemStyle={'h2'} color={'white'}>
              {employeeFullName}
            </Heading>
          </div>
        </Col>
          <Col lg={9} md={12} sm={12} style={{ paddingBottom: '1rem', paddingTop: '1rem' }}>
            <Row style={{ marginBottom: '.5rem' }}>
              <Col lg={6} md={6} sm={12}>
                <Info text={t('employeeIdText')} data-private={true} info={data?.empId}/>
                <Info data-private={true} text={t('ssnSidText')} info={data?.ssn != null && data?.ssn.trim() !== '' ? `***\xa0**\xa0${data?.ssn}` : data?.ssn} restricted={true}/>
                <Info text={t('dateOfBirthText')} data-private={true} info={data?.birthDate} restricted={true}/>
                <Info text={t('dateOfHireText')} data-private={true} info={data?.hireDate}/>
              </Col>
              <Col lg={6} md={6} sm={12}>
                {isNotEmpty(data?.location) || isNotEmpty(data?.subsidiary) || !user?.isLfgUser ?
            <>
                    <Info text={t('locationText')} info={data?.location}/>
                    <Info text={t('subsidiaryText')} info={data?.subsidiary}/>
                  </>
            : <></>}
              </Col>
            </Row>
            {showDetails && <Collapse data-testid={'employeeDetails'} title={`${t('employeeDetails')}`} type="alternate" theme={themes.lincoln}>
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <Info text={t('jobTitleText')} info={data?.jobTitle}/>
                  <Info data-private={true} text={t('supervisorText')} info={data?.supervisorName} restricted={true}/>
                  <Info text={t('salaryAmountText')} info={data?.salaryAmount} restricted={true}/>
                  <Info text={t('salaryModeText')} info={data?.salaryMode}/>
                  <Info text={t('benefitEligibleText')} info={checkDate(data?.benefitEligibleDate)}/>
                  <Info text={t('genderText')} info={mapGender(data?.gender)}/>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Info text={t('workAddressText')} info={formatAddress(data?.workAddress1, data?.workCity, data?.workState, data?.workZipCode)}/>
                  <Info text={t('workPhoneText')} info={formatPhone(data?.workPhoneNumber)}/>
                  <Info text={t('homeAddressText')} info={formatAddress(data?.homeAddress1, data?.homeCity, data?.homeState, data?.homeZipCode)}/>
                  <Info text={t('homePhoneText')} info={formatPhone(data?.homePhoneNumber)}/>
                  <Info text={t('emailText')} info={empEmailAddress}/>
                </Col>
              </Row>
            </Collapse>}
          </Col>
      </Row>
    </div>);
};
export default EmployeeDetails;
