import React, { useContext } from 'react';
import { Col, Collapse, Row, StepperContext, themes } from '@digitools/honest-react';
import useTranslate from '../../../../hooks/useTranslate';
import useCliDataContext from '../../hooks/useCliDataContext';
import { Language } from '../../../../types/Language';
import useExperience from '../../../../hooks/useExperience';
import ReviewField, { EditReviewField } from './ReviewField';
import { ReviewText } from 'src/packages/cli/constants/ReviewText';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { CLI_COUNTRIES, CLI_PROVINCES, CLI_STATES } from '../../constants/CliConstants';
const ReviewAboutYou = () => {
    const stepper = useContext(StepperContext);
    const { t } = useTranslate();
    const { experience } = useExperience();
    const { language } = useExperience();
    const { claimantData } = useCliDataContext();
    // @ts-ignore
    const isCountryUS = claimantData && claimantData.selectedCountryID === 'USA';
    // @ts-ignore
    const isCountryCanada = claimantData && claimantData.selectedCountryID === 'CAN';
    // @ts-ignore
    const isCountryEmpUs = claimantData && claimantData.selectedEmpCountryID === 'USA';
    // @ts-ignore
    const isCountryEmpCanada = claimantData && claimantData.selectedEmpCountryID === 'CAN';
    const selectedState = isCountryUS
        ? CLI_STATES.find(state => state.value === claimantData?.selectedStateID)
        : CLI_PROVINCES.find(province => province.value === claimantData?.selectedStateID);
    const selectedEmpState = isCountryEmpUs
        ? CLI_STATES.find(empState => empState.value === claimantData?.selectedEmpStateID)
        : CLI_PROVINCES.find(empProvince => empProvince.value === claimantData?.selectedEmpStateID);
    const selectedCountry = CLI_COUNTRIES.find(country => country.value === claimantData?.selectedCountryID);
    const selectedEmpCountry = CLI_COUNTRIES.find(empCountry => empCountry.value === claimantData?.selectedEmpCountryID);
    const getCommunicationPreference = (selectedpreferredmethod) => {
        switch (selectedpreferredmethod) {
            case 'E': {
                return 'Email';
            }
            case 'F': {
                return 'Fax';
            }
            case 'M': {
                return 'Mail';
            }
            default: {
                return '';
            }
        }
    };
    const getGender = (gender) => {
        switch (gender) {
            case 'M': {
                return 'Male';
            }
            case 'F': {
                return 'Female';
            }
            default: {
                return '';
            }
        }
    };
    return (<div data-testid='AboutYouReview'>
      <div data-public={true} className={`mb-3`}>
        <Collapse headerRightDisplay={<EditReviewField data-testid="editButtonPersonalInfoTestId" onClick={() => { stepper.edit(1); }}/>} isOpen={true} title={CustomEmployeeText(t(experience === 'Employer' ? ReviewText.personalInformation.headingER : ReviewText.personalInformation.heading))} theme={themes.lincoln} type='alternate'>
          <Row>
            <Col md={6} lg={language === Language.ENGLISH ? 3 : 6}>
              <ReviewField data-private={true} label={t(ReviewText.personalInformation.firstName)} value={claimantData && claimantData.firstName}/>
            </Col>
            <Col md={6} lg={language === Language.ENGLISH ? 3 : 6}>
              <ReviewField label={t(ReviewText.personalInformation.middleInitial)} value={claimantData && claimantData.middleName}/>
            </Col>
            <Col md={6} lg={6}>
              <ReviewField data-private={true} label={t(ReviewText.personalInformation.lastName)} value={claimantData && claimantData.lastName}/>
            </Col>
            <Col md={6} lg={6} xl={language === Language.ENGLISH ? 3 : 6}>
              <ReviewField label={t(ReviewText.personalInformation.dateOfBirthEE)} value={claimantData && claimantData.dob}/>
            </Col>
            <Col md={6} lg={3} data-testid='genderReview'>
              <ReviewField label={t(ReviewText.personalInformation.gender)} value={claimantData && getGender(claimantData.gender)} data-private={true}/>
            </Col>
          </Row>
        </Collapse>
      </div>
      <div data-public={true} className={`mb-3`}>
        <Collapse headerRightDisplay={<EditReviewField data-testid="editButtonContactInfoTestId" onClick={() => { stepper.edit(1); }}/>} isOpen={true} title={CustomEmployeeText(t(experience === 'Employer' ? ReviewText.contactInformation.headingER : ReviewText.contactInformation.heading))} theme={themes.lincoln} type='alternate'>
          <Row>
            <Col md={6} xs={12} data-testid="communicationPreferenceTestId">
              <ReviewField label={t(ReviewText.contactInformation.preferredContactLabel)} value={claimantData && getCommunicationPreference(claimantData.selectedpreferredmethod)}/>
            </Col>
            <Col md={6} xs={12} className="d-flex flex-column justify-content-end">
              <ReviewField label={t(ReviewText.contactInformation.phone)} value={claimantData && claimantData.phone}/>
            </Col>
            <Col md={6} xs={12}>
              <ReviewField data-private={true} label={t(ReviewText.contactInformation.email)} value={claimantData && claimantData.email}/>
            </Col>
            {claimantData && claimantData.claimantpreferredfax &&
            <Col md={6} xs={12}>
                <ReviewField label={t(ReviewText.contactInformation.fax)} value={claimantData && claimantData.claimantpreferredfax}/>
              </Col>}
          </Row>
        </Collapse>
      </div>
      <div data-public={true} className={`mb-3`}>
        <Collapse headerRightDisplay={<EditReviewField data-testid="editButtonResidentialInfoTestId" onClick={() => { stepper.edit(1); }}/>} isOpen={true} title={CustomEmployeeText(t(experience === 'Employer' ? ReviewText.residentialInformation.headingER : ReviewText.residentialInformation.heading))} theme={themes.lincoln} type='alternate'>
          <Row>
            <Col md={6} xs={12}>
              <ReviewField label={t(ReviewText.residentialInformation.address1)} value={claimantData && claimantData?.residentialAdd1?.toUpperCase()}/>
            </Col>
            <Col md={6} xs={12}>
              <ReviewField label={t(ReviewText.residentialInformation.address2)} value={claimantData && claimantData?.residentialAdd2?.toUpperCase()}/>
            </Col>
            <Col md={6}>
              <ReviewField label={t(ReviewText.residentialInformation.city)} value={claimantData && claimantData?.residentialCity?.toUpperCase()}/>
            </Col>
            <Col md={6}>
              <ReviewField label={t(ReviewText.residentialInformation.postal)} value={claimantData && claimantData.postalCode}/>
            </Col>
            <Col md={6} xs={12}>
              <ReviewField label={t(ReviewText.residentialInformation.country)} value={selectedCountry?.label}/>
            </Col>
            {isCountryUS &&
            <Col md={6} xs={12} data-testid='usaStateId'>
                <ReviewField label={t(ReviewText.residentialInformation.state)} value={selectedState?.label}/>
              </Col>}
            {isCountryCanada &&
            <Col md={6} xs={12} data-testid='canadaStateId'>
                <ReviewField label={t(ReviewText.residentialInformation.province)} value={selectedState?.label}/>
              </Col>}
          </Row>
        </Collapse>
      </div>
      <div data-public={true} className={`mb-3`}>
        <Collapse headerRightDisplay={<EditReviewField data-testid="editButtonPrimaryWorkTestId" onClick={() => { stepper.edit(1); }}/>} isOpen={true} title={CustomEmployeeText(t(experience === 'Employer' ? ReviewText.aboutYou.primaryWorkLocationER : ReviewText.aboutYou.primaryWorkLocation))} theme={themes.lincoln} type='alternate'>
          <Row>
            <Col md={6} xs={12}>
              <ReviewField label={t(ReviewText.aboutYou.countrySelect)} value={selectedEmpCountry?.label}/>
            </Col>
            {isCountryEmpUs &&
            <Col md={6} xs={12} data-testid='usaStateEmpId'>
                <ReviewField label={t(ReviewText.aboutYou.stateSelect)} value={selectedEmpState?.label}/>
              </Col>}
            {isCountryEmpCanada &&
            <Col md={6} xs={12} data-testid='canadaStateEmpId'>
                <ReviewField label={t(ReviewText.aboutYou.provinceSelect)} value={selectedEmpState?.label}/>
              </Col>}
          </Row>
        </Collapse>
      </div>
    </div>);
};
export default ReviewAboutYou;
