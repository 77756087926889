import React, { useState } from 'react';
import { parse, isBefore, isAfter, isSameDay } from 'date-fns';
import { Collapse, Row, DateSelect, InputMessage, Button, Col, Box, themes } from '@digitools/honest-react';
import { Form } from 'informed';
const PaymentFilter = ({ initialData, setFilteredData }) => {
    const [dateRangeError, setDateRangeError] = useState();
    const [formApi, setFormApi] = useState();
    if (!initialData) {
        return null; // don't display filter for no data
    }
    const filterPaymentsByDate = (values) => {
        const fromDate = parse(values.fromDate, 'MM/dd/yyyy', new Date());
        const throughDate = parse(values.throughDate, 'MM/dd/yyyy', new Date());
        const filteredDate = initialData.filter((data) => {
            const checkDate = parse(data.checkDate, 'MM/dd/yyyy', new Date());
            return (isBefore(fromDate, checkDate) || isSameDay(fromDate, checkDate)) && (isBefore(checkDate, throughDate) || isSameDay(checkDate, throughDate));
        });
        setFilteredData(filteredDate);
    };
    const validateDateFilter = (values) => {
        const fromDate = parse(values.fromDate, 'MM/dd/yyyy', new Date());
        const throughDate = parse(values.throughDate, 'MM/dd/yyyy', new Date());
        if (isAfter(fromDate, throughDate)) {
            setDateRangeError(true);
            return 'From date must come before through date';
        }
    };
    const clearFilterForm = () => {
        // clear out the values form the form
        formApi.setValue('fromDate', undefined);
        formApi.setValue('throughDate', undefined);
        formApi.setError('fromDate', undefined);
        formApi.setError('throughDate', undefined);
        setDateRangeError(false);
        setFilteredData(undefined);
    };
    return (<Box data-testid='payment-filter-box' style={{ border: `1px solid ${themes.lincoln.palette.grayLighter}` }}>
      <Collapse title={'Filter'} data-testid='filterPaymentsHeader' type={'alternate'} theme={themes.lincoln}>
        <Form onSubmit={filterPaymentsByDate} getApi={setFormApi} validate={validateDateFilter}>
          <Row>
            <Col md={6} sm={12}>
              <DateSelect data-testid={'filterFromDate'} style={{ width: '50%' }} field={'fromDate'} ariaLabelText={'From Date'} label={'From Date'} required={true}/>
            </Col>
            <Col md={6} sm={12}>
              <DateSelect data-testid={'filterThroughDate'} field={'throughDate'} ariaLabelText={'Through Date'} label={'Through Date'} required={true}/>
            </Col>
          </Row>
          {dateRangeError && <InputMessage data-testid="date-range-error" error={true}>{formApi && formApi.getState().error}</InputMessage>}
          <Row>
            <Button data-testid={'filterClearButton'} type='button' buttonType={'secondary'} onClick={() => clearFilterForm()} style={{ marginLeft: 'auto' }}>
              Clear filter
            </Button>
            <Button data-testid="applyFilterButton" type='submit' buttonType={'primary'} style={{ marginLeft: '1rem' }}>
              Apply
            </Button>
          </Row>
        </Form>
      </Collapse>
    </Box>);
};
export default PaymentFilter;
