import React, { useState } from 'react';
import { Button, Icon } from '@digitools/honest-react';
import { useTranslation } from 'react-i18next';
import titleCase from '../../../utilities/titleCase';
import Card from '../../../../../components/Cards/Card';
import ReviewField from '../../../../cli/pages/review/ReviewField';
import { compareByField } from '../../../../../utils/SortUtils';
import styled from 'styled-components';
const ReviewFieldMethodSent = styled(ReviewField) `
  min-width: 5rem;
  flex-grow: 2;
  @media (max-width: 768px) {
      min-width: 50%
      flex-grow: 1;
  }
`;
const ReviewFieldSentDate = styled(ReviewField) `
  min-width: 5rem;
  flex-grow: 2;
  @media (max-width: 768px) {
      min-width: 50%
      flex-grow: 1;
  }
`;
const EVENT_INCREMENT = 3;
const CorrespondenceCards = ({ correspondenceList, openDoc, openDocGet }) => {
    const { t } = useTranslation();
    const [selectedEvent, setSelectedEvent] = useState();
    //TODO: make sure this works & updates responsivly
    let mobile = window.matchMedia("(max-width: 768px)");
    const [numEventsToDisplay, setNumEventsToDisplay] = useState(EVENT_INCREMENT + (!mobile.matches ? 3 : 0)); // initially show 6 on desktop
    const [sortBy, setSortBy] = useState({ field: 'documentDate', ascending: false });
    const handleCardClick = (event, index) => {
        openDoc(openDocGet, event._links.content.href, event.documentTitle, index, event.docId);
    };
    let sortedData = [];
    if (correspondenceList && correspondenceList?.length > 0) {
        sortedData = correspondenceList.toSorted(compareByField(sortBy.field, sortBy.ascending));
    }
    ;
    const Heading = (docTitle) => {
        return (<div data-private={true}>{docTitle}</div>);
    };
    const DownloadIcon = (<Icon color='secondary' name='download' style={{ fontSize: '1.5rem' }}/>);
    return (<div id='CorrespondenceListDesktop-container' style={{ width: '100%' }}>
        {sortedData.map((correspondence, i) => {
            if (i >= numEventsToDisplay) {
                return <></>;
            }
            return (<>
                <Card data-testid={`correspondenceCards-${i}`} key={correspondence.docId} heading={Heading(t(correspondence.documentTitle))} secondaryHeading={DownloadIcon} selectable={true} selected={correspondence.docId === selectedEvent} onClick={() => handleCardClick(correspondence, i)} fontSize='large' small={true}>
                  <div style={{ display: 'flex', flexWrap: 'wrap', rowGap: '.5rem', columnGap: '.75rem', }}>
                    {/* @ts-ignore */}
                    <ReviewField small style={{ minWidth: '5rem', flexGrow: '2', }} label={titleCase(t('documentDate').toString())} value={t(correspondence.documentDate)}/>
                    {/* @ts-ignore */}
                    <ReviewFieldSentDate small data-testid={'sentDate'} label={titleCase(t('documentSentDate').toString())} value={t(correspondence.sentDate)}/>
                    {/* @ts-ignore */}
                    <ReviewField data-private={true} small style={{ minWidth: '5rem', flexGrow: '2', }} label={titleCase(t('documentSentToName').toString())} value={(correspondence.sentToFirstName ? t(`${titleCase(correspondence.sentToFirstName)} ${titleCase(correspondence.sentToLastName)}`) : '')}/>
                    {/* @ts-ignore */}
                    <ReviewFieldMethodSent small label={titleCase(t('documentMethodSent'))} value={t(correspondence.sentToDistributionMethod)}/>
                    {/* @ts-ignore */}
                    <ReviewField small style={{ flexGrow: '8', }} label={titleCase(t('documentType').toString())} value={t(correspondence.documentType)}/>
                  </div>
                </Card>
              </>);
        })}
          <div id='showMoreContainer' style={{ display: 'flex', justifyContent: 'center' }}>
            {numEventsToDisplay < sortedData.length && (<Button buttonType='text' onClick={() => setNumEventsToDisplay(numEventsToDisplay + (EVENT_INCREMENT + (!mobile.matches ? 3 : 0)))}>
                Show more
              </Button>)}
            {numEventsToDisplay >= sortedData.length && numEventsToDisplay > (EVENT_INCREMENT + (!mobile.matches ? 3 : 0)) && (<Button buttonType='text' onClick={() => setNumEventsToDisplay(numEventsToDisplay - (EVENT_INCREMENT + (!mobile.matches ? 3 : 0)))}>
                Show less
              </Button>)}
          </div>
      </div>);
};
export default CorrespondenceCards;
