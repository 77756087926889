import React, { useState, } from 'react';
import { Alert, Spinner, Heading, Paginator, Icon, Button, } from '@digitools/honest-react';
import useGet from '../../../../../hooks/useGet';
import AbsenceDetailsFilter from '../filters/AbsenceDetailsFilter';
import ReviewField from 'src/packages/cli/pages/review/ReviewField';
import Card from 'src/components/Cards/Card';
import useTranslate from 'src/hooks/useTranslate';
import getLeaveBalanceAsOfDate from 'src/packages/status/utilities/getLeaveBalanceAsOfDate';
import getLeaveStatus from 'src/packages/status/utilities/getLeaveStatus';
import getLeaveStatusReason from 'src/packages/status/utilities/getLeaveStatusReason';
import getLeaveProgramType from 'src/packages/status/utilities/getLeaveProgramType';
import checkDate from 'src/packages/status/utilities/checkDate';
import { downloadLeaveDates } from './downloadReport';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import Media from 'react-media';
import { StatusMessage } from '../CoverageDetails/ClaimsAndLeaves';
import useExperience from 'src/hooks/useExperience';
import { useTranslation } from 'react-i18next';
//TODO: test data for local testing w/o back end data
// const testDatas = [
//   {
//       "date": "09/15/2024",
//       "duration": 8,
//       "program": "USFMLA",
//       "status": "AP",
//       "reasonCode": "    ",
//       "reportedOn": "09/18/2024",
//       "addedOn": "09/18/2024",
//       "updatedOn": "09/18/2024",
//       "leaveType": "FMC",
//       "leaveStatus": "AP",
//       "requestTimeCode": "  ",
//       "requestTimeReasonCode": "  "
//   }
// ,
// {
//     "date": "09/16/2024",
//     "duration": 7,
//     "program": "OTH",
//     "status": "AP",
//     "reasonCode": "    ",
//     "reportedOn": "09/17/2024",
//     "addedOn": "09/17/2024",
//     "updatedOn": "01/01/1900",
//     "leaveType": "OTH",
//     "leaveStatus": "AP",
//     "requestTimeCode": "  ",
//     "requestTimeReasonCode": "  "
// },
// {
//     "date": "09/19/2024",
//     "duration": 6,
//     "program": null,
//     "status": "NA",
//     "reasonCode": "NA",
//     "reportedOn": "09/20/2024",
//     "addedOn": "09/20/2024",
//     "updatedOn": "01/01/1900",
//     "leaveType": "FMC",
//     "leaveStatus": "AP",
//     "requestTimeCode": "  ",
//     "requestTimeReasonCode": "  "
// },
// {
//     "date": "09/20/2024",
//     "duration": 1,
//     "program": "CA",
//     "status": "AT",
//     "reasonCode": "BB",
//     "reportedOn": "09/19/2024",
//     "addedOn": "09/19/2024",
//     "updatedOn": "01/01/1900",
//     "leaveType": "FMC",
//     "leaveStatus": "AP",
//     "requestTimeCode": "  ",
//     "requestTimeReasonCode": "  "
// },
// {
//   "date": "09/27/2024",
//   "duration": 1,
//   "program": "CA",
//   "status": "NA",
//   "reasonCode": "BB",
//   "reportedOn": "09/19/2024",
//   "addedOn": "09/19/2024",
//   "updatedOn": "01/01/1900",
//   "leaveType": "FMC",
//   "leaveStatus": "AP",
//   "requestTimeCode": "  ",
//   "requestTimeReasonCode": "  "
// },
// {
//   "date": "09/22/2024",
//   "duration": 1,
//   "program": "CA",
//   "status": "NA",
//   "reasonCode": "BB",
//   "reportedOn": "09/19/2024",
//   "addedOn": "09/19/2024",
//   "updatedOn": "01/01/1900",
//   "leaveType": "FMC",
//   "leaveStatus": "AP",
//   "requestTimeCode": "  ",
//   "requestTimeReasonCode": "  "
// },
// {
//   "date": "09/23/2024",
//   "duration": 1,
//   "program": "CA",
//   "status": "NA",
//   "reasonCode": "BB",
//   "reportedOn": "09/19/2024",
//   "addedOn": "09/19/2024",
//   "updatedOn": "01/01/1900",
//   "leaveType": "FMC",
//   "leaveStatus": "AP",
//   "requestTimeCode": "  ",
//   "requestTimeReasonCode": "  "
// }];
const NoAbsencesError = () => {
    const { t } = useTranslate();
    return (<Alert type={'info'}>
    <span>{t(ABSENCE_DETAILS_TEXT.errorNoAbsences)}</span>
  </Alert>);
};
// border of absence cards will rotate through these colors
const borderColors = [
    'secondaryLight',
    // 'secondary',
    'secondaryDark',
    // 'primaryDarker',
    // 'primary',
    // 'primaryLight',
    // 'warning',
    // 'warningLight',
    // 'warning',
    // 'primaryLight',
    // 'primary',
    // 'primaryDarker',
];
const ABSENCE_DETAILS_TEXT = {
    absencesTakenHeading: {
        english: 'Absences taken',
        spanish: 'Ausencias tomadas',
    },
    asOf: {
        english: 'as of',
        spanish: 'a partir de',
    },
    workScheduleDetails: {
        english: 'Work schedule details',
        spanish: 'Detalles del horario de trabajo',
    },
    daysInWorkWeek: {
        english: 'Days in work week',
        spanish: 'Días en la semana laboral',
    },
    hoursInWorkWeek: {
        english: 'Hours in work week',
        spanish: 'Horas en la semana laboral',
    },
    hoursinWorkDay: {
        english: 'Hours in work day',
        spanish: 'Horas en el día de trabajo',
    },
    leaveDate: {
        english: 'Leave date',
        spanish: 'Fecha de partida'
    },
    program: {
        english: 'Program',
        spanish: 'Programa',
    },
    status: {
        english: 'Status',
        spanish: 'Estado',
    },
    hours: {
        english: 'Hours',
        spanish: 'horas'
    },
    reportedOn: {
        english: 'Reported on',
        spanish: 'Reportado en',
    },
    addedToLeave: {
        english: 'Added to leave',
        spanish: 'Añadido a Leave',
    },
    lastModified: {
        english: 'Last modified',
        spanish: 'Última modificación',
    },
    errorNoAbsences: {
        english: 'There are no absences to display at this time.',
        spanish: 'No hay ausencias para mostrar en este momento.'
    },
    errorUnexpected: {
        english: 'Something went wrong while loading absences.',
        spanish: 'Algo salió mal al cargar las ausencias.',
    },
    downloadAll: {
        english: 'Download all',
        spanish: 'Descargar todo'
    }
};
const AbsencesList = ({ pagedData }) => {
    const { t } = useTranslate();
    return (<>
      {pagedData.map((absence, i) => (<Card data-testid='absence' small={true} fontSize={'small'} heading={''} borderColor={borderColors[i % borderColors.length]}>
          <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
            <ReviewField style={{ maxWidth: '50%', flexGrow: '3' }} label={t(ABSENCE_DETAILS_TEXT.leaveDate)} value={absence.date}/>
            <ReviewField style={{ flexGrow: '1', }} label={'Hours'} value={absence.duration}/>
            <ReviewField style={{ flexGrow: '2', minWidth: '8rem' }} label={t(ABSENCE_DETAILS_TEXT.program)} value={absence.program}/>
            <ReviewField style={{ flexGrow: '2', minWidth: '8rem' }} label={t(ABSENCE_DETAILS_TEXT.status)} value={absence.status + (absence.reason && '*')}/>
            {/* <ReviewField style={{ flexGrow: '.25' }} label={'Reason'} value={getLeaveStatusReason(absence)} /> */}
            <ReviewField style={{ maxWidth: '50%', flexGrow: '3' }} label={t(ABSENCE_DETAILS_TEXT.reportedOn)} value={absence.reportedOn}/>
            <ReviewField style={{ maxWidth: '50%', flexGrow: '3' }} label={t(ABSENCE_DETAILS_TEXT.addedToLeave)} value={absence.addedOn}/>
            <ReviewField style={{ flexGrow: '5' }} label={t(ABSENCE_DETAILS_TEXT.lastModified)} value={absence.updatedOn}/>
          </div>
          {absence.reason &&
                <StatusMessage><Icon size='sm' name='important-message' color='grayDark' className='ml-2 mr-2'/>{'*' + absence.reason}</StatusMessage>}
        </Card>))}
    </>);
};
const AbsenceDetailsNew = ({ leaveDetails, leave, employee }) => {
    // const { t } = useTranslation();
    const { t } = useTranslate();
    const { t: oldTranslate } = useTranslation();
    const [filteredData, setFilteredData] = useState();
    const { trackEvent } = useTealium();
    const { changeLanguage } = useExperience();
    if (!leaveDetails || !leave) {
        return <NoAbsencesError />;
    }
    const workDays = employee.numberDaysWorkWeek;
    const workHoursInDay = employee.numberHoursForWorkDay;
    const workHoursInWeek = workDays && workHoursInDay ? workDays * workHoursInDay : '';
    if (workDays === '0' && workHoursInDay === '0.0' && leave.lveCatg === 'C') {
        return <NoAbsencesError />;
    }
    const { error, loading, data: leaveDatesData, } = useGet({
        url: '/status-service/absences/' + leave.lveNum,
    });
    if (loading) {
        return <Spinner id='absence-details-table-spinner'/>;
    }
    const isNoData = leaveDatesData && leaveDatesData.length === 0;
    if (isNoData || (error && error.response && error.response.status === 404)) {
        return <NoAbsencesError />;
    }
    else if (error && error.response) {
        return (<Alert type='error' data-testid='AbsencesError'>
        <span><strong>Error! </strong>{t(ABSENCE_DETAILS_TEXT.errorUnexpected)}</span>
      </Alert>);
    }
    const print = () => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.PRINT,
            event_name: 'Print-LeaveDatesTaken',
        });
        downloadLeaveDates(leaveDatesData, leave, name, oldTranslate);
    };
    // show filtered data if the form has updated that
    let absenceDetailsList = filteredData ? filteredData : leaveDatesData;
    // Format data for display -- FIXME: this logic should live in back end and return translated results for strings
    absenceDetailsList = absenceDetailsList.map((absence) => {
        // absence.requestTimeReasonCode = 'NE'; // hack to get reasons to show for testing
        return {
            date: checkDate(absence.date),
            duration: `${Math.floor(absence.duration / 60)}:${(absence.duration % 60).toString().padStart(2, '0')}`,
            program: getLeaveProgramType(absence),
            status: getLeaveStatus(absence),
            reason: getLeaveStatusReason(absence),
            reportedOn: checkDate(absence.reportedOn),
            addedOn: checkDate(absence.addedOn),
            updatedOn: checkDate(absence.updatedOn),
            requestTimeCode: absence.requestTimeCode,
            requestTimeReasonCode: absence.requestTimeReasonCode,
        };
    });
    return (<>
      <Card small={true} elemType='h3' fontSize={'small'} fontColor={'grayDark'} heading={<>
            <Icon className='mr-2' size='small' name='calendar' color='grayDark'/>
            {t(ABSENCE_DETAILS_TEXT.workScheduleDetails)}
          </>}>
        <div style={{ display: 'flex', gap: '1rem' }}>
          {workDays !== '0' && workHoursInDay !== '0.0' ? (<>
              <ReviewField style={{ flexGrow: '.25' }} label={t(ABSENCE_DETAILS_TEXT.daysInWorkWeek)} value={workDays}/>
              <ReviewField style={{ flexGrow: '.25' }} label={t(ABSENCE_DETAILS_TEXT.hoursInWorkWeek)} value={workHoursInWeek}/>
              <ReviewField style={{ flexGrow: '10' }} label={t(ABSENCE_DETAILS_TEXT.hoursinWorkDay)} value={workHoursInDay}/>
            </>) : ('There are no work schedule details to display.')}
        </div>
      </Card>

      {leave.lveCatg !== 'C' && (<>
          <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                flexWrap: 'wrap',
                width: '100%',
            }}>
            <div>
              <Heading color={'primaryDark'} elemType='h2' style={{ fontSize: '1.625rem' }}>
                {`${t(ABSENCE_DETAILS_TEXT.absencesTakenHeading)}`}
              </Heading>
              <Heading className='mt-1' color={'grayDarker'} elemType='h3' style={{ fontSize: '1.125rem' }}>
                {`${t(ABSENCE_DETAILS_TEXT.asOf)} ${getLeaveBalanceAsOfDate(leave)}`}
              </Heading>
            </div>
            <Media query='(max-width: 560px)'>
              {matches => matches ? (<>
                    <Button style={{ width: '100%', marginTop: '.5rem' }} buttonType={'secondary'} type={'button'} onClick={print} aria-label={t(ABSENCE_DETAILS_TEXT.downloadAll)} data-testid='download-all-absences'>
                      <Icon name='download' color={'secondary'} style={{ paddingRight: '5px' }}/>
                      {t(ABSENCE_DETAILS_TEXT.downloadAll)}
                    </Button>
                  </>) : (<>
                    <Button buttonType={'text'} type={'button'} onClick={print} aria-label={t(ABSENCE_DETAILS_TEXT.downloadAll)} data-testid='download-all-absences'>
                      <Icon name='download' color={'secondary'} style={{ paddingRight: '5px' }}/>
                      {t(ABSENCE_DETAILS_TEXT.downloadAll)}
                    </Button>
                  </>)}
            </Media>
          </div>
          <hr className='mt-2'/>
          {/* NOTE: can change next line to `testData` to test stuff */}
          <AbsenceDetailsFilter initialData={leaveDatesData} setFilteredData={setFilteredData}/>
          <Paginator data={absenceDetailsList} renderContent={AbsencesList} managed={true} initialRowsPerPage={5}/>
        </>)}
    </>);
};
export default AbsenceDetailsNew;
