import React from 'react';
import { Row, Col, Alert, Icon } from '@digitools/honest-react';
import { useTranslation } from 'react-i18next';
import checkDate from '../../../utilities/checkDate';
import useAuthentication from '../../../../../hooks/useAuthentication';
import ReviewField from '../../../../cli/pages/review/ReviewField';
import titleCase from 'src/packages/status/utilities/titleCase';
import Card from 'src/components/Cards/Card';
import styled from 'styled-components';
const StatusMessage = styled('div') `
    color: ${({ theme }) => theme?.palette?.darkGray}
    font-Style: italic;
    font-size: .9rem;
    margin-top: .75rem;
    margin-bottom: .75rem;
`;
const Error = () => (<Alert type={'error'}>
        <span>
            <strong>Error!</strong>&nbsp;Unable to load claims dates.
        </span>
    </Alert>);
const ClaimDatesCard = ({ claim }) => {
    const { user } = useAuthentication();
    const { t } = useTranslation();
    const isSTDLTD = claim.prodType === 'STD' || claim.prodType === 'LTD';
    if (!claim) {
        return <Error />;
    }
    ;
    return (<Card data-testid={'claimDates'} selectable={false} fontSize='large' small={true}>
            <Row className='mb-2'>
                <Col sm={6} md={3} size={6} className='mb-2'>
                    <ReviewField small label={titleCase(t('claimReceivedText').toString())} value={claim.clmRecdDte && claim.clmRecdDte.split(' ')[0]}/>
                </Col>
                <Col sm={6} md={3} size={6} className='mb-2'>
                    {/* @ts-ignore */}
                    <ReviewField small label={titleCase(t('lastDateWorkedText').toString())} value={checkDate(claim.clmLastWrkDte)}/>
                </Col>
                <Col sm={6} md={3} size={6} className='mb-2'>
                    {/* @ts-ignore */}
                    <ReviewField data-private={true} small label={titleCase(t('dateOfDisabilityText').toString())} value={checkDate(claim.clmDisabDte)}/>
                </Col>
                <Col sm={6} md={3} size={6}>
                    {/* @ts-ignore */}
                    <ReviewField data-private={true} small label={titleCase(t('determinationDateText').toString())} value={checkDate(claim.clmDtrmDte)}/>
                </Col>
            </Row>
            <hr className={'mt-3 mb-3 d-sm-block d-md-none'}/>
            <Row className='mb-2'>
                <Col sm={6} md={3} size={6} className='mb-2'>
                    {/* @ts-ignore */}
                    <ReviewField small label={titleCase(t('benefitsBeginText').toString())} value={checkDate(claim.benBeginDte)}/>
                </Col>
                <Col sm={6} md={3} size={6} className='mb-2'>
                    {/* @ts-ignore */}
                    <ReviewField small label={titleCase(t('benefitsEndText').toString())} value={checkDate(claim.benEndDte)}/>
                </Col>
                <Col sm={6} md={3} size={6} className='mb-2'>
                    {/* @ts-ignore */}
                    <ReviewField small label={user?.customerInContext?.smallMid ? `${isSTDLTD ? '*' : ''} ${titleCase(t('approvedToText').toString())}` : `${isSTDLTD ? '*' : ''} ${titleCase(t('approvedThroughText').toString())}`} value={checkDate(claim.disabApprvThruDte)}/>
                </Col>
                <Col sm={6} md={3} size={6}>
                    {/* @ts-ignore */}
                    <ReviewField small label={titleCase(t('reopenDateText').toString())} value={checkDate(claim.clmReopnDte)}/>
                </Col>
            </Row>
            <hr className={'mt-3 mb-3 d-sm-block d-md-none'}/>
            <Row className='mb-2'>
                <Col sm={6} md={3} size={6} className='mb-2'>
                    {/* @ts-ignore */}
                    <ReviewField small label={titleCase(t('returnToWorkEstimatedText').toString())} value={checkDate(claim.clmExpecRtrnWrkDte)}/>
                </Col>
                <Col sm={6} md={3} size={6} className='mb-2'>
                    {/* @ts-ignore */}
                    <ReviewField small label={titleCase(t('returnToWorkModifiedText').toString())} value={checkDate(claim.partDtyRtrnWrkDte)}/>
                </Col>
                <Col sm={6} md={6} size={12}>
                    {/* @ts-ignore */}
                    <ReviewField small label={titleCase(t('returnToWorkActualText').toString())} value={checkDate(claim.fullDtyRtrnWrkDte)}/>
                </Col>
            </Row>
            {user?.customerInContext?.smallMid
            ? ''
            : isSTDLTD && (<Row className={'ml-0'} data-testid='date-disclaimer'>
                        <StatusMessage>
                            <Icon size='sm' name='important-message' color='grayDark' className='ml-2 mr-2'/>
                            <i>* {t('dateDisclaimerText')}</i>
                        </StatusMessage>
                    </Row>)}
        </Card>);
};
export default ClaimDatesCard;
