import React, { useState } from 'react';
import { Alert, Button, CompositeTable, Spinner, TabSection } from '@digitools/honest-react';
import useGet from '../../../../../hooks/useGet';
//import useGoogleAnalytics from '../../../../../hooks/useGoogleAnalytics';
import useTealium from '../../../../../hooks/useTealium';
import { compareAsc, format, parse, parseJSON } from 'date-fns';
import axios from 'axios';
import useAuthentication from '../../../../../hooks/useAuthentication';
import { saveAs } from 'file-saver';
import { TEALIUM_EVENT } from '../../../../../constants/tealiumConstants';
/**
 * Represents a TabSection for AS (Accommodation Status) Correspondences
 * rendered by the AccommodationDetails page. (permissions handled there)
 */
const CorrespondenceTab = ({ accommodationId }) => {
    const { data: correspondenceData, loading: correspondenceLoading, error: correspondenceError, } = useGet({
        url: `/status-service/correspondence/accommodation/${accommodationId}`,
    }) || {}; // the || {} is for tests where this useGet is not mocked out.. prevents undefined
    const [isDownloading, setIsDownloading] = useState(false);
    const [downloadError, setDownloadError] = useState();
    const { authHeader } = useAuthentication();
    const { trackEvent } = useTealium();
    const downloadCorrespondence = async (docId, documentTitle) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
            event_type: TEALIUM_EVENT.EVENT_TYPE.DOWNLOAD_CORRESPONDENCE,
            event_name: 'Download AS Correspondence',
        });
        setIsDownloading(true);
        try {
            const response = await axios.get(`/status-service/correspondence/accommodation/${accommodationId}/download/${docId}`, {
                responseType: 'blob',
                headers: { Authorization: authHeader() },
            });
            const fileExtension = response.headers['content-disposition']?.split('.')[1]?.replace('"', '');
            const filename = `${documentTitle}.${fileExtension}`;
            const blob = new Blob([response.data], { type: response.headers['content-disposition'] });
            saveAs(blob, filename);
            setIsDownloading(false);
        }
        catch (error) {
            const status = error && error.response && error.response.status;
            if (status === 404) {
                setDownloadError(`${documentTitle} not found.`);
            }
            else if (status === 403) {
                setDownloadError(`You are not allowed to download ${documentTitle}.`);
            }
            else {
                setDownloadError(`Something went wrong downloading ${documentTitle}, please try again later.`);
            }
            setIsDownloading(false);
        }
    };
    const downloadLink = (docId, documentTitle) => {
        const buttonTitle = documentTitle && documentTitle.length > 0 ? documentTitle : 'Download';
        return (<Button data-testid={'correspondence-download-button'} buttonType={'text'} disabled={isDownloading} data-private={true} onClick={() => downloadCorrespondence(docId, documentTitle)} style={{ textAlign: 'left', padding: 0 }}>
        {buttonTitle}
      </Button>);
    };
    const downloadErrorAlert = (<Alert data-testid={'correspondence-download-alert'} type={'error'} closeable={true} onClose={() => setDownloadError(undefined)}>
      {downloadError}
    </Alert>);
    const correspondenceColumns = [
        {
            Header: 'Document Type',
            accessor: 'documentType',
            id: 'documentType',
        },
        {
            Header: 'Description',
            accessor: 'description',
            id: 'description',
            Cell: ({ data }) => <span data-private={true}>{data._original.description}</span>,
        },
        {
            Header: 'Title',
            accessor: 'title',
            id: 'title',
            Cell: ({ data }) => downloadLink(data._original.docId, data._original.title),
        },
        {
            Header: 'Document Date',
            accessor: 'documentDate',
            id: 'documentDate',
            Cell: ({ data }) => <>{data._original.documentDate && format(new Date(data._original.documentDate), 'M/d/yyyy')}</>,
            sortMethod: (a, b) => compareAsc(parseJSON(a), parseJSON(b)),
        },
        {
            Header: 'Sent Date',
            accessor: 'sentDate',
            id: 'sentDate',
            Cell: ({ data }) => {
                return <>{data._original.sentDate && format(new Date(data._original.sentDate), 'M/d/yyyy')}</>;
            },
            sortMethod: (a, b) => compareAsc(parse(a, 'P', new Date()), parse(b, 'P', new Date())),
        },
        {
            Header: 'Sent To',
            accessor: 'sentToFullName',
            id: 'sentToFullName',
            Cell: ({ data }) => <span data-private={true}>{data._original.sentToFullName}</span>,
        },
        {
            Header: 'Send Method',
            accessor: 'sentToDistributionMethod',
            id: 'sentToDistributionMethod',
        },
    ];
    const defaultCorrespondenceSort = [
        {
            id: 'documentDate',
            desc: true,
        },
    ];
    /**
     * Render loading spinner inside tab section while data loads
     * Render info alert if data comes back empty
     * Render error alert if data results in an error
     * Render table for non-zero length data
     */
    return (<TabSection name='correspondence-tab' label='Correspondence' onClick={() => trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.TAB,
            event_type: TEALIUM_EVENT.EVENT_TYPE.AS_CORRESPONDENCE,
            event_name: 'AS Correspondence',
        })}>
      {correspondenceLoading && (<div data-testid={'correspondence-table-loading'}>
          <Spinner id='correspondence-tab-spinner'/>
        </div>)}
      {downloadError && downloadErrorAlert}
      {!correspondenceLoading && correspondenceData && correspondenceData.length > 0 && (<CompositeTable data-testid='correspondence-table' data={correspondenceData} columns={correspondenceColumns} defaultSorted={defaultCorrespondenceSort}/>)}
      {!correspondenceLoading && correspondenceData && correspondenceData.length === 0 && (<Alert data-testid='correspondence-table-nodata' type='info'>
          There are no correspondence items for this accommodation.
        </Alert>)}
      {!correspondenceLoading && correspondenceError && (<Alert data-testid='correspondence-table-error' type='error'>
          There was an error retrieving correspondence for this accommodation.
        </Alert>)}
    </TabSection>);
};
export default CorrespondenceTab;
