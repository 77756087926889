import React from 'react';
import DentalRouter from './routes/DentalRouter';
import GenericErrorBoundary from 'src/components/errorPages/GenericErrorBoundary';
import { DentalDataProvider } from './providers/DentalDataProvider';
const DentalContainer = () => {
    return (<GenericErrorBoundary>
      <DentalDataProvider>
        <DentalRouter />
      </DentalDataProvider>
    </GenericErrorBoundary>);
};
export default DentalContainer;
