import React, { useEffect, useState } from 'react';
import { Box, Col, Heading, SelectInput, themes } from '@digitools/honest-react';
import styled from 'styled-components';
import { NavLink, useNavigate } from 'react-router-dom';
import { encode } from 'status/utilities/hash';
import useDentalDataContext from './hooks/useDentalContext';
import { TEALIUM_EVENT, TEALIUM_PAGE_VIEW } from 'src/constants/tealiumConstants';
import useTealium from 'src/hooks/useTealium';
const PathNavLink = (props) => {
    return <NavLink {...props}/>;
};
export const StyledNavLink = styled(PathNavLink) `
  color: ${({ theme }) => `${theme.palette.black}`};
  font-weight: 400;
  border-top: ${({ theme }) => `1px solid ${theme.palette.grayLighter}`};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:last-child {
    border-bottom: ${({ theme }) => `1px solid ${theme.palette.grayLighter}`};
  }

  &.active {
    font-weight: 500;
    position: relative;
    color: ${({ theme }) => `${theme.palette.primary}`};
    padding: 1rem 0rem 1rem 1rem;

    &:before {
      content: '';
      height: 50%;
      width: 3px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: ${({ theme }) => `${theme.palette.primary}`};
    }
  }
`;
const Dental = () => {
    const { subscriber, currentMember, success, errorMessage } = useDentalDataContext();
    const { trackEvent, trackView } = useTealium();
    const trackDropdownEvent = (member) => {
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.CHANGE,
            event_name: TEALIUM_EVENT.EVENT_NAME.MEMBER,
            event_type: TEALIUM_EVENT.EVENT_TYPE.DENTAL_INSURANCE,
            event_version: member,
        });
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.DENTAL,
            page_l4: 'employee',
            page_l5: TEALIUM_EVENT.EVENT_TYPE.DENTAL_INSURANCE,
            user_role: 'employee',
        });
    };
    const [mobile, setMobile] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        const checkMobileView = () => {
            setMobile(window.innerWidth <= 1439);
        };
        window.addEventListener('resize', checkMobileView);
        checkMobileView();
    }, []);
    return (<>
      {' '}
      {success ? (<>
          {currentMember && subscriber && (<>
              {mobile ? (<Box color={'primary'} data-testid='dental-dropdown-box'>
                  <Heading color={'white'} elemType={'h2'}>
                    Select a family member
                  </Heading>
                  <SelectInput defaultChecked={true} initialValue={[1]} ariaLabelText={'Member'} data-testid='member-dropdown' field={'member'} title={'member-dropdown'} options={[
                        ...[
                            {
                                label: `${subscriber.firstName} ${subscriber.lastName}`,
                                value: subscriber.id,
                            },
                        ],
                        ...(subscriber.dependents?.map(dependent => ({
                            label: `${dependent.firstName} ${dependent.lastName}`,
                            value: dependent.id,
                        })) || []),
                    ]} onChange={(event) => {
                        navigate({
                            pathname: `/employee/dental`,
                            search: `?memberId=${encode(event.target.value)}`,
                        });
                        if (event.target.selectedIndex - 1 === 0) {
                            trackDropdownEvent('subscriber');
                        }
                        else {
                            trackDropdownEvent('member' + (event.target.selectedIndex - 1));
                        }
                    }} required={true} theme={themes.lincoln}/>
                </Box>) : (<Col md={3} sm={4}>
                  <nav style={{ display: 'flex', flexDirection: 'column' }}>
                    {[...[subscriber], ...(subscriber.dependents || [])].map((member, index) => (<StyledNavLink key={encode(member?.id)} data-public={true} to={{
                            pathname: `/employee/dental`,
                            search: `?memberId=${encode(member?.id)}`,
                        }} onClick={() => {
                            trackDropdownEvent(index === 0 ? 'subscriber' : 'member' + index);
                        }}>
                        {`${member?.firstName} ${member?.lastName}`}
                      </StyledNavLink>))}
                  </nav>
                </Col>)}
            </>)}
        </>) : (<>{errorMessage}</>)}
    </>);
};
export default Dental;
