import React from 'react';
import { Row, Alert, Col } from '@digitools/honest-react';
import { useTranslation } from 'react-i18next';
import checkDate from '../../../utilities/checkDate';
import ReviewField from '../../../../cli/pages/review/ReviewField';
import titleCase from '../../../utilities/titleCase';
import Card from 'src/components/Cards/Card';
const Error = () => (<Alert type={'warning'}>
        <span>There are no leave dates to display at this time.</span>
    </Alert>);
const LeaveOverviewMobile = ({ leave, leaveDetails }) => {
    const { t } = useTranslation();
    var freqMode = '';
    var freqPrd = '';
    var epPrd = '';
    if (leaveDetails) {
        freqMode = leaveDetails.int_freq_mode && leaveDetails.int_freq_mode.trim();
        freqPrd = leaveDetails.int_freq_prd && leaveDetails.int_freq_prd.trim();
        epPrd = leaveDetails.int_freq_epsd_prd && leaveDetails.int_freq_epsd_prd.trim();
    }
    var periodText;
    //handle text for the period
    if (freqPrd != 'E') {
        periodText = freqPrd ? t('modeText_' + freqPrd) : '';
    }
    else {
        // handle episodes case, 'Per episode, 2 episodes per week'
        periodText = t('perEpisodeText') + ', ' + leaveDetails.int_freq_epsd_num_times + ' ';
        periodText = periodText + t('episodeText') + (leaveDetails.int_freq_epsd_num_times > 1 ? 's' : ''); //pluralize if over 1
        periodText = periodText + ' ' + t('perText') + ' ' + t('simpleModeText_' + epPrd);
    }
    if (!leaveDetails && !leave) {
        return <Error />;
    }
    return (<Card data-testid={'leaveOverview'} selectable={false} fontSize='large' small={true}>
            <Row className='mb-2'>
                <Col sm={6} md={4} size={6} className='mb-2'>
                    {/* @ts-ignore */}
                    <ReviewField small label={titleCase(t('leaveReceivedText').toString())} value={leave.lveRecdDte && leave.lveRecdDte.split(' ')[0]}/>
                </Col>
                <Col sm={6} md={4} size={6} className='mb-2'>
                    {/* @ts-ignore */}
                    <ReviewField small label={titleCase(t('leaveBeginText').toString())} value={checkDate(leave.lveBeginDte)}/>
                </Col>
                <Col sm={12} md={4} size={12}>
                    {/* @ts-ignore */}
                    <ReviewField small label={titleCase(t('leaveEndText').toString())} value={checkDate(leave.lveEndDte)}/>
                </Col>
            </Row>
            <hr className={'mt-3 mb-3 d-sm-block d-md-none'}/>
            <Row className='mb-2'>
                <Col sm={6} md={4} size={6} className='mb-2'>
                    {/* @ts-ignore */}
                    <ReviewField small label={titleCase(t('requestedBeginText').toString())} value={checkDate(leave.lveReqBegDte)}/>
                </Col>
                <Col sm={6} md={4} size={6} className='mb-2'>
                    {/* @ts-ignore */}
                    <ReviewField small label={titleCase(t('requestedEndText').toString())} value={checkDate(leave.lveReqEndDte)}/>
                </Col>
                <Col sm={12} md={4} size={12}>
                    {/* @ts-ignore */}
                    <ReviewField data-private={true} small label={titleCase(t('determinationDateText').toString())} value={checkDate(leave.lveDtrmDte)}/>
                </Col>
            </Row>
            <hr className={'mt-3 mb-3 d-sm-block d-md-none'}/>
            <Row className='mb-2'>
                <Col sm={6} md={4} size={6} className='mb-2'>
                    {/* @ts-ignore */}
                    <ReviewField small label={titleCase(t('lastDayWorkedText').toString())} value={checkDate(leave.lveLastWrkDte)}/>
                </Col>
                <Col sm={6} md={4} size={6} className='mb-2'>
                    {/* @ts-ignore */}
                    <ReviewField small label={titleCase(t('dateCertifiedFromText').toString())} value={checkDate(leave.certFromDte)}/>
                </Col>
                <Col sm={12} md={4} size={12}>
                    {/* @ts-ignore */}
                    <ReviewField small label={titleCase(t('dateCertifiedThroughText').toString())} value={checkDate(leave.certThruDte)}/>
                </Col>
            </Row>
            <hr className={'mt-3 mb-3 d-sm-block d-md-none'}/>
            <Row>
                <Col sm={6} md={4} size={6} className='mb-2'>
                    {/* @ts-ignore */}
                    <ReviewField small label={titleCase(t('intermittentApprovedFrequencyText').toString())} value={leaveDetails && leaveDetails.approvedFrequency ? leaveDetails.int_freq_amt : null}/>
                </Col>
                <Col sm={6} md={4} size={6} className='mb-2'>
                    {/* @ts-ignore */}
                    <ReviewField small label={titleCase(t('intermittentApprovedModeText').toString())} value={freqMode ? t('modeText_' + freqMode) : ''}/>
                </Col>
                <Col sm={6} md={3} size={6} className='mb-2'>
                    {/* @ts-ignore */}
                    <ReviewField small label={titleCase(t('intermittentApprovedPeriodText').toString())} value={periodText}/>
                </Col>
                <Col sm={6} md={1} size={6} className='mt-3 mt-sm-0'>
                    {/* @ts-ignore */}
                    <ReviewField small label={titleCase(t('takeoverText').toString())} value={leave.takeOver}/>
                </Col>
            </Row>
        </Card>);
};
export default LeaveOverviewMobile;
