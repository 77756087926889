import React, { useState, useLayoutEffect, useContext } from 'react';
import StatementOfHealth from './StatementOfHealth';
import AdditionalQualifyingQuestions from './ReflexiveQualifyingQuestions';
import useGoogleAnalytics from '../../../../hooks/useGoogleAnalytics';
import { StepperContext } from '@digitools/honest-react';
const StatementOfHealthContainer = () => {
    const [step, setStep] = useState(0);
    const stepper = useContext(StepperContext);
    const { trackClickWithPage } = useGoogleAnalytics();
    const next = () => {
        trackClickWithPage('continue', '/customer/eoi/qualifyingQuestions');
        setStep(1);
    };
    const back = () => {
        trackClickWithPage('back', '/customer/eoi/qualifyingQuestionsContinued');
        setStep(0);
    };
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);
    useLayoutEffect(() => {
        if (stepper.isEditing()) {
            setStep(0);
        }
    }, [stepper.isEditing()]);
    return (<>
      {step === 0 && <StatementOfHealth nextStep={next} data-private={true}/>}
      {step === 1 && <AdditionalQualifyingQuestions goBack={back} data-private={true}/>}
    </>);
};
export default StatementOfHealthContainer;
