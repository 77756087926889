import { Box, Icon, Heading, withTheme, } from '@digitools/honest-react';
import React from 'react';
import styled from 'styled-components';
//FIXME: Border Gradient not perfect/ready yet - squares corners and applies to right border too, WIP
const CardBox = styled(Box) `
    ${({ className, theme }) => className?.includes('selected') &&
    `
        border-color: ${theme?.palette?.secondary};
        outline: 1px solid  ${theme?.palette?.secondary}
        box-shadow: 0 2px 6px ${({ theme }) => theme?.palette?.error};
      `}
  ${({ className, theme }) => className?.includes('gradient') &&
    `border-image: linear-gradient(to bottom, ${theme?.palette?.secondaryLight}, ${theme?.palette?.gray}) 1 100%;`}
  
  width: 100%;
  box-sizing: border-box;
  transition: all 0.3s ease;
  box-shadow: 0px 1px 4px #0f1f2c40;
  &:hover {
    cursor: ${({ className }) => className?.includes('selectable') && `pointer`};
    box-shadow: ${({ theme, className }) => className?.includes('selectable') && `0 2px 5px${theme?.palette?.secondaryLight}`};
  }
  &:focus {
    box-shadow: ${({ theme, className }) => className?.includes('selectable') && `0 2px 5px${theme?.palette?.secondaryLight}`};
  }
  width: 100%;

`;
// Personally i think 1.15 rem is weirdly small for a heading element? - changed it so 1 is thinMode
const CardHeading = (props) => {
    const style = { fontSize: props.fontSize === 'small' ? '1.15rem' : props.fontSize === 'medium' ? '1.325rem' : '1.625rem', width: '90%' };
    return (<Heading color={props.fontColor ? props.fontColor : 'primaryDark'} elemType='h2' style={style} {...props}/>);
};
// to be used if there's one big action for the card - not yet implemented
const CardAction = (props) => {
    return (<button onClick={() => console.log('action clicked')}>
      <Icon name={props.name}/>
    </button>);
};
const Card = ({ heading, secondaryHeading, selectable, selected, cardLinks, children, small, fontSize, fontColor, borderColor, gradient, elemType = 'h2', tabIndex, theme, ...rest }) => {
    //not sure if im using this pattern or not
    let classNames = '';
    if (selectable && selected) {
        classNames += ' selected';
    }
    if (small) {
        classNames += ' thin';
    }
    return (<CardBox tabIndex={tabIndex} borderType='rounded' style={{
            borderLeft: borderColor ? `.5rem solid ${gradient ? 'transparent' : theme?.palette[borderColor]}` : '',
        }} className={`${selectable && 'selectable'} ${selected && 'selected'} ${gradient && 'gradient'}`} {...rest}>
      {heading && (<>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <CardHeading fontSize={fontSize} fontColor={fontColor} elemType={elemType} small={small}>
              {heading}
            </CardHeading>
            {secondaryHeading && <div className={'mt-0'}>{secondaryHeading}</div>}
          </div>
          <hr className={'mt-2 mb-3'}/>
        </>)}
      {children}
      {cardLinks && (<>
          <hr className={'mt-3 mb-2'}/>
          <div className='mt-2' style={{ display: 'flex', alignItems: 'center' }}>
            {cardLinks}
          </div>
        </>)}
    </CardBox>);
};
export default withTheme(Card);
