export default {
  welcome: 'Hola Mundo! ¡Estás en casa!',
  modal: '¡Bienvenido al modal!',
  genericError: '¡Lo sentimos! ¡Algo salió mal!',
  unauthorizedError: 'No tiene autorización para ver esta página.',
  helpText: 'Llame al 1-800-431-2958 para pedir asistencia de 8:00 AM a 8:00 PM, Hora del Este',
  disclaimer:
    'Los productos y servicios de seguros grupales descritos en el presente documento son emitidos por The Lincoln National Life Insurance Company, una compañía de Lincoln Financial Group.  Oficina central: Boston, Massachusetts Lincoln Financial Group es el nombre de comercialización para Lincoln National Corporation y sus filiales. Las filiales son responsables por separado de sus propias obligaciones financieras y contractuales.',
  rights: 'Lincoln National Corporation. Todos los derechos reservados.',
  about: 'Acerca de Lincoln Financial Group',
  terms: 'Términos y Condiciones',
  policy: 'Política de Privacidad',
  analytics: 'Google Analitico',
  statusHeader: 'Ver estado',

  // Claim Detail
  claimDatesText: 'Fechas de reclamo',
  paymentInfoHeader: 'Información de Pago Más Reciente',
  dateIssued: 'Último pago emitido',
  netAmount: 'Cantidad neta',
  paymentMethod: 'Método de pago',
  futurePaymentDate: 'Información de pagos futuros',
  paymentCheckText: 'Cheque',
  paymentDirectDepositText: 'Depósito Directo',
  disabilityDatesText: 'Fechas de incapacidad',
  claimReceivedText: 'Fecha de recepción de reclamación',
  lastDateWorkedText: 'Última fecha trabajada',
  dateOfDisabilityText: 'Fecha de incapacidad',
  determinationDateText: 'Fecha de determinación',
  benefitsBeginText: 'Fecha de inicio de beneficios',
  benefitsEndText: 'Fecha de finalización del beneficio máximo potencial',
  returnToWorkEstimatedText: 'Fecha de regreso al trabajo - Estimada',
  returnToWorkModifiedText: 'Fecha de regreso al trabajo - Modificado',
  returnToWorkActualText: 'Fecha de regreso al trabajo - Real',
  returnToWorkNumberText: 'Para informar su fecha de regreso al trabajo, llame al ',
  tab: 'Volver-al-trabajo',
  tabDefault: '¿Cuál es su fecha de regreso al trabajo?',
  sameEmployer: '¿Vas a volver a trabajar para',
  capacity: '¿Qué capacidad estás volviendo-al-trabajo?',
  fullTimeText: 'Tiempo-Completo',
  partTimeText: 'Tiempo-Parcial',
  yes: 'Sí',
  no: 'No',
  fullTimeDate: '¿Cuál es su fecha de regreso al trabajo a tiempo completo?',
  partTimeDate: '¿Cuál es su fecha de regreso al trabajo a tiempo parcial?',
  circumstances: 'Tenga en cuenta cualquier circunstancia especial con respecto a su regreso al trabajo.',
  submit: 'enviar',
  requiredMsg: 'Campo requerido',
  placeholder: 'Límite de 190 caracteres',
  estimated: 'Estimado',
  estimatedDateValidation: 'La fecha estimada de regreso al trabajo debe ser posterior a la fecha actual',
  returnToWorkValidation: 'La fecha de regreso al trabajo debe ser anterior a la fecha de hoy',
  circumstancesValidation: 'El texto debe tener menos de 190 caracteres.',
  specialCharValidation: 'Los siguientes caracteres especiales no se pueden usar al ingresar su nota \\ | " ~ ^ [ ]',
  selectDate: 'Seleccione una fecha',
  approvedThroughText: 'Fecha límite de aprobación',
  approvedToText: 'Aprobado hasta la fecha',
  reopenDateText: 'Fecha de reapertura',
  associatedLeaveText: 'Licencia asociada',
  caseManagerText: 'Información del gerente de caso',
  diagnosticCategoryText: 'Categoría de diagnóstico',
  disabilityCauseText: 'Causa de discapacidad',
  claimDetailsText: 'Reclamo',
  uploadDocumentsText: 'Subir Documentos',
  claimManagement: 'Gestión de reclamaciones',
  docUploaded: 'Documento subido con éxito',
  docAlert: 'Cargue los documentos RTW requeridos correspondientes',
  uploadRtwLabel: 'Cargar documentos RTW',
  helperText: 'Detalles de regreso-al-trabajo',
  helperTextContent: 'Ingrese los detalles de su regreso al trabajo a continuación. Si regresa-al-trabajo con una capacidad reducida, seleccione la opción de medio-tiempo.',
  docUploadHeader: 'Sube documentos relacionados con tu regreso-al-trabajo.',
  claimSuccessMessage: 'Espere de 1 a 3 días hábiles para que su envío se procese y se muestre con precisión en el portal.',
  leaveSuccessMessage: 'Sus detalles de regreso al trabajo se enviaron con éxito. Si su fecha de regreso al trabajo cambia, asegúrese de actualizar y enviar sus nuevos detalles de regreso al trabajo.',
  dateDisclaimerText: 'La fecha límite aprobada depende de que el demandante continúe cumpliendo la definición de discapacidad según las provisiones del contrato/plan y, por lo tanto, está sujeto a cambio.',
  claimErrorMessage: 'Lo sentimos, no pudimos enviar su solicitud en este momento. Espere un momento y vuelva a intentarlo. Si el problema continúa, por favor ',
  contactUs: 'Contacta con nosotras',
  none: 'Ninguno',
  cancel: 'Cancelar',
  updatelink: 'Actualizar volver-al-trabajo',

  // Leave Detail
  datesTakenText: 'Ausencias tomadas',
  dateText: 'Fecha de partida',
  leaveReceivedText: 'Fecha de recepción de licencia',
  leaveBeginText: 'Fecha de inicio de licencia',
  leaveEndText: 'Fecha de finalización de licencia',
  requestedBeginText: 'Fecha de inicio solicitada',
  requestedEndText: 'Fecha de finalización solicitada',
  intermittentApprovedFrequencyText: 'Frecuencia aprobada intermitente',
  intermittentApprovedModeText: 'Modo aprobado intermitente',
  intermittentApprovedPeriodText: 'Periodo aprobado intermitente',
  takeoverText: 'Tomar el control',
  lastDayWorkedText: 'Último día trabajado',
  dateCertifiedFromText: 'Fecha de certificación desde',
  dateCertifiedThroughText: 'Fecha de certificación hasta',
  approvedFrequencyText: 'Frecuencia de licencia aprobada',
  asOfText: 'A partir de',
  leaveProgramText: 'Programa de licencia',
  programHoursUsedText: 'Horas del programa usadas',
  programHoursRemainingText: 'Horas del programa restantes',
  programHoursDescriptionText:
    'Las horas de programa utilizadas y las horas restantes del programa que se muestran se basan en la última ausencia aplicada a esta licencia. Si ha informado ausencias adicionales, o ha tenido / tenido otro permiso, esto afectará sus saldos.',
  leaveTypeText: 'Tipo de Licencia',
  leaveReasonText: 'Motivo de la Licencia',
  leaveCategoryText: 'Categoría de licencia',
  associatedClaimText: 'Asociados Reclamación',
  leaveDetailsText: 'Licencia',
  leaveSpecialistText: 'Información de especialista en licencias',
  programType: 'Tipo de Programa',
  state: 'Estado',
  qualified: 'Calificado',
  qualifiedThrough: 'Fecha Límite de Calificado',
  eligible: 'Elegible',
  eligibleThrough: 'Fecha Límite de Elegible',
  leaveBalanceText: 'Saldo de Licencia',
  downloadText: 'Descargar',
  leaveOverviewText: 'Dejar resumen',
  leaveNotesText: 'dejar notas',

  //Absence Details
  absenceDateText: 'Fecha de ausencia',
  absenceDetails: 'Detalles de ausencia',
  workScheduleDetailsText: 'Detalles del horario de trabajo',
  daysInWorkWeekText: 'Días en la semana laboral',
  hoursInWorkWeekText: 'Horas en la semana laboral',
  hoursinWorkDayText: 'Horas en el día de trabajo',
  programText: 'Programa',
  reasonText: 'Razón',
  reportedText: 'Reportado en',
  addedToLeaveText: 'Añadido a leave',
  lastModifiedText: 'Última modificación',

  // Shared
  dayText: 'Día',
  hoursText: 'Horas',
  mondayText: 'Lunes',
  tuesdayText: 'Martes',
  wednesdayText: 'Miércoles',
  thursdayText: 'Jueves',
  fridayText: 'Viernes',
  saturdayText: 'Sábado',
  sundayText: 'Domingo',
  statusReasonText: 'Motivo del estado',
  statusText: 'Estado',
  closedDateText: 'Fecha de cierre',
  coverageText: 'Cobertura',
  phoneText: 'Número telefónico',
  faxText: 'Número de fax',
  returnCoverageDetailsText: 'Volver a los detalles de cobertura',
  productText: 'Producto',
  mailingAddressHeaderText: 'Dirección de envio"',
  workEmailText: 'Correo Electrónico del Trabajo',
  supervisorText: 'Supervisora',
  hoursWorkedInText: 'Horas trabajadas en los últimos 12 meses',
  serviceMonthsText: 'Meses de Servicio',
  statusAvailableText: 'Todos los estados disponibles para este empleado se enumeran a continuación.',
  salaryAmountText: 'Cantidad de salario',
  salaryModeText: 'Modo de salario',
  jobCategoryText: 'Job Category',
  jobTitleText: 'Título profesional',
  benefitEligibleText: 'Fecha elegible para beneficios',
  genderText: 'Sexo asignado al nacer',
  addressText: 'Dirección',
  emailText: 'Correo electrónico',

  homeAddressText: 'Direccion de casa',
  homePhoneText: 'Teléfono de casa',
  workAddressText: 'Direccion de trabajo',
  workPhoneText: 'Teléfono del trabajo',

  printSummaryText: 'Print Summary (Spanish)',

  findMyInfoEOIStatusLinkText_1: 'Tenga en cuenta: ',
  findMyInfoEOIStatusLinkText_2: 'Para la evidencia de asegurabilidad, puede continuar sin escribir un número y fecha de nacimiento. ',
  findMyInfoEOIStatusLinkText_3: 'Ver mi evidencia de asegurabilidad',

  // EOI Stuff
  eoiSpecialistHeader: 'Información Especialista de EOI',
  eoiSpecialistMailingAddressHeader: 'Dirección de envio"',
  eoiSpecialistPhoneNumberHeader: 'Número de teléfono',
  eoiSpecialistFaxNumberHeader: 'Número de fax',

  // Coverage Details
  allHeaderText: 'Todas',
  claimHeaderText: 'Reclamo',
  leaveHeaderText: 'Licencia',
  eoiHeaderText: 'Evidencia de asegurabilidad',
  eventNumberText: 'Evento#',
  receivedDateText: 'Fecha de recepción',
  statusDefinitionText: 'Definición del estado',
  questionsCaseManagerText: '¿Preguntas? Póngase en contacto con el administrador de casos que se muestra.',
  questionsLeaveSpecialistText: '¿Preguntas? Póngase en contacto con el especialista de vacaciones que se muestra.',

  // Modes and Periods
  modeText_H: 'Cada hora',
  modeText_D: 'Diario',
  modeText_W: 'Semanal',
  modeText_M: 'Mensual',
  modeText_Y: 'Anual',
  simpleModeText_H: 'hora',
  simpleModeText_D: 'día',
  simpleModeText_W: 'semana',
  simpleModeText_M: 'mes',
  simpleModeText_Y: 'año',
  perEpisodeText: 'Por episodio',
  episodeText: 'episodio',
  perText: 'por',

  //Payment List
  paymentInformationTabHeaderText: 'Información de Pago',
  checkDateHeaderText: 'Fecha de Cheque',
  beginPaymentDateHeaderText: 'Fecha de Inicio de Pago',
  endPaymentDateHeaderText: 'Fecha de Fin de Pago',
  checkStatusHeaderText: 'Estado de Cheque',
  grossBenefitsHeaderText: 'Beneficios Brutos',
  netBenefitsHeaderText: 'Beneficios Netos',
  checkNumberHeaderText: 'Número de Cheque',
  payeeHeaderText: 'Beneficiario del Cheque',
  issuedText: 'Emitido',
  IS: 'Emitido',
  cashedText: 'Cobrado',
  CA: 'Cobrado',

  //spanish text needs to be checked
  beginDateText: 'Fecha de inicio de pago',
  endDateText: 'Fecha de pago final',
  amountText: 'Cantidad',

  wageText: 'Salario',
  taxableText: 'Impuestos',
  nonTaxableText: 'Sin Inpuesto',
  federalText: 'Federal',

  checkDetailsHeaderText: 'Detalles de Cheque',
  benefitGrossAmountText: 'Beneficio Bruto',
  checkTotalOffsetAmountText: 'Compensaciones',
  checkAdjustedBenefitGrossAmountText: 'Bruto Ajustado',
  colaAmountText: 'COLA',
  checkTaxWithheldAmountText: 'Retención de Impuestos',
  checkTotalDeductionAmountText: 'Otras Deducciones',
  benefitNetAmountText: 'Beneficio Neto',

  checkFicaTaxWithheldAmountText: 'FICA',
  checkFederalTaxWithheldAmountText: 'Federal',
  checkStateTaxWithheldAmountText: 'Estado',
  checkLoclTaxWithheldAmountText: 'Local',
  checkSsoaiTaxWithheldAmountText: 'OASDI',
  checkMedicareTaxWithheldAmountText: 'Seguro médico del estado',
  noDataMessageOffset: 'No hay datos de compensación para mostrar',
  noDataMessageOtherDeductions: 'No hay más datos de deducción para mostrar',

  taxesText: 'Impuestos',
  withholdingAmountText: 'Cantidad de retención',
  totalText: 'Total',
  withholdingAmountSmallText: 'Cantidad',

  // Correspondence
  correspondenceText: 'Correspondencia',
  documentType: 'Tipo de Documento',
  description: 'Descripción',
  documentDate: 'Fecha del Documento',
  documentSentDate: 'Fecha Enviada',
  documentSentToName: 'Enviado a Nombre',
  documentMethodSent: 'Método Enviado',

  noInformationText: 'No Información Disponible',

  //CoverageDetail - AS Table Header
  event: 'Evento#',
  status: 'Estado',
  accommodationHeaderText: 'Acomodación',
  accommodationEventHeaderText: 'Acomodación Evento',

  //AccommodationDetails - AS Info
  eventId: 'ID del evento',
  eventStatus: 'Estado del evento',
  jobAccommodation: 'Acomodación laboral',
  eventReceivedDate: 'Fecha de recepción del evento',

  //AccommodationDetails - Request Info
  request: 'Solicitud',
  requestType: 'Tipo de solicitud',
  providedType: 'Tipo proporcionado',
  eeAccommodationCallForHelpText:
    'Si tiene alguna pregunta sobre los detalles de su solicitud de acomodación, comuníquese con su empleador.',

  //EOI - TODO: Move this lol
  eoiError:
    'Algo salió mal. Vuelva a cargar la página y vuelva a intentarlo. Si el problema persiste, comuníquese con el soporte técnico.',
  eoiHomepage: 'Ir a la página de inicio.',

  employeeDetails: 'Detalles del empleado',
};
