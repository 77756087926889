import { Button, Link } from '@digitools/honest-react';
import React from 'react';
import { updateUserTermsAndConditions } from 'src/api/UpdateProfileApi';
import { EMPLOYEE_HOME, EMPLOYER_HOME } from 'src/constants/applicationPermissionConstants';
import { format } from 'date-fns';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
const a11yClick = (event) => {
    if (event.type === 'click') {
        return true;
    }
    else if (event.type === 'keydown') {
        const code = event.charCode || event.keyCode;
        if (code === 32 || code === 13) {
            return true;
        }
    }
    else {
        return false;
    }
};
const getExperience = (applicationPermissions) => {
    if (applicationPermissions.indexOf(EMPLOYER_HOME) > -1) {
        return 'employer';
    }
    else if (applicationPermissions.indexOf(EMPLOYEE_HOME) > -1) {
        return 'employee';
    }
    else {
        return '';
    }
};
const getSSODeepLinkURL = async (landingPage, experience) => {
    switch (landingPage) {
        case 'EOI_OVERVIEW':
            return '/eoi';
        case 'STATUS_RESULTS':
            return '/status/' + experience;
        case 'CLAIM_LEAVE_OVERVIEW':
            return '/cli/' + experience + '/intake';
        case 'CLAIM_LEAVE_ABOUT_YOU':
            return '/cli/' + experience + '/intake';
        default:
            return '';
    }
};
const getContentForModal = ({ ...args }) => {
    const { language, changeLanguage } = args;
    const TermsAndConditonsEs = [
        {
            header: 'ACCESO A PORTAL Y ACUERDO DE PRIVACIDAD',
            paragraphs: [
                'El portal seguro de Lincoln Financial Group está diseñado para dar a nuestros clientes, demandantes, agentes designados, corredores y representantes, un medio confidencial para hacer negocios con Lincoln Financial Group a través de Internet. Este Acceso al portal y Acuerdo de privacidad (Acuerdo) aplica a este portal seguro e incorpora y complementa los Términos y condiciones que se encuentran en la parte inferior del portal seguro, así que examínelos con cuidado.',
                'El acceso a este portal seguro se limita SOLO a usuarios autorizados que acordaron cumplir con este Acuerdo. Todas las identificaciones y contraseñas de los usuarios proporcionadas a los usuarios estarán protegidas por los usuarios asignados y se utilizarán solamente para los fines que implican llevar a cabo negocios con Lincoln Financial Group, sus filiales y compañías afiliadas por los clientes o demandantes de Lincoln Financial Group, o en nombre de los clientes o demandantes de Lincoln Financial Group representados por el usuario autorizado.',
            ],
        },
        {
            header: 'TRANSACCIONES ELECTRÓNICAS Y CONSENTIMIENTO',
            paragraphs: [
                'Los usuarios del portal seguro acuerdan aceptar mediante medios electrónicos todos los documentos disponibles a través de My Lincoln Portal relacionados con todos los productos y servicios proporcionados por nosotros a usted, así como también cualquier otra comunicación relacionada. Todos los documentos se proporcionarán a través de My Lincoln Portal, utilizando el formato Adobe Acrobat PDF o cualquier otro formato elegido por nosotros.',
                'Usted acepta que, donde las leyes estatales aplicables lo permitan, la transmisión electrónica de cualquier documento relacionado con su póliza de seguro o cobertura reemplazará cualquier requisito de entrega de estos documentos a través de cualquier otro medio incluido, a mero título enunciativo, la entrega de correo de EE. UU. Las copias de papel de todos los documentos de seguros están disponibles bajo solicitud. Puede anular este consentimiento en cualquier momento al contactar a su Representante de RR.HH.',
            ],
        },
        {
            header: 'SOLICITUDES DE IDENTIFICACIÓN DE USUARIO',
            paragraphs: [
                'El acceso al portal seguro de Lincoln Financial Group se basa en un procedimiento de identificación de combinación de contraseña/identificación del usuario. Usted acepta mantener la confidencialidad de su combinación de contraseña/identificación del usuario y no permitirles su uso a terceros. Usted acepta notificar de inmediato a Lincoln Financial Group al 800-989-7854 (disponible 24 horas, los 7 días a la semana) ante cualquier pérdida de confidencialidad de la combinación de la contraseña/identificación del usuario o cualquier acceso no autorizado al portal seguro mediante el uso de su combinación de contraseña/identificación de usuario que le llame la atención. Será responsable de cualquier daño a Lincoln Financial Group, sus filiales, afiliados o clientes, incluido, a mero título enunciativo: cualquier rechazo de servicio; eliminación o modificación o creación de datos; acceso no autorizado a los datos de Lincoln Financial Group; o de cualquier otra acción directa o indirecta relacionada con el uso no autorizado o inadecuado de su identificación de usuario y contraseña por usted o por cualquier otra persona que obtenga acceso al portal seguro a través de usted. Lincoln Financial Group se reserva el derecho a finalizar su acceso por cualquier razón sin obligación y sin aviso previo para mantener la seguridad del sistema. ',
            ],
        },
        {
            header: 'PRIVACIDAD',
            paragraphs: [
                'Todos los datos privados transmitidos a este portal seguro están encriptados y usan Secure Sockets Layer (SSL). Lincoln Financial Group rastrea el nombre del dominio, la dirección de IP y el tipo de buscador de los visitantes de su sitio a través de un instrumento de información que se ejecuta en nuestro servidor. Esta información se utiliza internamente para los fines de seguridad y para mostrar la actividad y las tendencias del uso de nuestro sitio. Quizás compartamos cierta información proporcionada por usted en la actividad normal de llevar a cabo operaciones de seguros. Por ley se nos permite o se nos obliga a compartir cierta información acerca de los clientes y los demandantes, incluso sin su autorización, con 1) un tercero si es razonablemente necesario para permitir que el tercero realice los servicios de negocios para nosotros, como investigaciones de reclamaciones, evaluaciones relacionadas con los procedimientos legales o la detección de fraude o tergiversación material; 2) cualquiera de nuestras compañías afiliadas que le proveen servicios a usted; 3) autoridades regulatorias de seguros; 4) agencias de orden púbico u otras autoridades gubernamentales para proteger nuestros intereses o para informar actividades ilegales, y 5) una persona u organizaciones que llevan a cabo estudios de actuarios e investigaciones. No proporcionamos ninguna información a terceros para listas de correo.',
            ],
        },
        {
            header: 'CONFIDENCIALIDAD',
            paragraphs: [
                'La información contenida en, relacionada con, o proporcionada en conexión con este portal seguro es información confidencial y puede solo utilizarse para realizar las tareas específicamente relacionadas con los clientes o demandantes de Lincoln Financial Group. Si procede, usted utilizará procedimientos razonables para proteger los registros y datos de negocios del acceso inapropiado, acceso no autorizado o divulgación no autorizada y respetará estrictamente todas las leyes y regulaciones respecto a la privacidad de individuos, incluida, a mero título enunciativo, la privacidad de los registros médicos. No puede duplicar o reproducir la información ni los materiales disponibles en el portal seguro en su totalidad o parcialidad excepto para brindarle atención a clientes o demandantes de Lincoln Financial Group. Sin embargo, si está legalmente obligado o se le exige (por preguntas orales, interrogatorios, pedidos de información o documentos, citación, demanda de investigación civil o algún proceso similar) divulgar información confidencial, usted acepta notificar de inmediato a Lincoln Financial Group para que pueda buscar una orden preventiva u otro recurso adecuado (y si Lincoln Financial Group busca tal orden, usted cooperará según se solicite razonablemente a costa de Lincoln Financial Group) o renunciar a su cumplimiento con este Acuerdo. Si no se obtiene una orden preventiva u otro recurso adecuado, puede divulgar sólo la parte de la información confidencial que legalmente se requiere divulgar con el asesoramiento del abogado y cooperará por completo con Lincoln Financial Group para obtener garantías de que la información confidencial divulgada será acorde al máximo tratamiento de confidencialidad disponible.',
                'Debe notificar a Lincoln Financial Group sin demora indebida de cualquier incumplimiento de seguridad, intrusión o acceso no autorizado, adquisición, divulgación o uso de información confidencial, de propiedad o del cliente/demandante obtenida del portal seguro.',
            ],
        },
        {
            header: 'RESTRICCIONES EN EL USO DE MATERIALES',
            paragraphs: [
                'Usted acepta que no tiene participación patrimonial en o para los conceptos, diseños, técnicas de procesamiento de datos, expresión o estructura de cualquiera de los programas informáticos que comprenden este portal seguro de forma parcial o total, lo que incluye cualquier modificación, mejora, cambio u otra revisión que resulte de la ejecución de cualquier parte aquí establecida o de las que usted le proporcione a Lincoln Financial Group.',
                'Este portal seguro puede también contener información de propiedad y confidencial relacionada a nuestros planes de negocios, procesos, procedimientos, programas y operaciones, seguros y productos relacionados con seguros, incluido, a mero título enunciativo, la política específica o disposiciones contractuales, parámetros de programa de calificación, descuentos y créditos. Se le prohíbe expresamente utilizar, extraer, compilar, comparar, analizar, informar o almacenar, electrónicamente o en cualquier formato, toda nuestra información propuesta o relacionada, a menos que Lincoln Financial Group lo autorice específicamente por escrito. Esto incluye la suma de cualquier información propuesta de Lincoln Financial Group para benchmarking u para otro resumen industrial o fines comparativos. ',
            ],
        },
        {
            header: 'JURISDICCIÓN Y AVISO LEGAL',
            paragraphs: [
                'Este Acuerdo y su uso del portal seguro se regirán e interpretarán de conformidad con las leyes delCommonwealth de Massachusetts.',
                'Ni Lincoln Financial Group ni ninguna de sus filiales o compañías afiliadas prestan garantías en relación con la disponibilidad del portal seguro o servicios. Todos los datos y servicios se proporcionan COMO TAL.',
                'POR LA PRESENTE, SE RECHAZAN LAS GARANTÍAS IMPLÍCITAS DE ADECUACIÓN PARA UN PROPÓSITO PARTICULAR Y LA COMERCIABILIDAD Y NO SE APLICAN AL PORTAL SEGURO DE LA COMPAÑÍA.',
                'EN NINGÚN CASO LINCOLN FINANCIAL GROUP O CUALQUIERA DE SUS FILIALES O COMPAÑÍAS AFILIADAS SERÁN RESPONSABLES DE DAÑOS Y PERJUICIOS DE CUALQUIER NATURALEZA, SIN IMPORTAR SI DICHOS DAÑOS Y PERJUICIOS SON DIRECTOS, INDIRECTOS, ESPECIALES, CONSECUENTES O DE OTRA MANERA, QUE PUEDAN SURGIR DEL ACCESO O USO DEL PORTAL SEGURO DE LA COMPAÑÍA, O DE CUALQUIER SERVICIO O DATOS DISPONIBLES A TRAVÉS DE DICHO ACCESO, SIN IMPORTAR SI LA COMPAÑÍA O CUALQUIERA DE SUS FILIALES O COMPAÑÍAS AFILIADAS HAN SIDO ACONSEJADAS ACERCA DE LA POSIBILIDAD DE DAÑOS Y PERJUICIOS REALES O POTENCIALES.',
            ],
        },
        {
            header: 'DANOS REALES O POTENCIALES',
            paragraphs: [
                'Al marcar la casilla de verificación en la página de Registro, usted acepta los términos de este Acuerdo de acceso y privacidad del portal y los Términos y condiciones de este portal seguro. El uso o acceso no autorizado a este portal seguro de Lincoln Financial Group está estrictamente prohibido.',
                'Los productos y servicios de seguros grupales descritos en este documento son emitidos por The Lincoln National Life Insurance Company. Lincoln Financial Group es el nombre comercial de Lincoln National Corporation y sus afiliados. Los afiliados son responsables por separado de sus propias obligaciones financieras y contractuales.',
            ],
        },
    ];
    const TermsAndConditonsEn = [
        {
            header: 'PORTAL ACCESS & PRIVACY AGREEMENT',
            paragraphs: [
                'This Lincoln Financial secure portal is designed to give our customers, claimants, designated agents, brokers and representatives, a confidential means of doing business with Lincoln Financial Group through the Internet. This Portal Access & Privacy Agreement (Agreement) applies to this secure portal and incorporates and supplements the Terms and Conditions found at the bottom of the secure portal so please review them carefully.',
                'Access to this secure portal is intended ONLY for authorized users who have agreed to abide by this Agreement. All User IDs and passwords provided to users are to be protected by the assigned users and used only for the purposes of conducting business with Lincoln Financial Group, its subsidiaries and affiliated companies by Lincoln Financial Group customers or claimants, or on behalf of the Lincoln Financial Group customers or claimants represented by the authorized user',
            ],
        },
        {
            header: 'ELECTRONIC TRANSACTIONS AND CONSENT',
            paragraphs: [
                'Users of the secure portal agree to accept by electronic means all documents available through My Lincoln Portal related to all products and services provided by us to you as well as any other related communications. All documents will be provided to you via My Lincoln Portal using the Adobe Acrobat PDF format or any format chosen by us.',
                'You agree that, where permitted by applicable state law, the electronic transmission of any documents related to your insurance policy or coverage will replace any requirement of delivery of these documents by way of any other means including, but not limited to, U.S. Mail delivery. Paper copies of all insurance documents are available upon request. You may revoke this consent at any time by contacting your HR Representative.',
            ],
        },
        {
            header: 'USER ID REQUESTS',
            paragraphs: [
                'Access to Lincoln Financial Group’s secure portal is based on a userid/password combination identification procedure. You agree to maintain the confidentiality of your userid/password combination and not to allow use by anyone other than you. You agree to immediately report to Lincoln Financial Group at 800-989-7854 (available 24 hours a day/ 7 days a week) any loss of confidentiality of the userid/password combination or any unauthorized access to the secure portal using your userid/password combination that may come to your attention. You shall be responsible for any damage to Lincoln Financial Group, its subsidiaries, affiliates or customers, including but not limited to: any denial of service; deletion or modification or creation of data; unauthorized access to Lincoln Financial Group data; or, any other action directly or indirectly related to unauthorized or improper use of your userid and password by you, or any other person gaining access to the secure portal through you. Lincoln Financial Group reserves the right to terminate your access for any reason without liability and without prior notification to maintain system security.',
            ],
        },
        {
            header: 'PRIVACY',
            paragraphs: [
                'All private data transmitted to this secure portal is encrypted and uses Secure Sockets Layer (SSL). Lincoln Financial Group tracks the domain name, IP address and browser type of its site visitors through a reporting tool that runs on our server. This information is used internally for security purposes and to show activity and trends of our site usage. We may share certain information provided to us in the normal business of conducting insurance operations. We are permitted or required by law to share certain information about customers or claimants, even without your authorization, with 1) a third party if it is reasonably necessary to enable the party to perform business services for us, such as claims investigations, appraisals in regard to legal procedures, or the detection of fraud or material misrepresentations; 2) any of our affiliated companies who provide service to you; 3) insurance regulatory authorities; 4) law enforcement agencies or other governmental authorities to protect our interest or to report illegal activities; and 5) person or organizations conducting actuarial or research studies. We do not provide any information to third parties for mailing lists.',
            ],
        },
        {
            header: 'CONFIDENTIALITY',
            paragraphs: [
                'The information contained in, relating to or provided in connection with this secure portal is confidential information and may only be used by to perform the tasks specifically related to the Lincoln Financial Group customers or claimants. If applicable, you shall use reasonable procedures to protect business records and data from improper access, unauthorized access or unauthorized disclosure and shall observe strictly all laws and regulations regarding privacy of individuals, including but not limited to privacy of medical records. You may not nor will it permit the information and materials available on this secure portal to be duplicated or reproduced in whole or in part except for servicing the Lincoln Financial Group customer and/or claimant. If, however, you become legally compelled or required (by oral questions, interrogatories, requests for information or documents, subpoena, civil investigation demand or similar process) to disclose any confidential information, you agree to promptly notify Lincoln Financial Group so that it may seek a protective order or other appropriate remedy (and, if Lincoln Financial Group seeks such an order, you will provide such cooperation as is reasonably requested at Lincoln Financial Group’s expense) or waive its compliance with this Agreement. If a protective order or other appropriate remedy is not obtained, you may disclose only that portion of the confidential information that is legally required to be disclosed on advice of counsel and you will cooperate fully with Lincoln Financial Group to obtain assurances that the disclosed confidential information will be accorded the maximum confidential treatment available',
                'You must notify Lincoln Financial Group without unreasonable delay of any breach of security, intrusion, or any unauthorized access, acquisition, disclosure, or use of confidential, proprietary or customer/claimant Information obtained from this secure portal.',
            ],
        },
        {
            header: 'RESTRICTIONS ON USE OF MATERIALS',
            paragraphs: [
                'You agree that you have no proprietary interest in or to the concepts, design, data processing techniques, expression or structure of any of the computer programs comprising this secure portal in part or as a whole, including any modifications, improvements, changes or other revisions that result from any performance by any party hereunder or which are provided by you to Lincoln Financial Group.',
                'This secure portal may also contain proprietary and confidential information related to our business plans, processes, procedures, programs and operations, insurance and insurance related products, including but not limited to specific policy or contract provisions, rating program parameters, discounts and credits. You are expressly prohibited from using, extracting, compiling, comparing, analyzing reporting or storing, electronically or in any other format, any of our proposal or other related information in the aggregate unless specifically authorized by Lincoln Financial Group in writing. This includes the aggregation of any of Lincoln Financial Group proposal information for benchmarking or other industry summary or comparative purposes. ',
            ],
        },
        {
            header: 'JURISDICTION & DISCLAIMER ',
            paragraphs: [
                'This Agreement and your use of the secure portal will be governed and construed in accordance with the laws of the Commonwealth of Massachusetts.',
                'Neither Lincoln Financial Group nor any of its subsidiaries or affiliated companies makes any warranties with regard to the availability of the secure portal nor any services. All data and services are provided AS IS.',
                'THE IMPLIED WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE AND MERCHANTABILITY ARE HEREBY DISCLAIMED AND ARE NOT APPLICABLE TO THE COMPANY’S SECURE PORTAL.',
                'UNDER NO CIRCUMSTANCES WILL LINCOLN FINANCIAL GROUP OR ANY OF ITS SUBSIDIARIES OR AFFILIATED COMPANIES BE LIABLE FOR DAMAGES OF ANY KIND OR NATURE, REGARDLESS OF WHETHER SUCH DAMAGES ARE DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL OR OTHERWISE, THAT MAY ARISE FROM ACCESS OR USE OF THE COMPANY’S SECURE PORTAL, OR FROM ANY SERVICE OR DATA MADE AVAILABLE THROUGH SUCH ACCESS, REGARDLESS OF WHETHER THE COMPANY OR ANY OF ITS SUBSIDIARIES OR AFFILIATED COMPANIES HAVE BEEN ADVISED OF THE POSSIBILITY OF ACTUAL OR POTENTIAL DAMAGE.',
            ],
        },
        {
            header: 'ACTUAL OR POTENTIAL DAMAGE ',
            paragraphs: [
                'By checking the checkbox on the page you are agreeing to the terms of this Portal Access and Privacy Agreement and the Terms & Conditions for this secure portal. Unauthorized use or access to this Lincoln Financial Group secure portal is strictly prohibited.',
                'Group insurance products and services described herein are issued by The Lincoln National Life Insurance Company. Lincoln Financial Group is the marketing name for Lincoln National Corporation and its affiliates. Affiliates are separately responsible for their own financial and contractual obligations.',
            ],
        },
    ];
    const paragraphBuilder = (paragraph, index) => {
        return (<p key={`para-${index}`} style={{ marginBottom: '50px' }}>
        {paragraph}
      </p>);
    };
    const termsAndConditionToRender = () => {
        return (<>
        <Link aria-label={'change language'} data-testid={'termsAndConditionToRender'} onClick={() => changeLanguage(language === 'en' ? 'es' : 'en')} onKeyDown={(event) => {
                if (a11yClick(event)) {
                    changeLanguage(language === 'en' ? 'es' : 'en');
                }
            }} style={{ display: 'flex', justifyContent: 'flex-start' }} tabIndex={0}>
          {language === 'en' ? 'En Espanol' : 'English'}
        </Link>
        <hr />
        <div style={{ height: '450px', overflowY: 'scroll', width: 'auto' }}>
          {(language === 'en' ? TermsAndConditonsEn : TermsAndConditonsEs).map((item, index) => {
                return (<div key={index} tabIndex={0} aria-label={`paragraph for ${item.header}`}>
                  <div style={{ marginRight: '30px', textAlign: 'justify' }}>
                    <div key={`header-${index}`} className={'mt-2'}>
                      <strong>{item.header}</strong>
                    </div>
                    {item.paragraphs.map((paragraph, paraIndex) => paragraphBuilder(paragraph, paraIndex))}
                  </div>
                </div>);
            })}
        </div>
      </>);
    };
    return termsAndConditionToRender();
};
const updateTermsAndConditions = async (user, authHeader, formApiRef, navigate, setLoading, loading, trackEvent) => {
    // make api call and change state to reflect success
    if (!loading) {
        setLoading(true);
        try {
            await updateUserTermsAndConditions({
                userId: user.loginId,
                firstName: formApiRef?.current?.getValue('firstName'),
                lastName: formApiRef?.current?.getValue('lastName'),
                termsAndConditionsAccepted: true,
                elecConsentAcceptedDate: !user?.elecConsentAcceptedDate ? format(new Date(), 'yyyy-MM-dd') : user?.elecConsentAcceptedDate.toString(),
            }, authHeader);
            // TODO: Need to update with a catch block that redirects user to new SSO logout handler page when that is built and provide appropriate error messaging.
        }
        finally {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.BUTTON,
                event_name: 'accept',
                event_type: TEALIUM_EVENT.EVENT_TYPE.ECONSENT,
            });
            setLoading(false);
            window.history.replaceState({ action: '' }, '', '');
            if (user.customerInContext) {
                const experience = getExperience(user.customerInContext.applicationPermissions);
                const url = user.ssoUser
                    ? await getSSODeepLinkURL(user.ssoCargo.landingPage, experience)
                    : '';
                if (url !== '') {
                    navigate(url, { replace: true });
                }
                else {
                    if (experience === 'employer') {
                        navigate('/employer/home');
                    }
                    else if (experience === 'employee') {
                        navigate('/employee/home');
                    }
                }
            }
            else {
                navigate('/customer-search');
            }
            navigate(0); //Force reloading the app to fetch new tokens including updated firstname,lastname
        }
    }
};
const renderModalFooter = (props) => {
    const { user, authHeader, language, formApiRef, navigate, setLoading, loading, trackEvent } = props;
    return (<Button data-testid={'termsAndConditionsAcceptButton'} buttonType={'primary'} aria-label={'Accept Terms and Conditions'} onClick={() => updateTermsAndConditions(user, authHeader(), formApiRef, navigate, setLoading, loading, trackEvent)} onKeyDown={(event) => {
            if (a11yClick(event)) {
                updateTermsAndConditions(user, authHeader(), formApiRef, navigate, setLoading, loading, trackEvent);
            }
        }} tabIndex={0}>
      {language === 'en' ? 'Accept' : 'Acepto'}
    </Button>);
};
const logoutMethod = (user, logout, navigate) => {
    if (user.ssoUser) {
        navigate('/public/ssologout');
    }
    else {
        logout();
    }
};
const renderModalFooterCancelButton = ({ ...args }) => {
    const { language, user, navigate, logout } = args;
    return (<Button data-testid={'termsAndConditionsCancelButton'} buttonType={'text'} onClick={() => logoutMethod(user, logout, navigate)} onKeyDown={(event) => {
            if (a11yClick(event)) {
                logoutMethod(user, logout, navigate);
            }
        }} aria-label={'Decline Terms and Conditions'} tabIndex={1}>
      {language === 'en' ? 'Cancel' : 'Cancelar'}
    </Button>);
};
export { getContentForModal, renderModalFooter, renderModalFooterCancelButton };
