const leaveStatusReason = leave => {
  const code = leave.requestTimeReasonCode && leave.requestTimeReasonCode.trim();
  const status = leave.status && leave.status.trim();

  const mappings = [
    { code: 'DE', leaveStatusReason: 'Leave denied' },
    { code: 'BB', leaveStatusReason: 'Prior to leave begin date' },
    { code: 'AE', leaveStatusReason: 'After leave end date' },
    { code: 'OC', leaveStatusReason: 'Overlapping continuous' },
    { code: 'PE', leaveStatusReason: 'Pending frequency evaluation' },
    { code: 'AT', leaveStatusReason: 'Pending eligibility recheck' },
    { code: 'NE', leaveStatusReason: 'Not entitled' },
    { code: 'ET', leaveStatusReason: 'Employment terminated' },
    { code: 'ED', leaveStatusReason: 'Death' },
  ];

  const foundStatusReason = mappings.find(element => element.code === code);
  if (foundStatusReason) return foundStatusReason.leaveStatusReason;
  else if (status == 'NE') {
    return 'Not entitled';
  }

  return '';
};

export default leaveStatusReason;
