import titleCase from "./titleCase";

const formatClassDescription = desc => {
    if(!desc.includes('-')) {
        return "";
    } else {
        const code = desc.split('-')[0];
        let description = desc.split('-')[1];
        description = description
            .split(' ')
            .map(word => titleCase(word))
            .join(' ');
        return `${code} - ${description}`;
    }
};

export default formatClassDescription;