import axios from 'axios';
import { getEnvVariable } from 'src/utils/envUtils';
export const GetSubscriber = async (authHeader) => {
    let dentalGraphqlUrl = getEnvVariable('dentalGraphqlUrl');
    return await axios.post(dentalGraphqlUrl, {
        query: `
            query subscriber {
                subscriber {
                    firstName
                    lastName
                    id
                    dependents {
                        firstName
                        lastName
                        id
                }
            }
        }`,
    }, { headers: { authorization: authHeader } });
};
