import { userPatch } from 'src/api/UpdateProfileApi';

//FIXME: this is all backend logic and shouldn't be on the front end
const getClaimStatusDefinition = (
  language,
  statusDefinitionList,
  product,
  statusCode,
  closureReasonCode,
  pendingReasonCode,
  isSmallMidCustomer,
) => {
  let statusDefinition = '';
  let statusReasonCode = '';

  //set status code to closure or pending reason code
  try {
    const closureReasons = ['CL', 'DE', 'Closed'];
    const pendingReasons = ['IP', 'PE', 'In Progress', 'Pended'];

    if (closureReasons.includes(statusCode?.trim())) {
      statusReasonCode = closureReasonCode?.trim();
    } else if (pendingReasons.includes(statusCode?.trim())) {
      statusReasonCode = pendingReasonCode?.trim();
    } else {
      statusReasonCode = '';
    }

    //uses product, statusCodes, to find the appropriate status definition
    let statusDefinitionFilter = [];
    statusDefinitionFilter = statusDefinitionList?.filter(function (item) {
      let products = [];
      let statusCodes = [];

      if (isSmallMidCustomer) {
        if (item.normalizedProductCode) products = item.normalizedProductCode.split(';');
        else {
          products = [];
        }
        if (item.normalizedStatusCode) {
          statusCodes = item.normalizedStatusCode.split(';');
        } else {
          statusCodes = [];
        }

        return (
          products.indexOf(product?.trim()) !== -1 &&
          statusCodes.indexOf(statusCode?.trim()) !== -1 &&
          item.normalizedStatusReason === statusReasonCode &&
          item.enabled === true
        );
      } else if (item.statusReasonCode?.length || item.product?.length || item.statusCode?.length) {
        if (item.product) products = item.product.split(';');
        if (item.statusCode) {
          statusCodes = item.statusCode.split(';');
        } else statusCodes = [];

        return (
          products.indexOf(product?.trim()) !== -1 &&
          statusCodes.indexOf(statusCode?.trim()) !== -1 &&
          item.statusReasonCode === statusReasonCode &&
          item.enabled === true
        );
      }
    });

    if (statusDefinitionFilter?.length) {
      if (language === 'es') {
        statusDefinition = statusDefinitionFilter[0].reason_ES;
      } else {
        statusDefinition = statusDefinitionFilter[0].reason_EN;
      }
    } else {
      statusDefinitionFilter = statusDefinitionList?.filter(function (item) {
        if (isSmallMidCustomer) {
          let products = item.normalizedProductCode !== null ? item.normalizedProductCode.split(';') : [];
          let statusCodes = item.normalizedStatusCode !== null ? item.normalizedStatusCode.split(';') : [];

          return (
            products.indexOf(product?.trim()) !== -1 &&
            statusCodes.indexOf(statusCode?.trim()) !== -1 &&
            item.normalizedStatusReason?.trim() === 'N/A' &&
            item.enabled === true
          );
        } else {
          let products = item.product !== null ? item.product.split(';') : [];
          let statusCodes = item.statusCode !== null ? item.statusCode.split(';') : [];

          return (
            products.indexOf(product?.trim()) !== -1 &&
            statusCodes.indexOf(statusCode?.trim()) !== -1 &&
            item.statusReasonCode?.trim() === 'N/A' &&
            item.enabled === true
          );
        }
      });

      if (statusDefinitionFilter?.length) {
        if (language === 'es') {
          statusDefinition = statusDefinitionFilter[0].reason_ES;
        } else {
          statusDefinition = statusDefinitionFilter[0].reason_EN;
        }
      }
    }
  } catch (ex) {
    console.log('Exception occured in getClaimStatusDefinition : ' + ex.toString());
  }
  return statusDefinition;
};

export default getClaimStatusDefinition;
