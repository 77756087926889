import { Button, Col, Container, Label, Loader, Radio, RadioGroup, Row, SelectInput, TextInput, themes, } from '@digitools/honest-react';
import React, { useEffect, useState } from 'react';
import { UA_DURATION_HOURS, UA_HOURS, UA_MINUTES, UA_TIME_OFF_REASONS, UA_TIME_OFF_TYPES, } from '../constants/UAConstants';
//@ts-ignore - Relevant isn't in 'informed' typescript
import { Relevant, useFormApi, useFormState } from 'informed';
import moment from 'moment';
import { formatDate, formatTime, GetAssociatedLeaves } from '../api/UnplannedAbsenceApi';
import useTealium from '../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../constants/tealiumConstants';
import styled from 'styled-components';
import { addDays, differenceInHours, format, isAfter, isBefore, isValid, parse, subDays } from 'date-fns';
const UnplannedAbsenceForm = ({ hasAbsences = false, initialAbsence, onCancel }) => {
    const formState = useFormState();
    const formApi = useFormApi();
    // const lowerDateBoundary = moment().endOf('day').subtract(5, 'days');
    const lowerDateBoundary = subDays(new Date(), 5).setHours(0, 0, 0, 0);
    // const upperDateBoundary = moment().endOf('day').add(5, 'days');
    const upperDateBoundary = addDays(new Date(), 5);
    const [dateTimeErrorMsg, setDateTimeErrorMsg] = useState();
    const [startTimeLabel, setStartTimeLabel] = useState('Start time');
    const [endTimeLabel, setEndTimeLabel] = useState('End time');
    const [startTimeQuestion, setStartTimeQuestion] = useState('When would your shift have started?');
    const [endTimeQuestion, setEndTimeQuestion] = useState('When would your shift have ended?');
    const { trackEvent } = useTealium();
    const { loading: leavesLoading, data: leavesData, error: leavesError, get: getLeaves } = GetAssociatedLeaves();
    const [leaves, setLeaves] = useState([]);
    const validateNotEmpty = (value) => {
        return !value ? 'This field is required' : undefined;
    };
    const validateTimeoffDate = (timeOffdate) => {
        const enteredDate = parse(timeOffdate, 'MM/dd/yyyy', new Date());
        if (enteredDate && isValid(enteredDate)) {
            if (isBefore(enteredDate, lowerDateBoundary) || isAfter(enteredDate, upperDateBoundary)) {
                const lowBound = format(lowerDateBoundary, 'MM/dd/yyyy');
                const upperBound = format(upperDateBoundary, 'MM/dd/yyyy');
                return `Date is not between ${lowBound} and ${upperBound}`;
            }
        }
        else {
            return 'Please enter a valid date (mm/dd/yyyy)';
        }
    };
    useEffect(() => {
        if (formState.values.spansMidnight && formState.values.absType === 'OUT') {
            setStartTimeLabel('Start time');
            setEndTimeLabel('End time');
            setStartTimeQuestion('When would your shift have started?');
            setEndTimeQuestion('When would your shift have ended?');
        }
        else if (formState.values.absType === 'LATE') {
            setStartTimeLabel('Normal start');
            setEndTimeLabel('Estimated arrival');
            setStartTimeQuestion('When would you have started work?');
            setEndTimeQuestion('When did you actually arrive?');
        }
        else if (['EARLYNORTN', 'EARLYRTN'].includes(formState.values.absType)) {
            setStartTimeLabel('Depart time');
            setStartTimeQuestion('When are you leaving work early?');
            if (formState.values.absType === 'EARLYNORTN') {
                setEndTimeLabel('Regular end');
                setEndTimeQuestion('When would you normally leave work?');
            }
            else if (formState.values.absType === 'EARLYRTN') {
                setEndTimeLabel('Estimated return');
                setEndTimeQuestion('When will you return to work?');
            }
        }
        if (formState.values.absType !== 'OUT') {
            formApi.setValue('startTimeHours', undefined);
            formApi.setValue('startTimeMinutes', undefined);
            formApi.setValue('endTimeHours', undefined);
            formApi.setValue('endTimeMinutes', undefined);
        }
    }, [formState.values.spansMidnight, formState.values.absType]);
    const validateEndMinutes = () => {
        const startTimeHours = formApi.getValue('startTimeHours');
        const startTimeMinutes = formApi.getValue('startTimeMinutes');
        const endTimeHours = formApi.getValue('endTimeHours');
        const endTimeMinutes = formApi.getValue('endTimeMinutes');
        const spansMidnight = formApi.getValue('spansMidnight');
        const absType = formApi.getValue('absType');
        if (startTimeHours && startTimeMinutes && endTimeHours && endTimeMinutes) {
            let startTimeStamp = parse(`${startTimeHours}:${startTimeMinutes}`, 'HH:mm', new Date());
            let endTimeStamp = parse(`${endTimeHours}:${endTimeMinutes}`, 'HH:mm', new Date());
            if (spansMidnight && absType === 'OUT') {
                endTimeStamp = addDays(endTimeStamp, 1);
                if (differenceInHours(endTimeStamp, startTimeStamp) >= 24) {
                    // console.log("BWEEEEEEEP ---- error message should show"); //Note - this is working - but error message isn't showing on test
                    setDateTimeErrorMsg('Absence should be fewer than 24 hours');
                    return 'Absence should be fewer than 24 hours';
                }
            }
            else {
                if (isBefore(endTimeStamp, startTimeStamp)) {
                    let errorMessage;
                    if (['EARLYRTN', 'EARLYNORTN'].includes(absType)) {
                        // console.log("BWEEEEEEEP ---- error message should show") //Note - this is working - but error message isn't showing on test
                        errorMessage = `${startTimeLabel} must be before ${endTimeLabel.toLowerCase()}`;
                    }
                    else if (absType === 'LATE') {
                        errorMessage = `${endTimeLabel} must be after ${startTimeLabel.toLowerCase()}`;
                    }
                    setDateTimeErrorMsg(errorMessage);
                    return errorMessage;
                }
            }
            return undefined;
        }
        else {
            if (!endTimeMinutes) {
                setDateTimeErrorMsg(`Please select ${endTimeLabel.toLowerCase()} minutes`);
                return 'Please select end time minutes';
            }
        }
    };
    const validateLeaveNumberTextInput = (value) => {
        // Queue has leavenumber as an int
        const leaveNumberRegex = /^[1-9]\d*$/;
        if ((value && value.trim() !== '' && !leaveNumberRegex.test(value)) || Number.parseInt(value) > 2147483647) {
            return 'Please enter a valid leave number.';
        }
    };
    useEffect(() => {
        setLeaves(leavesData?.map((lve) => {
            return { label: lve.lveNum?.toString(), value: lve.lveNum };
        }) || []);
    }, [leavesData]);
    useEffect(() => {
        if (!!formState.values.hasLeave) {
            getLeaves();
        }
    }, [formState.values.hasLeave]);
    useEffect(() => {
        if (initialAbsence) {
            // If we're editing an absence, pre-fill the inputs
            formApi.setValue('date', formatDate(initialAbsence.uaStartDate));
            formApi.setValue('spansMidnight', initialAbsence.uaSpansMidnight);
            formApi.setValue('absReason', initialAbsence.absReason);
            formApi.setValue('absType', initialAbsence.absType);
        }
        else {
            formApi.reset();
            formApi.setValue('spansMidnight', false);
        }
    }, [initialAbsence]);
    useEffect(() => {
        if (initialAbsence) {
            //Prefill inputs that are dependent on spansMidnight and absType
            if (initialAbsence.uaDuration) {
                formApi.setValue('durationHours', initialAbsence?.uaDuration.substring(0, initialAbsence.uaDuration.indexOf(':')));
                formApi.setValue('durationMinutes', initialAbsence?.uaDuration.substring(initialAbsence?.uaDuration.indexOf(':') + 1, initialAbsence?.uaDuration.length));
            }
            if (formatTime(initialAbsence.uaStartTime) !== 'N/A') {
                formApi.setValue('startTimeHours', moment.utc(initialAbsence.uaStartTime).format('H').padStart(2, '0'));
                formApi.setValue('startTimeMinutes', moment.utc(initialAbsence.uaStartTime).format('mm').padStart(2, '0'));
            }
            if (formatTime(initialAbsence.uaEndTime) !== 'N/A') {
                formApi.setValue('endTimeHours', moment.utc(initialAbsence.uaEndTime).format('H').padStart(2, '0'));
                formApi.setValue('endTimeMinutes', moment.utc(initialAbsence.uaEndTime).format('mm').padStart(2, '0'));
            }
            formApi.validate();
        }
    }, [initialAbsence, formApi.getValue('spansMidnight'), formApi.getValue('absType')]);
    useEffect(() => {
        if (initialAbsence) {
            formApi.setValue('immediateFamily', initialAbsence.uaHasDeath);
            if (['SELF', 'FAMILY'].includes(initialAbsence.absReason)) {
                formApi.setValue('hasLeave', initialAbsence.uaHasLeave);
                if (leavesData) {
                    formApi.setValue('leaveId', initialAbsence.associatedLeave);
                }
                formApi.setValue('leaveIdTextInput', initialAbsence.associatedLeave);
            }
        }
    }, [initialAbsence, formState.values.absReason, leavesData]);
    const fieldFocus = (field) => {
        if (!formApi.getTouched(field.target.id)) {
            trackEvent({
                event_action: field.target.type === 'radio' ? TEALIUM_EVENT.EVENT_ACTION.BUTTON : TEALIUM_EVENT.EVENT_ACTION.FOCUS,
                event_type: TEALIUM_EVENT.EVENT_TYPE.UNPLANNED_ABSENCE,
                event_name: field.target.id,
            });
        }
    };
    const Disclaimer = styled.div `
    color: ${themes.lincoln.palette.gray};
    font-style: italic;
  `;
    return (<Container data-testid='testiddd' theme={themes.lincoln}>
      <Row>
        <Col md={6} xs={12}>
          <RadioGroup label='Does your work schedule span midnight?' field='spansMidnight' initialValue={false} notify={['endTimeMinutes']}>
            <Radio theme={themes.lincoln} containerStyle={{ marginBottom: '.5rem' }} name='spansMidnight' id='spansMidnightYes' onClick={fieldFocus} data-testid='spansMidnightYes' label='Yes' value={true}/>
            <Radio theme={themes.lincoln} containerStyle={{ marginBottom: '.5rem' }} name='spansMidnight' onClick={fieldFocus} data-testid='spansMidnightNo' id='spansMidnightNo' label='No' value={false}/>
          </RadioGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <Label theme={themes.lincoln} style={{ marginBottom: '1rem' }} data-testid={'type-of-time-label'}>
            Please select your type of time off:
          </Label>
          <SelectInput label='Type of time off' aria-label='Please select your type of time off' data-testid='type-of-time' field='absType' options={UA_TIME_OFF_TYPES} validateOnBlur={true} validate={validateNotEmpty} onFocus={fieldFocus} errorMessage='Please select a valid type of time off' theme={themes.lincoln} placeholderText='Type of time off'/>
        </Col>
      </Row>
      <Row>
        <Col>
          <Label theme={themes.lincoln} style={{ marginBottom: '1rem' }} data-testid={'absence-date-label'}>
            What is the date of your absence?
          </Label>
        </Col>
      </Row>
      <Row className='mt-0 mb-2'>
        <Col md={9} xs={12}>
          <Disclaimer>
            {`Absences can be submitted for dates between [${format(lowerDateBoundary, 'MM/dd/yyyy')}] 
              and [${format(upperDateBoundary, 'MM/dd/yyyy')}].`}
          </Disclaimer>
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <TextInput ariaLabelText='Enter the date of your absence' data-testid='timeOffDate' field='date' label='Absence date' placeholder={'Absence date'} theme={themes.lincoln} onFocus={fieldFocus} initialValue={format(new Date(), 'MM/dd/yyyy')} validate={validateTimeoffDate} validateOnBlur={true}/>
        </Col>
      </Row>
      {formState.values.spansMidnight === false && formState.values.absType === 'OUT' && (<>
          <Row>
            <Col>
              <Label theme={themes.lincoln} data-testid={'annual-salary-label'}>
                What is the duration of your absence?
              </Label>
            </Col>
          </Row>
          <Row>
            <Col md={3} xs={12}>
              <SelectInput label='Duration hours' aria-label='Please select duration hours' data-testid='duration-hours-input' field='durationHours' options={UA_DURATION_HOURS} validateOnBlur={true} validate={validateNotEmpty} onFocus={fieldFocus} errorMessage='Please select duration hours' theme={themes.lincoln} placeholderText='Duration hours'/>
            </Col>
            <Col md={3} xs={12}>
              <SelectInput label='Duration minutes' aria-label='Please select duration minutes' data-testid='duration-minutes-input' field='durationMinutes' options={UA_MINUTES} validateOnBlur={true} validate={validateNotEmpty} onFocus={fieldFocus} errorMessage='Please select duration minutes' theme={themes.lincoln} placeholderText='Duration minutes'/>
            </Col>
          </Row>
        </>)}
      <Relevant when={({ values }) => (values.spansMidnight && values.absType === 'OUT') ||
            ['LATE', 'EARLYNORTN', 'EARLYRTN'].includes(values.absType)}>
        <Row>
          <Col>
            <Label theme={themes.lincoln}>{startTimeQuestion}</Label>
          </Col>
        </Row>
        <Row>
          <Col md={3} xs={12}>
            <SelectInput label={`${startTimeLabel} hours`} aria-label={`Please select ${startTimeLabel} hours`} data-testid='start-hours-input' field='startTimeHours' options={UA_HOURS} validateOnBlur={true} validate={validateNotEmpty} onFocus={fieldFocus} errorMessage={`Please select ${startTimeLabel.toLowerCase()} hours`} notify={['endTimeMinutes']} theme={themes.lincoln} placeholderText={`${startTimeLabel} hours`}/>
            <SelectInput label='Duration hours' aria-label='Please select duration hours' data-testid='duration-hours-input' field='durationHours' options={UA_DURATION_HOURS} validateOnBlur={true} validate={validateNotEmpty} onFocus={fieldFocus} errorMessage='Please select duration hours' theme={themes.lincoln} placeholderText='Duration hours'/>
          </Col>
          <Col md={3} xs={12}>
            <SelectInput label={`${startTimeLabel} minutes`} aria-label={`Please select ${startTimeLabel} minutes`} data-testid='start-minutes-input' field='startTimeMinutes' options={UA_MINUTES} validateOnBlur={true} validate={validateNotEmpty} onFocus={fieldFocus} errorMessage={`Please select ${startTimeLabel.toLowerCase()} minutes`} notify={['endTimeMinutes']} theme={themes.lincoln} placeholderText={`${startTimeLabel} minutes`}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label theme={themes.lincoln}>{endTimeQuestion}</Label>
          </Col>
        </Row>
        <Row>
          <Col md={3} xs={12}>
            <SelectInput label={`${endTimeLabel} hours`} aria-label={`Please select ${endTimeLabel} hours`} data-testid='end-hours-input' field='endTimeHours' options={UA_HOURS} validateOnBlur={true} validate={validateNotEmpty} onFocus={fieldFocus} errorMessage={`Please select ${endTimeLabel.toLowerCase()} hours`} notify={['endTimeMinutes']} theme={themes.lincoln} placeholderText={`${endTimeLabel} hours`}/>
          </Col>
          <Col md={3} xs={12}>
            <SelectInput label={`${endTimeLabel} minutes`} data-testid='end-minutes-input' aria-label={`Please select ${endTimeLabel} minutes`} field='endTimeMinutes' options={UA_MINUTES} validateOnBlur={true} validateOnChange={true} onFocus={fieldFocus} validate={validateEndMinutes} errorMessage={`Please select ${endTimeLabel.toLowerCase()} minutes`} theme={themes.lincoln} placeholderText={`${endTimeLabel} minutes`}/>
          </Col>
        </Row>
      </Relevant>
      <Row>
        <Col md={6} xs={12}>
          <Label theme={themes.lincoln} style={{ marginBottom: '1rem' }} data-testid={'annual-salary-label'}>
            Please select your reason for time off:
          </Label>
          <SelectInput label='Reason for time off' aria-label='Please select your reason for time off' data-testid='reason-input' field='absReason' options={UA_TIME_OFF_REASONS} validateOnBlur={true} validate={validateNotEmpty} onFocus={fieldFocus} errorMessage='Please select a reason for time off' theme={themes.lincoln} placeholderText='Reason for time off'/>
        </Col>
      </Row>
      <Relevant when={({ values }) => values.absReason === 'BEREAV'}>
        <Row>
          <Col md={9} xs={12}>
            <RadioGroup label="Are you taking time off due to the death of an immediate family member as defined in the company's Time Off Work policy?" field='immediateFamily' initialValue={false}>
              <Disclaimer data-testid='family-members-disclaimer' className='mb-3'>
                The following family members qualify as immediate family defined by USAA's Time Off Work policy: Mother,
                father, mother-in-law, father-in-law, stepmother, stepfather, sister, brother, spouse, child, stepchild,
                grandmother, grandfather, and primary caregiver.
              </Disclaimer>
              <Radio theme={themes.lincoln} containerStyle={{ marginBottom: '.5rem' }} name='immediateFamily' onClick={fieldFocus} data-testid='immediateFamilyYes' id='immediateFamilyYes' label='Yes' value={true}/>
              <Radio theme={themes.lincoln} containerStyle={{ marginBottom: '.5rem' }} name='immediateFamily' onClick={fieldFocus} data-testid='immediateFamilyNo' id='immediateFamilyNo' label='No' value={false}/>
            </RadioGroup>
          </Col>
        </Row>
      </Relevant>
      <Relevant when={({ values }) => ['SELF', 'FAMILY'].includes(values.absReason)}>
        <Row>
          <Col md={6} xs={12}>
            <RadioGroup label='Is this absence related to an existing Family Medical Leave or State Leave?' field='hasLeave' initialValue={false}>
              <Radio theme={themes.lincoln} containerStyle={{ marginBottom: '.5rem' }} name='hasLeave' data-testid='hasLeaveYes' id='hasLeaveYes' onClick={fieldFocus} label='Yes' value={true}/>
              <Radio theme={themes.lincoln} containerStyle={{ marginBottom: '.5rem' }} name='hasLeave' onClick={fieldFocus} data-testid='hasLeaveNo' id='hasLeaveNo' label='No' value={false}/>
            </RadioGroup>
          </Col>
        </Row>
      </Relevant>
      <Relevant when={({ values }) => values.hasLeave === true}>
        <Loader isShown={leavesLoading} data-testid='leave-loader'>
          {!leavesLoading && !leavesError && leaves.length > 0 && (<Row>
              <Col md={6} xs={12}>
                <Label theme={themes.lincoln} style={{ marginBottom: '1rem' }} data-testid={'select-leave-id-label'}>
                  Please select a the related leave:
                </Label>
                <SelectInput aria-label='Select Leave ID' data-testid='leave-id-input' data-private={true} field='leaveId' label='Leave ID' onFocus={fieldFocus} options={leaves} placeholder='Leave ID' theme={themes.lincoln}/>
              </Col>
            </Row>)}
          {!leavesLoading && (leavesError || leaves?.length === 0) && (<Row>
              <Col md={6} xs={12} className={'mt-2'}>
                <Label theme={themes.lincoln} style={{ marginBottom: '1rem' }} data-testid={'text-input-leave-id-label'}>
                  Enter a related leave if applicable (optional):
                </Label>
                <TextInput ariaLabelText='Enter the leave ID' data-testid='leave-id-textinput' data-private={true} field='leaveIdTextInput' onFocus={fieldFocus} label='Leave ID' validateOnBlur={true} validate={validateLeaveNumberTextInput} placeholder={'Leave ID'} theme={themes.lincoln}/>
              </Col>
            </Row>)}
        </Loader>
      </Relevant>

      <Row>
        <Col md={3} xs={12}>
          <Button type='submit' aria-label='Submit Absence' data-testid='submit-btn' buttonType='primary' theme={themes.lincoln} 
    // onClick={() => console.log('-66666-', formState.errors)}
    className='w-100 mt-2'>
            Submit
          </Button>
        </Col>
        <Col md={3} xs={12}>
          {hasAbsences && (<Button buttonType={'secondary'} aria-label='Cancel Absence' data-testid='cancel-btn' onClick={onCancel} theme={themes.lincoln} className='w-100 mt-2'>
              Cancel
            </Button>)}
        </Col>
      </Row>
    </Container>);
};
export default UnplannedAbsenceForm;
