import useGet from '../../../hooks/useGet';
const useProductAttributeCodes = () => {
    const url = '/status-service/customer/productAttributeCode';
    const params = {
        applicationInternalName: 'STATUS_ER',
    };
    const { error, loading, data } = useGet({
        url,
        options: {
            params,
        },
    });
    return {
        productAttributeCodes: data,
        loading,
        error,
    };
};
export default useProductAttributeCodes;
