import React from 'react';
import useTranslate from '../../../../../../hooks/useTranslate';
import { ApplicantInformationText, PersonalInformationText } from '../../../../i18n/ApplicantInformationText';
import Row from '@digitools/honest-react/dist/lib/components/Row';
import Col from '@digitools/honest-react/dist/lib/components/Col';
import ReviewAndSignField from './ReviewAndSignField';
import EoiVerificationText from '../../../../i18n/EoiVerificationText';
import ReviewAndSignHeader from './ReviewAndSignHeader';
import useEoiContext from '../../../../hooks/useEoiContext';
import { isSitusStateMA } from 'src/packages/eoi/utils/HelperFunctions';
const PersonalInformation = (props) => {
    const { interviewAnswers, customContent, initialInformation } = useEoiContext();
    const { t } = useTranslate();
    const formatHeight = () => {
        return `${props.applicant.feet}' ${props.applicant.inches}"`;
    };
    const formatWeight = () => {
        return `${props.applicant.weight} lbs`;
    };
    const getMaritalStatus = () => {
        const employee = props.applicant;
        return employee.maritalStatus;
    };
    const isEmployee = () => {
        const employee = props.applicant;
        return employee.employmentInformation && employee.contactInformation && true;
    };
    return (<div style={{ marginTop: '2.5rem' }} data-testid={'review-personal-info'}>
      <ReviewAndSignHeader title={t(ApplicantInformationText.personalInformation)} step={1}/>
      <Row style={{ marginLeft: '1rem' }}>
        <Col md={4} sm={12} data-private={true}>
          <ReviewAndSignField label={PersonalInformationText.firstName} value={props.applicant.firstName} capitalize={true}/>
        </Col>
        <Col md={4} sm={12} data-private={true}>
          <ReviewAndSignField label={PersonalInformationText.middleInitial} value={props.applicant.middleInitial} capitalize={true}/>
        </Col>
        <Col md={4} sm={12} data-private={true}>
          <ReviewAndSignField label={PersonalInformationText.lastName} value={props.applicant.lastName} capitalize={true}/>
        </Col>
      </Row>
      <Row style={{ marginLeft: '1rem' }}>
        <Col md={4} sm={12} data-private={true}>
          <ReviewAndSignField label={EoiVerificationText.ssnLabel} value={(props.applicant && props.applicant.socialSecurityNumber) ? `*****${props.applicant.socialSecurityNumber.toString().slice(-4)}` : ''}/>
        </Col>
        {props.displayApplicantFields &&
            <Col md={4} sm={12}>
            <ReviewAndSignField label={PersonalInformationText.dateOfBirth} value={props.applicant.dateOfBirth}/>
          </Col>}
        {props.displayApplicantFields &&
            <Col md={4} sm={12} data-private={true}>
            <ReviewAndSignField label={isSitusStateMA(initialInformation) ? PersonalInformationText.gender : PersonalInformationText.sexAtBirth} value={props.applicant.sex}/>
          </Col>}
      </Row>
      {props.displayApplicantFields &&
            <Row style={{ marginLeft: '1rem' }}>
        <Col md={4} sm={12}>
          <ReviewAndSignField label={PersonalInformationText.birthState} value={props.applicant.birthState}/>
        </Col>
        <Col md={4} sm={12}>
          <ReviewAndSignField label={PersonalInformationText.height} value={formatHeight()}/>
        </Col>
        <Col md={4} sm={12}>
          <ReviewAndSignField label={PersonalInformationText.weight} value={formatWeight()}/>
        </Col>
      </Row>}
      {isEmployee() &&
            <Row style={{ marginLeft: '1rem' }}>
        <Col md={4} sm={12}>
          <ReviewAndSignField label={PersonalInformationText.maritalStatus} value={getMaritalStatus()} capitalize={true}/>
        </Col>
      </Row>}
      
    </div>);
};
export default PersonalInformation;
