import React from 'react';
import { Col, Radio, RadioGroup, Row, themes } from '@digitools/honest-react';
import { fieldRequired } from 'src/packages/cli/utils/validationUtils';
import useCliDataContext from '../../../hooks/useCliDataContext';
import AboutYourAbsenceText from 'src/packages/cli/constants/AboutYourAbsenceText';
import useTranslate from 'src/hooks/useTranslate';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import useExperience from 'src/hooks/useExperience';
import { Experience } from 'src/types/Experience';
import { SURGERY_YES_TYPES, SURGERY_NO_ILLNESS_TYPES, SURGERY_NO_INJURY_TYPES } from 'src/packages/cli/constants/CliSurgeryTypeConstants';
import { useFormState } from 'informed';
import styled from 'styled-components';
const StyledIllnessCol = styled(Col) `
    label {
        marginBottom: .5rem;
        color: ${({ theme }) => theme?.palette?.black};
    }
    .igqTZe {
        top: 0;
    }
    small {
        display: block;
        color: ${({ theme }) => theme.palette.grayDark};
        font-size: .8rem;
    }
`;
const SurgeryType = ({ shouldDisplayOtherSurgery }) => {
    const { absenceData } = useCliDataContext();
    const { experience } = useExperience();
    const { t } = useTranslate();
    const { formLabels } = AboutYourAbsenceText;
    const { values } = useFormState();
    const surgeryYesType = SURGERY_YES_TYPES;
    const surgeryNoInjuryType = SURGERY_NO_INJURY_TYPES;
    const surgeryNoIllnessType = SURGERY_NO_ILLNESS_TYPES;
    const surgeryTypeYesLabel = CustomEmployeeText(t(experience === Experience.EMPLOYEE ? formLabels.surgeryTypeYesEE : formLabels.surgeryTypeYesER));
    const surgeryTypeNoIllnessLabel = CustomEmployeeText(t(experience === Experience.EMPLOYEE ? formLabels.surgeryTypeNoIllnessEE : formLabels.surgeryTypeNoIllnessER));
    const surgeryTypeNoInjuryLabel = CustomEmployeeText(t(experience === Experience.EMPLOYEE ? formLabels.surgeryTypeNoInjuryEE : formLabels.surgeryTypeNoInjuryER));
    //@ts-ignore
    const claimantCondition = values?.absenceData?.claimantCondition;
    //@ts-ignore
    const surgeryIndicator = values?.absenceData?.surgeryIndicator;
    return (<>
            {surgeryIndicator === 'Y' && (<Row data-testid='surgeryTypeYes' className='mb-3' data-private={true}>
                    <Col xs={12}>
                        <RadioGroup data-testid={'surgerySubTier'} label={surgeryTypeYesLabel} field={'surgerySubTier'} legendStyles={{ marginBottom: '.5rem' }} theme={themes.lincoln} validateOnBlur={true} validateOnChange={true} required={true} validate={fieldRequired} initialValue={absenceData && absenceData.surgerySubTier}>
                            {surgeryYesType.map(surgeryYes => {
                return (<Radio containerStyle={{ marginBottom: '.5rem' }} data-testid={'lt-' + surgeryYes?.value} key={'lt-' + surgeryYes?.value} name='surgerySubTier' label={t(surgeryYes?.label)} value={surgeryYes?.value}/>);
            })}
                        </RadioGroup>
                    </Col>
                </Row>)}

            {(claimantCondition === 'I' && (surgeryIndicator === 'N' || surgeryIndicator === 'U')) && (<Row data-testid='surgeryNoInjuryType' className='mb-3' data-private={true}>
                    <Col xs={12} md={6}>
                        <RadioGroup label={surgeryTypeNoInjuryLabel} field={'injuryTier'} legendStyles={{ marginBottom: '.5rem' }} theme={themes.lincoln} validateOnBlur={true} validateOnChange={true} validate={fieldRequired} initialValue={absenceData && absenceData.injuryTier}>
                            {surgeryNoInjuryType.map(surgeryNoInjury => {
                return (<Radio containerStyle={{ marginBottom: '.5rem' }} data-testid={'lt-' + surgeryNoInjury?.value} key={'lt-' + surgeryNoInjury?.value} name='injuryTier' label={t(surgeryNoInjury?.label)} value={surgeryNoInjury?.value}/>);
            })}
                        </RadioGroup>
                    </Col>
                </Row>)}
            
            {(claimantCondition === 'S' && (surgeryIndicator === 'N' || surgeryIndicator === 'U')) && (<RadioGroup label={surgeryTypeNoIllnessLabel} field={'injuryTier'} legendStyles={{ marginBottom: '.5rem' }} theme={themes.lincoln} validateOnBlur={true} data-private={true} validateOnChange={true} validate={fieldRequired} initialValue={absenceData && absenceData.injuryTier}>
                <Row data-testid='surgeryNoIllnessType' className={`${shouldDisplayOtherSurgery ? 'mb-1' : 'mb-4'}`} data-private={true}>
                    <StyledIllnessCol md={6} theme={themes?.default}>
                        {surgeryNoIllnessType.slice(0, Math.ceil(surgeryNoIllnessType.length / 2)).map(surgeryNoIllness => {
                return (<Radio containerStyle={{ marginBottom: '.5rem' }} data-testid={'lt-' + surgeryNoIllness?.value} key={'lt-' + surgeryNoIllness?.value} name='injuryTier' label={t(surgeryNoIllness?.label)} value={surgeryNoIllness?.value}/>);
            })}
                    </StyledIllnessCol>
                    <StyledIllnessCol md={6} theme={themes?.default}>
                        {surgeryNoIllnessType.slice(Math.ceil(surgeryNoIllnessType.length / 2)).map(surgeryNoIllness => {
                return (<Radio containerStyle={{ marginBottom: '.5rem' }} data-testid={'lt-' + surgeryNoIllness?.value} key={'lt-' + surgeryNoIllness?.value} name='injuryTier' label={t(surgeryNoIllness?.label)} value={surgeryNoIllness?.value}/>);
            })}
                    </StyledIllnessCol>
                    </Row>
                </RadioGroup>)} 
        </>);
};
export default SurgeryType;
