import { compareAsc, compareDesc, isValid, parse } from 'date-fns';
// supported formats - most common first as it will return first valid date parsed
const formats = ['MM/dd/yyyy', 'yyyy-MM-dd', 'MM-dd-yyyy'];
const parseDate = (dateString) => {
    for (const format of formats) {
        const parsedDate = parse(dateString, format, new Date());
        if (isValid(parsedDate)) {
            return parsedDate;
        }
    }
    return null;
};
/**
 * Generic object field comparator function. for use with list sort methods
 * Helps sort objects based on shared fields
 * will use basic string comparison unless it detects the fields are actually date strings
 * @param field
 * @param isAscending
 * @returns
 */
export const compareByField = (field, isAscending = true) => (a, b) => {
    const aValue = a[field];
    const bValue = b[field];
    // Check if field is a date and sort using date comparators
    const dateA = parseDate(aValue);
    const dateB = parseDate(bValue);
    // If one is valid date and the other is not, prioritize the valid date
    if (dateA && !dateB) {
        return isAscending ? -1 : 1;
    }
    if (!dateA && dateB) {
        return isAscending ? 1 : -1;
    }
    // If both are valid dates, compare them
    if (dateA && dateB) {
        return isAscending ? compareAsc(dateA, dateB) : compareDesc(dateA, dateB);
    }
    // Fallback to string comparison
    if (aValue < bValue) {
        return isAscending ? -1 : 1;
    }
    if (aValue > bValue) {
        return isAscending ? 1 : -1;
    }
    return 0;
};
