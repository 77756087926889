import useAuthentication from 'src/hooks/useAuthentication';
import React, { createContext, useEffect, useState } from 'react';
import queryString from 'query-string';
import { decode } from 'status/utilities/hash';
import { GetSubscriber } from '../api/DentalApiCalls';
export const DentalDataContext = createContext({
    subscriber: undefined,
    setSubscriber: () => { },
    getSubscriber: () => { },
    currentMember: undefined,
    setCurrentMember: () => { },
    success: true,
    errorMessage: undefined,
});
export const DentalDataProvider = ({ children, }) => {
    const { authHeader } = useAuthentication();
    const [success, setSuccess] = useState(true);
    const queryParams = queryString.parse(location.search);
    const [subscriber, setSubscriber] = useState();
    const [currentMember, setCurrentMember] = useState();
    const [errorMessage, setErrorMessage] = useState('');
    useEffect(() => {
        GetSubscriber(authHeader()).then(response => {
            const data = response?.data?.data;
            if (data.errors) {
                setSuccess(false);
                setErrorMessage(data.errors.message);
            }
            setSubscriber(data.subscriber);
        });
    }, []);
    // To happen after subscriber changes - which should only be once at the start
    useEffect(() => {
        if ((subscriber && !queryParams.memberId) || (subscriber && subscriber.id === decode(queryParams.memberId))) {
            setCurrentMember(subscriber);
        }
        else if (queryParams.memberId && subscriber?.dependents) {
            let dependent = subscriber.dependents.filter((dep) => dep.id === decode(queryParams.memberId))[0];
            if (dependent) {
                //check we got a match
                setCurrentMember(dependent);
            }
        }
    }, [subscriber, queryParams.memberId]);
    const dentalDataContext = {
        subscriber,
        setSubscriber,
        currentMember,
        setCurrentMember,
        success,
        errorMessage,
    };
    return <DentalDataContext.Provider value={dentalDataContext}>{children}</DentalDataContext.Provider>;
};
