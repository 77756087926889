import React from 'react';
import AboutYourAbsenceText from '../constants/AboutYourAbsenceText';
import { validationDisplayTextEs } from 'src/utils/language/validationDisplayTextEs';
import { validationDisplayTextEn } from 'src/utils/language/validationDisplayTextEn';
import LanguageToggle from 'src/utils/LanguageToggle';
import { addDays, addYears, isAfter, isBefore, parse } from 'date-fns';
import { AboutYouText } from '../constants/AboutYouText';
import CliVerificationText from '../i18n/CliVerificationText';
import { ElectionConstants } from '../constants/ElectionConstantsText';
import { GetCurrentDate } from 'src/packages/lci/constants/LCIConstant';
export const fieldRequired = (value) => {
    if (!value) {
        return (<LanguageToggle en={CliVerificationText.verifyRequiredError.english} es={CliVerificationText.verifyRequiredError.spanish}/>);
    }
    return undefined;
};
export const validateCliNameOptional = (name) => {
    const regex = RegExp(/^([-\\.'a-zA-Z]|^[-\\.'a-zA-Z][-\\.' a-zA-Z]{0,33}[-\\.'a-zA-Z])$/gm);
    if (name && !regex.test(name)) {
        return <LanguageToggle en={AboutYouText.nameError.english} es={AboutYouText.nameError.spanish}/>;
    }
    return undefined;
};
export const validateName = (name, t) => {
    const regex = RegExp(/^([-\\.'a-zA-Z]|^[-\\.'a-zA-Z][-\\.' a-zA-Z]{0,33}[-\\.'a-zA-Z])$/gm);
    if (name && !regex.test(name)) {
        return <LanguageToggle en={AboutYouText.nameError.english} es={AboutYouText.nameError.spanish}/>;
    }
    else if (!name) {
        return fieldRequired(name);
    }
    return undefined;
};
export const validateEmail = (email) => {
    const regex = RegExp(/^([0-9a-zA-Z]([-\.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z]{0,}[-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/i);
    if (!email) {
        return <LanguageToggle en={validationDisplayTextEn.fieldRequired} es={validationDisplayTextEs.fieldRequired}/>;
    }
    else if (!email.match(regex) || email.length >= 140) {
        return <LanguageToggle en={validationDisplayTextEn.invalidEmail} es={validationDisplayTextEs.invalidEmail}/>;
    }
    return undefined;
};
export const validateOptionalEmail = (email) => {
    const regex = RegExp(/^([0-9a-zA-Z]([-\.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z]{0,}[-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/i);
    if (email && (!email.match(regex) || email.length >= 140)) {
        return <LanguageToggle en={validationDisplayTextEn.invalidEmail} es={validationDisplayTextEs.invalidEmail}/>;
    }
    return undefined;
};
export const validateOtherRelationship = (relationship, t) => {
    const regex = RegExp(/^([ a-zA-Z]{0,34})$/gm);
    if (relationship && !regex.test(relationship)) {
        return (<LanguageToggle en={AboutYourAbsenceText.formLabels.relationshipError.english} es={AboutYourAbsenceText.formLabels.relationshipError.spanish}/>);
    }
    else if (!relationship) {
        return fieldRequired(relationship);
    }
    return undefined;
};
export const validateTextArea = ({ value, t, charLimit = 0, required = true }) => {
    const test = `^[-a-zA-Z0-9' .,:;_#/!*+=?&<>$@%(){}\\s]{0,${charLimit}}$`;
    const regex = new RegExp(test);
    if ((required && !value) || !regex.test(value)) {
        return t(AboutYourAbsenceText.formLabels.textAreaError)(charLimit);
    }
};
export const validateJobRequirementTextArea = (value, t) => {
    //field must be less than 250 characters and may contain only numbers and letters
    const regex = /^[a-zA-Z0-9 ]{0,250}$/;
    if (!regex.test(value)) {
        return t(AboutYourAbsenceText.formLabels.textAreaError)(250);
    }
};
export const validateZeroToOneHundred = (value) => {
    const regex = /^[0-9][0-9]?$|^100$/;
    if ((value && value.length > 3) || !regex.test(value)) {
        return 'Field must be between 0 and 100';
    }
};
export const rangedNumberValidation = (value, startRange, endRange) => {
    return value && (isNaN(Number(value)) || parseInt(value) < startRange || parseInt(value) > endRange)
        ? `Must be a number between ${startRange} and ${endRange}.`
        : undefined;
};
export const validateDateIsWithinAYear = (input, t, required) => {
    const date = parse(input, 'P', new Date());
    if (isBefore(date, addYears(new Date(), -1)) || isAfter(date, addYears(new Date(), 1))) {
        return t({
            english: "Date entered must be within one year of today's date",
            spanish: 'La fecha ingresada debe estar dentro de un año de la fecha de hoy',
        });
    }
    else if (!input && required) {
        return t({
            english: 'This field is required',
            spanish: 'Este campo es obligatorio',
        });
    }
};
export const validateDateIsWithinOneYearBeforeAndTwoAfterToday = (input, t, required) => {
    const date = parse(input, 'P', new Date());
    if (isBefore(date, addYears(addDays(new Date(), -1), -1))) {
        return t({
            english: "Date entered must be on or after one year before today's date",
            spanish: 'La fecha ingresada debe ser igual o posterior a un año antes de la fecha de hoy',
        });
    }
    else if (isAfter(date, addYears(new Date(), 2))) {
        return t({
            english: 'Date entered must be within two years of todays date',
            spanish: 'La fecha ingresada debe estar dentro de los dos años de la fecha de hoy',
        });
    }
    else if (!input && required) {
        return t({
            english: 'This field is required',
            spanish: 'Este campo es obligatorio',
        });
    }
};
export const validateDateIsWithinAYearFromToday = (input, t, required) => {
    const date = parse(input, 'P', new Date());
    if (isAfter(new Date(date), addYears(new Date(), 1))) {
        return t({
            english: "Date entered cannot occur more than one year after today's date",
            spanish: 'La fecha ingresada no puede ocurrir más de un año después de la fecha de hoy',
        });
    }
    else if (!input && required) {
        return t({
            english: 'This field is required',
            spanish: 'Este campo es obligatorio',
        });
    }
};
export const validateDateIsOnOrBeforeToday = (input, t, required) => {
    const today = new Date();
    const minDate = new Date('01/01/1900');
    const inputDate = new Date(input);
    if (input && inputDate > today) {
        return t({
            english: "Date entered must be on or before today's date",
            spanish: 'La fecha ingresada debe ser igual o anterior a la fecha de hoy',
        });
    }
    else if (!input && required) {
        return t({
            english: 'This field is required',
            spanish: 'Este campo es obligatorio',
        });
    }
    else if (input && inputDate < minDate) {
        return t({
            english: `Date is not between 01/01/1900 and ${GetCurrentDate()}`,
            spanish: `La fecha no está entre 01/01/1900 y ${GetCurrentDate()}`,
        });
    }
};
export const validatePostalCode = (postalCode, claimantData) => {
    const invalidPostalError = (<LanguageToggle en={validationDisplayTextEn.invalidPostal} es={validationDisplayTextEs.invalidPostal}/>);
    if (!postalCode)
        return invalidPostalError;
    //@ts-ignore
    const selectedCountryID = claimantData.claimantData.selectedCountryID;
    const canadaPostalCodeRegex = /^[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$/;
    const usaPostalCodeRegex = /^\d{5}(?:[-]\d{4})?$/;
    if (selectedCountryID === 'USA') {
        if (!postalCode.match(usaPostalCodeRegex))
            return invalidPostalError;
    }
    else if (selectedCountryID === 'CAN') {
        if (!canadaPostalCodeRegex.test(postalCode))
            return invalidPostalError;
    }
    else if (postalCode?.length < 1) {
        return invalidPostalError;
    }
};
export const validateNameDD = (name) => {
    const regex = RegExp(/^[-A-Za-z'. ]*$/gm);
    if (name && !regex.test(name)) {
        return <LanguageToggle en={ElectionConstants.name.english} es={ElectionConstants.name.spanish}/>;
    }
    else if (!name) {
        return <LanguageToggle en={ElectionConstants.requiredError.english} es={ElectionConstants.requiredError.spanish}/>;
    }
    return undefined;
};
export const validateRoute = (route) => {
    if (route && route.length < 9) {
        return (<LanguageToggle en={ElectionConstants.routingErrorMessage.english} es={ElectionConstants.routingErrorMessage.spanish}/>);
    }
    if (!route) {
        return <LanguageToggle en={ElectionConstants.requiredError.english} es={ElectionConstants.requiredError.spanish}/>;
    }
};
export const accountrouteMask = (rvalue) => {
    if (rvalue) {
        const numbers = rvalue.toString().replace(/[^\d]/g, '');
        return numbers;
    }
    else {
        return '';
    }
};
export const validateConfirmAccount = (confirmAccountNumber, values) => {
    const accountNumber = values.accountNumber ? values.accountNumber : '';
    if (confirmAccountNumber && confirmAccountNumber.length < 4) {
        return (<LanguageToggle en={ElectionConstants.accountErrorMessage.english} es={ElectionConstants.accountErrorMessage.spanish}/>);
    }
    if (accountNumber !== confirmAccountNumber) {
        return (<LanguageToggle en={ElectionConstants.confirmAccountError.english} es={ElectionConstants.confirmAccountError.spanish}/>);
    }
    if (!confirmAccountNumber)
        return <LanguageToggle en={ElectionConstants.requiredError.english} es={ElectionConstants.requiredError.spanish}/>;
};
export const validateAccount = (route) => {
    if (route && route.length < 4) {
        return (<LanguageToggle en={ElectionConstants.accountErrorMessage.english} es={ElectionConstants.accountErrorMessage.spanish}/>);
    }
    if (!route) {
        return <LanguageToggle en={ElectionConstants.requiredError.english} es={ElectionConstants.requiredError.spanish}/>;
    }
};
export const validateAddress1 = (address) => {
    if (!address) {
        return <LanguageToggle en={validationDisplayTextEn.fieldRequired} es={validationDisplayTextEs.fieldRequired}/>;
    }
    else if (!address.match(/^[0-9A-Za-z .,?#'-/]{1,30}$/)) {
        return <LanguageToggle en={validationDisplayTextEn.invalidAddress} es={validationDisplayTextEs.invalidAddress}/>;
    }
    return undefined;
};
export const validateAddress2 = (address) => {
    if (address && !address.match(/^[0-9A-Za-z .,?#'-/]{1,30}$/)) {
        return <LanguageToggle en={validationDisplayTextEn.invalidAddress} es={validationDisplayTextEs.invalidAddress}/>;
    }
    return undefined;
};
