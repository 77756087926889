const disabilityCause = disabilityCauseCode => {
  if (!disabilityCauseCode) {
    return undefined;
  }
  const code = disabilityCauseCode.trim();

  const mappings = [
    { code: 'A', disabilityCause: 'Injury' },
    { code: 'S', disabilityCause: 'Sickness' },
    { code: 'H', disabilityCause: 'Hospital-Sickness' },
    { code: 'J', disabilityCause: 'Hospital-Injury' },
    { code: 'X', disabilityCause: 'WC-Sickness' },
    { code: 'W', disabilityCause: 'WC-Injury' },
    { code: 'M', disabilityCause: 'Maternity' },
  ];

  var foundCategory = mappings.find(element => element.code === code);
  if (foundCategory) return foundCategory.disabilityCause;

  return disabilityCauseCode.trim();
};

export default disabilityCause;
