import React from 'react';
import { Checkbox, Button } from '@digitools/honest-react';
import Spinner from 'status/components/Spinner/Spinner';
import { usePost } from 'status/hooks';
import useGet from '../../../../hooks/useGet';
import { themes } from '@digitools/honest-react';
import useTealium from '../../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../../constants/tealiumConstants';

const WatchlistCheckBox = ({ eventId, eventType, isNewCard, ...rest }) => {
  const { trackEvent } = useTealium();

  const eventValues = {
    eventId,
    eventType,
  };

  const {
    loading: loadingIsWatchlistItem,
    data: isWatchlistItemData,
    get: getIsWatched,
    error: isWatchlistError,
  } = useGet({
    url: '/status-service/watch-list/is-watched',
    options: {
      params: eventValues,
    },
  });

  const success = () => {
    getIsWatched();
  };

  const {
    post: watchlistPost,
    loading: loadingEditWatchlist,
    error: watchlistPostError,
  } = usePost({
    payload: eventValues,
    success,
  });

  const handleClick = async () => {
    trackEvent({
      event_action: TEALIUM_EVENT.EVENT_ACTION.CHECKBOX,
      event_type: TEALIUM_EVENT.EVENT_TYPE.WATCHLIST_CHECKBOX,
      event_name: 'Watchlist CheckBox',
    });
    let action = '';

    if (isWatchlistItemData) {
      action = '/remove';
    }

    await watchlistPost({ url: `/status-service/watch-list${action}` });
  };

  if (loadingEditWatchlist || loadingIsWatchlistItem) {
    return <Spinner id='watchlist-checkbox-spinner' size='small' style={{ margin: '-.1rem' }} />;
  }

  return (
    (isNewCard ? (
      <Button
        buttonType={'secondary'}
        size={'medium'}
        type={'button'}
        tabIndex={-1}
        style={{display: 'grid'}}
      >
        <div style={{justifySelf: 'center', alignSelf: 'center'}}>
          <Checkbox
            field={'watchlist'}
            label={'Watchlist'}
            initialValue={isWatchlistItemData}
            aria-label={isWatchlistItemData ? 'Add to watchlist' : 'Remove from watchlist'}
            onClick={handleClick}
            disabled={isWatchlistError || watchlistPostError}
            theme={themes.lincoln}
            {...rest}
          />
        </div>
      </Button>
    ) : ( 
      <Checkbox
        field={'watchlist'}
        label={'Watchlist'}
        initialValue={isWatchlistItemData}
        onClick={handleClick}
        disabled={isWatchlistError || watchlistPostError}
        theme={themes.lincoln}
        {...rest}
      />
    ))
  );
};

export default WatchlistCheckBox;
