export const docMeta = {
    s1: {
        claimsEE: {
            english: [
                {
                    label: 'Authorization forms',
                    type: 'EMP',
                    title: 'AUTH',
                },
                {
                    label: 'Claim and leave forms',
                    type: 'EMP',
                    title: 'FORM',
                    isRtwEligible: true,
                },
                {
                    label: 'Medical records',
                    type: 'MED',
                    title: 'MEDREC',
                    isRtwEligible: true,
                },
                {
                    label: 'Other non-medical documentation',
                    type: 'EMP',
                    title: 'CORR',
                    isRtwEligible: true,
                },
                {
                    label: 'Appeal letter',
                    type: 'APPL',
                    title: 'NTCEAPPL',
                },
            ],
            spanish: [
                {
                    label: 'Formularios de autorización',
                    type: 'EMP',
                    title: 'AUTH',
                },
                {
                    label: 'Formularios de reclamo y salida',
                    type: 'EMP',
                    title: 'FORM',
                    isRtwEligible: true,
                },
                {
                    label: 'Registros médicos',
                    type: 'MED',
                    title: 'MEDREC',
                    isRtwEligible: true,
                },
                {
                    label: 'Otra documentación no médica',
                    type: 'EMP',
                    title: 'CORR',
                    isRtwEligible: true,
                },
                {
                    label: 'Carta de apelación',
                    type: 'APPL',
                    title: 'NTCEAPPL',
                },
            ],
        },
        claimsER: [
            {
                label: 'Authorization forms',
                type: 'EPR',
                title: 'AUTH',
            },
            {
                label: 'Claim and leave forms',
                type: 'EPR',
                title: 'FORM',
                isRtwEligible: true,
            },
            {
                label: 'Medical records',
                type: 'MED',
                title: 'MEDREC',
                isRtwEligible: true,
            },
            {
                label: 'Other non-medical documentation',
                type: 'EPR',
                title: 'CORR',
                isRtwEligible: true,
            },
            {
                label: 'Appeal documents',
                type: 'APPL',
                title: 'NTCEAPPL',
            },
        ],
        leaves: {
            english: [
                {
                    label: 'Medical Certification',
                    type: 'LVE',
                    title: 'MEDCERT',
                },
                {
                    label: 'Proof of birth',
                    type: 'LVE',
                    title: 'BRTHCERT',
                },
                {
                    label: 'Adoption/Foster Care Certification',
                    type: 'LVE',
                    title: 'ADPTCERT',
                },
                {
                    label: 'Other correspondence',
                    type: 'LVE',
                    title: 'MISCCORR',
                    isRtwEligible: true,
                },
            ],
            spanish: [
                {
                    label: 'Formulario de certificación médica',
                    type: 'LVE',
                    title: 'MEDCERT',
                },
                {
                    label: 'Prueba de nacimiento',
                    type: 'LVE',
                    title: 'BRTHCERT',
                },
                {
                    label: 'Certificación de adopción/cuidado de crianza',
                    type: 'LVE',
                    title: 'ADPTCERT',
                },
                {
                    label: 'Otra correspondencia',
                    type: 'LVE',
                    title: 'MISCCORR',
                    isRtwEligible: true,
                },
            ],
        },
    },
    docsNet: {
        claimsEE: {
            english: [
                {
                    label: 'Claim and leave forms',
                    type: 'EMP',
                    title: 'FORM',
                },
                {
                    label: 'Authorization forms',
                    type: 'EMP',
                    title: 'AUTH',
                },
                {
                    label: 'Medical records',
                    type: 'MED',
                    title: 'MEDREC',
                    isRtwEligible: true,
                },
                {
                    label: 'Other non-medical documentation',
                    type: 'EMP',
                    title: 'CORR',
                    isRtwEligible: true,
                },
                {
                    label: 'Social Security documents',
                    type: 'SOC',
                    title: 'CORR',
                },
            ],
            spanish: [
                {
                    label: 'Formularios de reclamo y salida',
                    type: 'EMP',
                    title: 'FORM',
                },
                {
                    label: 'Formularios de autorización',
                    type: 'EMP',
                    title: 'AUTH',
                },
                {
                    label: 'Registros médicos',
                    type: 'MED',
                    title: 'MEDREC',
                    isRtwEligible: true,
                },
                {
                    label: 'Otra documentación no médica',
                    type: 'EMP',
                    title: 'CORR',
                    isRtwEligible: true,
                },
                {
                    label: 'Documentos de seguridad social',
                    type: 'SOC',
                    title: 'CORR',
                },
            ],
        },
        claimsER: [
            {
                label: 'Claim and leave forms',
                type: 'EPR',
                title: 'FORM',
            },
            {
                label: 'Authorization forms',
                type: 'EPR',
                title: 'AUTH',
            },
            {
                label: 'Medical records',
                type: 'MED',
                title: 'MEDREC',
                isRtwEligible: true,
            },
            {
                label: 'Other non-medical documentation',
                type: 'EPR',
                title: 'CORR',
                isRtwEligible: true,
            },
            {
                label: 'Social Security documents',
                type: 'SOC',
                title: 'CORR',
            },
        ],
    },
};
Object.assign(docMeta.s1, { intregatedLeaves: { ...docMeta.s1.claimsEE, ...docMeta.s1.leaves } });
