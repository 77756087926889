import React, { useContext, useEffect, useState } from 'react';
import { Form, Scope } from 'informed';
import useTranslate from '../../../../hooks/useTranslate';
import { EoiGeneralText } from '../../i18n/EoiText';
import StepperButtons from '../../components/StepperButtons';
import Heading from '@digitools/honest-react/dist/lib/components/Heading';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import { StepperContext } from '@digitools/honest-react/dist/lib/components/Stepper/StepperContext';
// import Loader from '@digitools/honest-react/dist/lib/components/Loader';
import { Loader } from '@digitools/honest-react';
import QuestionSection from './QuestionSection';
import { requestReflexiveQuestions } from '../../api/EoiApiCalls';
import useAuthentication from '../../../../hooks/useAuthentication';
import useEoiContext from '../../hooks/useEoiContext';
import { hasCIEmployeeCoverageSelected, hasCISpouseCoverageSelected, hasOnlyCICoverageSelected, isApplicantCICoverageOnly, isSitusStateNY } from '../../utils/HelperFunctions';
import { QuestionsText } from '../../i18n/QuestionsText';
import SaveForLaterErrorAlert from '../../components/SaveForLaterErrorAlert';
import ValidationErrorAlert from '../../components/ValidationErrorAlert';
import useGoogleAnalytics from '../../../../hooks/useGoogleAnalytics';
import useTealium from '../../../../hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from '../../../../constants/tealiumConstants';
import LegalFormNumbers from '../../components/LegalFormNumbers';
/**
 * Takes a values object from the form and builds an Answer payload for InterviewAnswers
 * Takes a shouldCallForReflexiveQuestions function that sets the value in submit to
 * appropriately skip or call the API for reflexivequestions, and pivot to the right page
 * @param values
 * @param shouldCallForReflexiveQuestions
 */
const buildAnswerPayload = (values, shouldCallForReflexiveQuestions) => {
    const answers = [];
    Object.keys(values).forEach(key => {
        // @ts-ignore
        if (values[key].employee !== undefined) {
            const answer = {
                questionCode: key,
                role: 'employee',
                // @ts-ignore
                value: values[key].employee,
            };
            shouldCallForReflexiveQuestions ? shouldCallForReflexiveQuestions(values[key].employee, key) : undefined; // do nothing ;
            answers.push(answer);
        }
        // @ts-ignore
        if (values[key].spouse !== undefined) {
            const answer = {
                questionCode: key,
                role: 'spouse',
                // @ts-ignore
                value: values[key].spouse,
            };
            shouldCallForReflexiveQuestions ? shouldCallForReflexiveQuestions(values[key].spouse, key) : undefined; // do nothing ;
            answers.push(answer);
        }
        // @ts-ignore
        if (values[key].dependent !== undefined) {
            let dependentIds;
            // @ts-ignore - Due to Checkbox not supporting Scopes, need to clean out QuestionCode from dependentId Checkbox Field
            if (values[key].dependent === true && values[key][key + '-dependents']) {
                // @ts-ignore
                dependentIds = values[key][key + '-dependents'].map((dependentId) => {
                    return (dependentId.substring(dependentId.indexOf('-') + 1));
                });
            }
            const answer = {
                questionCode: key,
                role: 'dependent',
                // @ts-ignore
                value: values[key].dependent,
                dependentIds
            };
            shouldCallForReflexiveQuestions ? shouldCallForReflexiveQuestions(values[key].dependent, key) : undefined;
            answers.push(answer);
        }
        // @ts-ignore
        if (values[key].all !== undefined) { // these are the footerQuestions - answered once for all roles
            const answer = {
                questionCode: key,
                role: 'EMPTY',
                // @ts-ignore
                value: values[key].all,
            };
            answers.push(answer);
        }
    });
    return answers;
};
/**
 * Qualifying Questions Page - part 1 - non-reflexive questions sourced from Initial Information
 * Handles building the appropriate Sections and submit logic of the form
 * Either skips the part 2 if no yes respones, or calls the
 * eoi service for the next question set and moves to part 2
 * @param IStatementOfHealthProps
 */
const StatementOfHealth = ({ nextStep }) => {
    const { t } = useTranslate();
    const { authHeader } = useAuthentication();
    const stepper = useContext(StepperContext);
    const [loading, setLoading] = useState(false);
    const { setDisplaySaveForLaterError, setDisplaySaveForLaterModal, setValidationError, setReflexiveAnswers } = useEoiContext();
    const { trackClickWithPage } = useGoogleAnalytics();
    const { trackView } = useTealium();
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.EOI,
            page_l4: TEALIUM_PAGE_VIEW.PAGE_L4.EOI.QUALIFYING_QUESTIONS,
        });
    }, []);
    const { initialInformation, interviewAnswers, setMedicalInformationAnswers, setCriticalIllnessAnswers, setReflexiveQuestions } = useEoiContext();
    const savePageToProvider = (values) => {
        let callForReflexiveQuestions = false;
        const shouldCallForReflexiveQuestions = (value, key) => {
            if (key !== 'DISCL ' && key !== 'NWSMOK' && key !== 'HAIDS' && key !== 'HDRUGS' && value) {
                callForReflexiveQuestions = true;
            }
        };
        let criticalIllnessAnswers = [];
        let medicalInformationAnswers = [];
        // @ts-ignore
        if (values.criticalIllness) {
            criticalIllnessAnswers = buildAnswerPayload(values.criticalIllness);
            setCriticalIllnessAnswers(criticalIllnessAnswers);
        }
        if (values.medicalInformation) {
            medicalInformationAnswers = buildAnswerPayload(values.medicalInformation, shouldCallForReflexiveQuestions);
            setMedicalInformationAnswers(medicalInformationAnswers);
        }
    };
    const submit = async (values) => {
        setLoading(true);
        setValidationError(false);
        setDisplaySaveForLaterError(false);
        setDisplaySaveForLaterModal(false);
        let callForReflexiveQuestions = false;
        const shouldCallForReflexiveQuestions = (value, key) => {
            if (key !== 'DISCL ' && key !== 'NWSMOK' && key.trim() !== 'HAIDS' && key !== 'HDRUGS' && key !== 'HELCA5' && value) {
                callForReflexiveQuestions = true;
            }
        };
        let criticalIllnessAnswers = [];
        let medicalInformationAnswers = [];
        // @ts-ignore
        if (values.criticalIllness) {
            criticalIllnessAnswers = buildAnswerPayload(values.criticalIllness);
            setCriticalIllnessAnswers(criticalIllnessAnswers);
        }
        if (values.medicalInformation) {
            medicalInformationAnswers = buildAnswerPayload(values.medicalInformation, shouldCallForReflexiveQuestions);
            setMedicalInformationAnswers(medicalInformationAnswers);
        }
        if (callForReflexiveQuestions) {
            // TODO: @peter - move this to the provider? or deal with the erro on this page? could be some delay here
            // possibly need to add a loading boolean to StepperButtons so the contineu button can spin while stuff happens
            try {
                const response = await requestReflexiveQuestions(medicalInformationAnswers, authHeader());
                if (response.data.employee.length === 0 && response.data.spouse.length === 0 && response.data.dependents.length === 0) {
                    setReflexiveAnswers({}); // Clear any reflexive answers that may have been on the form.
                    trackClickWithPage('continue', '/customer/eoi/qualifyingQuestions');
                    if (stepper.isEditing()) {
                        stepper.completeEdit();
                    }
                    else {
                        stepper.next();
                    }
                }
                else {
                    setReflexiveQuestions(response.data);
                    nextStep();
                }
            }
            catch (error) {
                // TODO: catch and display an error
            }
        }
        else {
            setReflexiveAnswers({}); // Clear any reflexive answers that may have been on the form.
            trackClickWithPage('continue', '/customer/eoi/qualifyingQuestions');
            if (stepper.isEditing()) {
                stepper.completeEdit();
            }
            else {
                stepper.next();
            }
        }
        setLoading(false);
    };
    let disclosureQuestion;
    let smokingQuestion;
    let medicalDescription;
    const medicalQuestions = [];
    let criticalIllnessQuestions = [];
    let criticalIllnessFooterQuestions = [];
    let criticalIllnessHeaderNote;
    let criticalIllnessFooterNote;
    // These functions a bit redundant but run quickly through coverages to get the booleans we need for display conditions
    const ciEmployeeCoverage = hasCIEmployeeCoverageSelected(interviewAnswers);
    const ciSpouseCoverage = hasCISpouseCoverageSelected(interviewAnswers);
    const onlyCIcoverage = hasOnlyCICoverageSelected(interviewAnswers);
    const employeeCIOnly = isApplicantCICoverageOnly('employee', interviewAnswers);
    const spouseCIOnly = isApplicantCICoverageOnly('spouse', interviewAnswers);
    if (initialInformation) {
        initialInformation.medicalQuestions.forEach((question) => {
            if (question.questionCode.indexOf('DISCL') > -1) {
                disclosureQuestion = question;
            }
            else if (question.questionCode.indexOf('NWSMOK') > -1) {
                smokingQuestion = question;
            }
            else if (question.questionCode === 'HELTH ') {
                medicalDescription = question.text;
            }
            else {
                medicalQuestions.push(question);
            }
        });
        if (ciEmployeeCoverage || ciSpouseCoverage) {
            criticalIllnessQuestions = initialInformation.criticalIllnessQuestions;
            criticalIllnessFooterQuestions = initialInformation.criticalIllnessOnlyQuestions;
            criticalIllnessHeaderNote = initialInformation.criticalIllnessHeaderNote;
            criticalIllnessFooterNote = initialInformation.criticalIllnessFooterNote;
        }
    }
    const onSubmitFailure = (errors) => {
        trackClickWithPage('continue', '/customer/eoi/qualifyingQuestions');
        setValidationError(true);
        window.scrollTo(0, 0);
    };
    return (<Form onSubmit={submit} onSubmitFailure={onSubmitFailure} data-public={true}>
      <Loader id='qualifying-med-questions-spinner' isShown={loading}>
        <ValidationErrorAlert />
        <Heading elemType={'h2'} color={'primary'} theme={themes.lincoln} data-testid={'welcome-heading'} style={{ marginTop: '1.875rem' }}>
          {isSitusStateNY(initialInformation) ? t(EoiGeneralText.statementOfHealthStep) : t(EoiGeneralText.qualifyingQuestionsStep)}
        </Heading>
        {!isSitusStateNY(initialInformation) && <p data-testid='subheading' style={{ marginBottom: '1.875rem' }}>{t(QuestionsText.qualifyingQuestionsSubheading)}</p>}
        {/* @ts-ignore */}
        <Scope scope='medicalInformation'>
          {disclosureQuestion &&
            <QuestionSection heading={QuestionsText.agreementOfTerms} yesOnly={true} questions={[disclosureQuestion]}/>}
          {smokingQuestion &&
            <QuestionSection heading={QuestionsText.tobaccoOrNicotine} questions={[smokingQuestion]}/>}
          {!onlyCIcoverage && medicalDescription && medicalQuestions.length > 0 &&
            <QuestionSection heading={QuestionsText.medicalInformation} tableHeading={QuestionsText.medicalInformationHeading} description={medicalDescription} questions={medicalQuestions} hideEmployee={employeeCIOnly} hideSpouse={spouseCIOnly} data-private={true}/>}
        </Scope>
        {criticalIllnessQuestions.length > 0 &&
            <>
            {/* @ts-ignore */}
            <Scope scope='criticalIllness'>
              <QuestionSection heading={QuestionsText.criticalIllness} tableHeading={QuestionsText.criticalIllnessHeading} headerNote={criticalIllnessHeaderNote} footerNote={criticalIllnessFooterNote} description={!isSitusStateNY(initialInformation) ? QuestionsText.criticalIllnessDescription : QuestionsText.criticalIllnessDescriptionNY} questions={criticalIllnessQuestions} footerQuestions={criticalIllnessFooterQuestions} hideEmployee={!ciEmployeeCoverage} hideSpouse={!ciSpouseCoverage} hideDependents={true}/>
            </Scope>
          </>}
        <SaveForLaterErrorAlert />
        <LegalFormNumbers showSupplementalForm={false}/>
        <StepperButtons continueButtonType={'continue'} saveToProvider={savePageToProvider} page={'/customer/eoi/qualifyingQuestions'}/>
      </Loader>
    </Form>);
};
export default StatementOfHealth;
