import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage, useExperience } from 'status/hooks';
import getProductAttributeCode from 'status/utilities/getProductAttributeCode';
import getLeaveStatusDefinition from 'status/utilities/getLeaveStatusDefinition';
import LeaveSpecialist from './LeaveSpecialist';
import { encode } from 'status/utilities/hash';
import { downloadLeaveAppliedReport } from './downloadReport';
import Glossary from 'status/components/Glossary/Glossary';
import { Alert, Col, Row } from '@digitools/honest-react';
import useTealium from '../../../../../hooks/useTealium';
import { TEALIUM_EVENT } from '../../../../../constants/tealiumConstants';
import LeaveInfoCard from './LeaveInfoCard';

const Error = ({ error }) => (
  <Alert type={'error'}>
    {error.response && error.response.status === 404 ? (
      'No leave found.'
    ) : (
      <span>
        <strong>Error!</strong> Something went wrong when loading leave details.
      </span>
    )}
  </Alert>
);

const BasicInfo = ({
  params,
  leave,
  leaveDetails,
  empNum,
  prodAttCodes,
  statusDefinitionList,
  leaveReports,
  name,
  updateStatus,
  leaveId,
}) => {
  const { language } = useLanguage();
  const { experience } = useExperience();
  const { t } = useTranslation();
  const { trackEvent } = useTealium();

  const print = () => {
    trackEvent({
      event_action: TEALIUM_EVENT.EVENT_ACTION.DOWNLOAD,
      event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
      event_name: 'leave time applied report',
      event_version: 'details'
    });
    downloadLeaveAppliedReport(leaveReports, language, prodAttCodes, name, t);
  };

  const status = getProductAttributeCode(language, prodAttCodes, 'Leave', 'Status', leave.lveSttusCde);
  updateStatus({ status: status });
  const type = getProductAttributeCode(language, prodAttCodes, 'Leave', 'Type', leave.lveType);

  const reason = getProductAttributeCode(language, prodAttCodes, 'Leave', 'Reason', leave.lveReas);

  const category = getProductAttributeCode(language, prodAttCodes, 'Leave', 'Category', leave.lveCatg);

  const statusDef = getLeaveStatusDefinition(
    language,
    statusDefinitionList,
    leave.lveType,
    leave.lveSttusCde,
    leave.lveSttusReasCde,
  );

  const expPrefix = experience === 'EE' ? 'employee' : 'employer';

  const associatedClaim = {};

  if (leave && leave.coordClmNum && leave.coordClmNum !== '0' && leaveDetails && leaveDetails.coord_clm_num != 0) {
    associatedClaim.claimNum = leave.coordClmNum;
    associatedClaim.to = `/status/${expPrefix}/${encode(empNum)}/claims/${encode(leave.coordClmNum)}/details`;
    if (params.search) {
      associatedClaim.to = `${associatedClaim.to}?search=${params.search}&searchCrit=${params.searchCrit}`;
    }
    if (params.from) {
      associatedClaim.to = `${associatedClaim.to}?from=${params.from}`;
    }
  }

  return (
    <LeaveInfoCard 
      leave={leave}
      associatedClaim={associatedClaim}
      status={status}
      type={type}
      reason={reason}
      category={category}
      statusDef={statusDef}
      print={print}
      leaveReports={leaveReports}
      leaveId={leaveId}
      empNum={empNum}
    />
  );
};

const LeaveInfo = ({
  leave,
  params,
  error,
  empNum,
  prodAttCodes,
  statusDefinitionList,
  leaveDetails,
  leaveId,
  leaveReports,
  name,
  updateStatusCallback,
}) => {

  if (error) {
    return <Error error={error} />;
  }

  const updateStatus = status => {
    if (updateStatusCallback) {
      updateStatusCallback(status);
    }
  };

  return (
    <Row style={{ marginBottom: '1rem', marginTop: '1.5rem' }}>
      <Col md={8} xs={12}>
        <BasicInfo
          leaveReports={leaveReports}
          leave={leave}
          name={name}
          leaveDetails={leaveDetails}
          params={params}
          empNum={empNum}
          statusDefinitionList={statusDefinitionList}
          prodAttCodes={prodAttCodes}
          updateStatus={updateStatus}
          leaveId={leaveId}
        />
      </Col>
      <Col md={4} xs={12}>
        <LeaveSpecialist
          specialistName={leaveDetails && leaveDetails.lve_analy_asgn_name}
          offNum={leaveDetails && leaveDetails.usr_off_num}
          leaveNum={leaveId}
          employeeName={name}
        />
        <Glossary />
      </Col>
    </Row>
  );
};

export default LeaveInfo;
