import { Alert, Button, Collapse, Icon, Spinner } from '@digitools/honest-react';
import React, { useContext, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import getProductAttributeCode from 'src/packages/status/utilities/getProductAttributeCode';
import useExperience from 'src/hooks/useExperience';
import useAuthentication from 'src/hooks/useAuthentication';
import getClaimStatusDefinition from 'src/packages/status/utilities/getClaimStatusDefinition';
import checkDate from 'src/packages/status/utilities/checkDate';
import Card from '../../../../../components/Cards/Card';
import ReviewField from 'src/packages/cli/pages/review/ReviewField'; // TODO: make into an honest component - AS has copy/paste versions that must be cleaned up
import { encode } from 'status/utilities/hash';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import styles from './CoverageDetails.scss';
import getLeaveStatusDefinition from 'src/packages/status/utilities/getLeaveStatusDefinition';
import { compareByField } from 'src/utils/SortUtils';
import StatusBadge, { BadgeButton, getStatusColor } from 'src/components/Cards/StatusBadge';
import styled from 'styled-components';
import StatusText from 'src/packages/status/constants/StatusText';
import useTranslate from 'src/hooks/useTranslate';
import useStatusDataContext from 'src/packages/status/hooks/useStatusDataContext';
import { RtwContext } from 'src/packages/status/context';
import { Experience } from 'src/types/Experience';
const { STATUS, LINKS, ALERTS } = StatusText;
//TODO: move into some shared place for components
export const StatusMessage = styled('div') `
  color: ${({ theme }) => theme?.palette?.gray}
  font-Style: italic;
  font-size: .9rem;
  margin-left: .5rem;
  margin-right: .5rem;
  margin-top: .75rem;
  margin-bottom: .75rem;
}
`;
//FIXME: text underline not working here
const LinkIcon = styled(Icon) `
  &:hover {
    text-decoration: none !important;
  }
`;
/**
 * Helper function for if rtw is configured for this user's customer & their current experience
 *
 * @param experience
 * @param user
 * @returns
 */
const hasRtwConfigured = (experience, user) => {
    const applicationPermissions = user?.applicationPermissions;
    if (experience === 'Employer') {
        return applicationPermissions?.indexOf('statusER.claimLeaveStatus.displayRtw') > -1;
    }
    else {
        return applicationPermissions?.indexOf('statusEE.claimLeaveStatus.displayRtw') > -1;
    }
};
/**
 * RTW only shows on in progress, approved, pending or pending events - basically aything but denied or closed.
 * RTW only shows for leaves in similar statuses, and only if they are continuous leaves without a coordinated claim number
 * should also just be on the back end and have the event give us a boolean for this
 *
 * @param event
 */
const shouldShowRtwForEvent = (event) => {
    // these make the logic too complicated - but are how statuses are in the backend separated out
    // let docsNetStatusEn = ['In Progress', 'Open', 'Pended'];
    // let docsNetStatusEs = ['En Progreso', 'Abierto', 'Pendiente'];
    // let s1NetStatusEn = ['Pending', 'Approved', 'Pended'];
    // let s1NetStatusEs = ['Pendiente', 'Aprobadoo'];
    // let leaveDocsnetEn = ['Pending', 'Approved'];
    // let leaveDocsnetEs = ['Pendiente', 'Aprobadoo'];
    // combined all into one list of approved statuses
    let statusesToShow = ['In Progress', 'Open', 'Pended', 'Pending', 'Approved', 'En Progreso', 'Abierto', 'Pendiente', 'Aprobadoo']; //Aprobadoo may be type but just in case
    if (statusesToShow.includes(event.status)) {
        if (event.product === 'Leave') {
            // only shows on continuous leaves without a coordinated claim number
            if (event.crdClm === '0' && event.leaveCategory === 'Continuous') {
                return true;
            }
        }
        else {
            // nothing else to check for claims
            return true;
        }
    }
};
const getCoverageName = (coverage) => {
    switch (coverage.trim()) {
        case 'STD':
            // case 'STDCP': //pending
            return 'Short term disability';
        case 'LTD':
            // case 'LTDCP': //pending
            return 'Long term disability';
        case 'STAT':
            return 'Statutory Disability';
        case 'PLNY':
        case 'PLVA':
        case 'PLVB':
        case 'PLCT':
        case 'PLCO':
        case 'PLOR':
        case 'PLCA':
        case 'MAPL':
            return 'Paid leave';
        // case 'SALCN': // not yet useed
        //   return 'Salary continuation'
        default:
            return coverage.trim();
    }
};
const EVENT_INCREMENT = 3;
const StyledCardLink = styled(Link) `
  display: flex;
  gap: .25rem;
`;
const StyledCardLinkContainer = styled.div `
  display: flex;
  row-gap: .75rem;
  column-gap: 1.5rem;
  flex-wrap: wrap;
`;
const ClaimsAndLeaves = ({ claims, leaves, error }) => {
    const { empNum, from } = useParams();
    const { productAttributeCodes, statusDefinitionList } = useStatusDataContext();
    const { language, experience } = useExperience();
    const { user } = useAuthentication();
    const { t } = useTranslate();
    const { trackEvent } = useTealium();
    //TODO: make sure this works & updates responsivly
    let mobile = window.matchMedia("(max-width: 768px)");
    const [numEventsToDisplay, setNumEventsToDisplay] = useState(EVENT_INCREMENT + (!mobile.matches ? 3 : 0)); // initially show 7 on desktop
    const navigate = useNavigate();
    const [filter, setFilter] = useState('all');
    const [sortBy, setSortBy] = useState({ field: 'receivedDate', ascending: false });
    const [selectedEvent, setSelectedEvent] = useState();
    //@ts-ignore -- this could be removed in favor of session storage only - or vice versa
    const { saveRtwNavigation } = useContext(RtwContext);
    if (!empNum || claims?.length === 0 && leaves?.length === 0) {
        return <Alert type={'info'}>{t(ALERTS.noClaimsOrLeaves)}</Alert>;
    }
    if (error && error.response.status !== 404) {
        return <Alert type={'error'}>{experience === Experience.EMPLOYEE ? t(ALERTS.fetchClaimsErrorEE) : t(ALERTS.fetchClaimsErrorER)}</Alert>;
    }
    if (!user) {
        return <Spinner />;
    }
    /****** FORMATTING CODE -- TODO: THIS SHOULD BE IN THE BACK END!!! *************
    * need to spin up a ticket to do that work, way too much going on here
    * translate this properly - currently formats into english or spanish then other logic here in ui is based on these values
    * should provide UI with display (translated) value and only send codes if they're meant to be displayed
    */
    let formattedData = useMemo(() => {
        let data = [];
        // Format claims
        if ((claims && filter === 'all') || filter === 'claims') {
            claims.forEach(claim => {
                const status = getProductAttributeCode(language, productAttributeCodes, 'Claim', 'Status', claim.clmSttusCde, user.customerInContext?.smallMid);
                const statusDefinition = getClaimStatusDefinition(language, statusDefinitionList, claim.prodType, claim.clmSttusCde, claim.clmClosureReasCde, claim.clmPendReasCde, user.customerInContext?.smallMid);
                const formattedClaim = {
                    product: 'Claim',
                    eventId: claim.clmNum,
                    receivedDate: claim.clmRecdDte && claim.clmRecdDte.split(' ')[0],
                    determinationDate: checkDate(claim.clmDtrmDte), // TODO: clean up as part of formatting in back end
                    coverageCode: claim.prodType,
                    coverage: getCoverageName(claim.prodType),
                    status,
                    statusCode: claim.clmSttusCde,
                    statusDefinition,
                    empNum,
                    url: 'claims',
                };
                data.push(formattedClaim);
            });
            /*** FOR TESTING & DEMO PURPOSES - DELETE LATER */
            // const dummyClaim = {
            //   product: 'Claim',
            //   event: 12345678,
            //   receivedDate: '06/06/2024',
            //   determinationDate: '06/26/2024',
            //   coverageCode: 'STAT',
            //   coverage: getCoverageName('STAT'),
            //   status: 'Approved',
            //   statusDefinition: 'Your claim has been processed and approved.',
            //   uploadDocuments: '',
            //   empNum,
            //   url: 'claims',
            //   // ...params,
            // };
            // data.push(dummyClaim)
        }
        // Format Leaves
        if ((leaves && filter === 'all') || filter === 'leaves') {
            leaves.forEach(leave => {
                const status = getProductAttributeCode(language, productAttributeCodes, 'Leave', 'Status', leave.lveSttusCde);
                const statusDefinition = getLeaveStatusDefinition(language, statusDefinitionList, leave.lveType, leave.lveSttusCde, leave.lveSttusReasCde);
                const formattedLeave = {
                    product: 'Leave',
                    eventId: leave.lveNum,
                    receivedDate: leave.lveRecdDte && leave.lveRecdDte.split(' ')[0],
                    determinationDate: checkDate(leave.lveDtrmDte),
                    coverage: 'Leave',
                    statusCode: leave.lveSttusCde,
                    status,
                    statusDefinition,
                    uploadDocuments: '',
                    empNum,
                    url: 'leaves',
                    crdClm: leave.coordClmNum,
                    leaveCategory: getProductAttributeCode(language, productAttributeCodes, 'Leave', 'Category', leave.lveCatg),
                };
                data.push(formattedLeave);
            });
            /*** FOR TESTING & DEMO PURPOSES - DELETE LATER */
            // const dummyLeave = {
            //   product: 'Leave',
            //   event: 23456890,
            //   receivedDate: '07/14/2023',
            //   determinationDate: '07/20/2023',
            //   coverageCode: 'PLNY',
            //   coverage: 'Leave',
            //   status: 'Approved',
            //   statusDefinition: 'Your claim has been processed and approved.',
            //   uploadDocuments: '',
            //   empNum,
            //   url: 'claims',
            //   // ...params,
            // };
            // data.push(dummyClaim)
        }
        return data;
    }, [leaves, claims, filter]);
    const handleCardClick = (event, e) => {
        // setSelectedEvent(event.eventId); // not really used any more - could delay after to show animations
        //TODO: this should probably be a link around the card vs. programmatic
        // ?? TBD: original link had encode around empNum
        let to = `/status/${experience}/${event.empNum}/${event.url}/${encode(event.eventId)}/details`;
        if (from === 'teamView') {
            to = `${to}?from=teamView`;
        }
        const statusNav = window.sessionStorage.getItem('statusNav');
        console.log(statusNav);
        let eventType = event.eventId;
        if (statusNav === 'rtw') {
            eventType = StatusText.LINKS.returnToWork.english;
        }
        navigate(to);
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.LINK,
            event_type: TEALIUM_EVENT.EVENT_TYPE.VIEW_STATUS,
            event_name: eventType,
            event_results: event.product.toLowerCase(),
            event_version: 'details',
        });
    };
    // so the to='' prop can actually be used
    const getLinkUrl = (event) => {
        let url = '';
        let to = `/status/${experience}/${event.empNum}/${event.url}/${encode(event.eventId)}/details`;
        if (from === 'teamView') {
            to = `${to}?from=teamView`;
        }
        return url;
    };
    /**
     *  Flips sort if you click the already active sort field, otherwise sets it to ascending
     * @param newSort
     */
    const changeSort = (newSort) => {
        setSortBy({ field: newSort, ascending: sortBy.field === newSort ? !sortBy.ascending : true });
    };
    let sortedData = [];
    if (formattedData && formattedData?.length > 0) {
        sortedData = formattedData.toSorted(compareByField(sortBy.field, sortBy.ascending));
    }
    const Heading = (coverage, coverageCode) => {
        return (<div data-private={true}>{`${coverage} ${coverageCode && !user?.customerInContext?.smallMid ? `(${coverageCode.trim()})` : ''}`}</div>);
    };
    return (<>
      <Collapse title='Sort & filter'>
        {/* TODO: export these styles/inlines into styled components or classes -- entire filter box should be its own component(s) - props:  filter, setFilter */}
        <div style={{ display: 'flex', gap: '.5rem', flexWrap: 'wrap', padding: '0' }}>
          <div className={styles.allTableFilterGroup} style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
            <span style={{
            position: 'absolute',
            backgroundColor: 'white',
            marginTop: '-1.33rem',
            padding: '0 .25rem 0 .25rem',
            fontWeight: 'bold',
        }}>
              Filter:
            </span>
            <BadgeButton backgroundColor={filter === 'all' ? 'secondaryDark' : 'grayLight'} onClick={() => setFilter('all')}>
              All
            </BadgeButton>
            <BadgeButton backgroundColor={filter === 'claims' ? 'secondaryDark' : 'grayLight'} onClick={() => setFilter('claims')}>
              Claims
            </BadgeButton>
            <BadgeButton backgroundColor={filter === 'leaves' ? 'secondaryDark' : 'grayLight'} onClick={() => setFilter('leaves')}>
              Leaves
            </BadgeButton>
          </div>
          {/* TODO: export these styles/inlines into styled components or classes  -- entire sort box should be its own component(s) - props:  sortBy object, setSortBy (include changeSort in it) */}
          <div className={styles.allTableFilterGroup} style={{ display: 'flex', gap: '.5rem', flexWrap: 'wrap' }}>
            <span style={{
            position: 'absolute',
            backgroundColor: 'white',
            marginTop: '-1.33rem',
            padding: '0 .25rem 0 .25rem',
            fontWeight: 'bold',
        }}>
              Sort:
            </span>
            <BadgeButton onClick={() => changeSort('eventId')} backgroundColor={sortBy.field === 'eventId' ? 'secondaryDark' : 'grayLight'}>
              Event #{' '}
              <Icon color='white' style={{ fontSize: '.75rem' }} name={sortBy.ascending ? 'long-arrow-up-regular' : 'long-arrow-down-regular'} size='small'/>
            </BadgeButton>
            <BadgeButton onClick={() => changeSort('coverage')} backgroundColor={sortBy.field === 'coverage' ? 'secondaryDark' : 'grayLight'}>
              Product
              <Icon color='white' style={{ fontSize: '.75rem' }} className='ml-2' name={sortBy.ascending ? 'long-arrow-up-regular' : 'long-arrow-down-regular'}/>
            </BadgeButton>
            <BadgeButton onClick={() => changeSort('receivedDate')} backgroundColor={sortBy.field === 'receivedDate' ? 'secondaryDark' : 'grayLight'}>
              Rec. Date
              <Icon color='white' style={{ fontSize: '.75rem' }} className='ml-2' name={sortBy.ascending ? 'long-arrow-up-regular' : 'long-arrow-down-regular'}/>
            </BadgeButton>
            <BadgeButton onClick={() => changeSort('determinationDate')} backgroundColor={sortBy.field === 'determinationDate' ? 'secondaryDark' : 'grayLight'}>
              Det. Date
              <Icon color='white' style={{ fontSize: '.75rem' }} className='ml-2' name={sortBy.ascending ? 'long-arrow-up-regular' : 'long-arrow-down-regular'}/>
            </BadgeButton>
            <BadgeButton onClick={() => changeSort('status')} backgroundColor={sortBy.field === 'status' ? 'secondaryDark' : 'grayLight'}>
              Status
              <Icon className='ml-2' color='white' style={{ fontSize: '.75rem' }} name={sortBy.ascending ? 'long-arrow-up-regular' : 'long-arrow-down-regular'}/>
            </BadgeButton>
          </div>
        </div>
      </Collapse>
      <br />
      <div id='claims&leaves-container' style={{ width: '100%', }}>
          {sortedData.map((item, i) => {
            if (i >= numEventsToDisplay) {
                return <></>;
            }
            return (<>
                  <Card tabIndex={0} data-testid={'event'} key={item.eventId} heading={Heading(item.coverage, item.coverageCode)} secondaryHeading={<StatusBadge status={item.status}>{item.status}</StatusBadge>} selectable={true} selected={item.eventId === selectedEvent} onClick={(e) => handleCardClick(item, e)} fontSize='large' elemType='h2' small={true} borderColor={getStatusColor(item.status)} cardLinks={<>
                        <StyledCardLinkContainer>
                          {/* TODO: accessibility - do these links need to have the url they'll send you to? (and not rely on wrapping Link onClick behavior) */}
                          <StyledCardLink to='' onClick={() => window.sessionStorage.setItem('statusNav', 'upload')}>
                            {t(LINKS.uploadDocuments)} <LinkIcon color='secondary' name='upload'/>
                          </StyledCardLink>
                          {hasRtwConfigured(experience, user) && shouldShowRtwForEvent(item) && (<StyledCardLink to='' onClick={() => {
                            window.sessionStorage.setItem('statusNav', 'rtw');
                            saveRtwNavigation(true);
                        }}>
                              {t(LINKS.returnToWork)}
                              <LinkIcon color='secondary' name='calendar'/>
                            </StyledCardLink>)}
                          <StyledCardLink to=''>
                            {t(LINKS.viewDetails)}
                            <LinkIcon className={styles.iconClass} color='secondary' style={{ fontSize: '.75rem', marginTop: '.25rem' }} name='long-arrow-right-regular'/>
                          </StyledCardLink>
                        </StyledCardLinkContainer>
                      </>}>
                    {item.statusDefinition && <StatusMessage><Icon size='sm' name='important-message' color='grayDark' className='ml-2 mr-2'/>{item.statusDefinition}</StatusMessage>}
                    <div style={{ display: 'flex', flexWrap: 'wrap', rowGap: '.5rem', columnGap: '.75rem', }}>
                      {/* @ts-ignore //FIXME: ReviewField doesn't like being styled - fix or fold this into the component */}
                      <ReviewField small label={'Event No.'} value={item.eventId} style={{ minWidth: '5rem', flexGrow: '1', }}/>
                      {/* @ts-ignore */}
                      <ReviewField small label={'Received date'} value={item.receivedDate} style={{ minWidth: '5rem', flexGrow: '1', }}/>
                       {/* @ts-ignore */}
                      <ReviewField small label={'Determination date'} value={item.determinationDate} style={{ minWidth: '5rem', flexGrow: item.leaveCategory ? '1' : '10', }}/>
                      {/* style={{flexGrow: '1', flexShrink: '1'}} */}
                      {/* @ts-ignore */}
                      {item.leaveCategory && <ReviewField small label={'Leave category'} value={item.leaveCategory} style={{ flexGrow: '8', }}/>}
                    </div>
                  </Card>
              </>);
        })}
          <div id='showMoreContainer' style={{ display: 'flex', justifyContent: 'center' }}>
          {numEventsToDisplay < sortedData.length && (<Button buttonType='text' onClick={() => setNumEventsToDisplay(numEventsToDisplay + (EVENT_INCREMENT + (!mobile.matches ? 3 : 0)))}>
              Show more
            </Button>)}
          {numEventsToDisplay >= sortedData.length && numEventsToDisplay > (EVENT_INCREMENT + (!mobile.matches ? 3 : 0)) && (<Button buttonType='text' onClick={() => setNumEventsToDisplay(numEventsToDisplay - (EVENT_INCREMENT + (!mobile.matches ? 3 : 0)))}>
              Show less
            </Button>)}
          </div>
        </div>
      <hr />
    </>);
};
export default ClaimsAndLeaves;
