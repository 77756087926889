import React, { useState } from 'react';
import { Button, Col, Row } from '@digitools/honest-react';
import { useTranslation } from 'react-i18next';
import Card from '../../../../../components/Cards/Card';
import ReviewField from '../../../../cli/pages/review/ReviewField';
import checkDate from '../../../utilities/checkDate';
import titleCase from '../../../utilities/titleCase';
const EVENT_INCREMENT = 6;
const LeaveBalanceCard = ({ leaveBalanceList, }) => {
    const { t } = useTranslation();
    const [numEventsToDisplay, setNumEventsToDisplay] = useState(EVENT_INCREMENT);
    const Heading = (docType, docTitle) => {
        return <>{docType}&nbsp;-&nbsp;{docTitle}</>;
    };
    return (<div id='LeaveBalance-container' style={{ width: '100%', display: 'flex', marginBottom: '1rem' }}>
        <div id='leaveBalance-content' style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
          {leaveBalanceList.map((leaveBalance, i) => {
            if (i >= numEventsToDisplay) {
                return <></>;
            }
            return (<>
                <Card data-testid={`leaveBalanceCard`} key={`leaveBalanceCard`} heading={Heading(leaveBalance.program, leaveBalance.lve_pgm_cde)} fontSize='large' small={true}>
                  <Row>
                    <Col sm={6} md={3} size={6} className='mb-2'>
                      {/* @ts-ignore */}
                      <ReviewField label={titleCase(t('programHoursUsedText'))} value={leaveBalance.hoursUsed}/>
                    </Col>
                    <Col sm={6} md={leaveBalance.program !== "Federal" ? 2 : 3} size={6} className='mb-2'>
                      {/* @ts-ignore */}
                      <ReviewField data-testid={'programHoursRemainingText'} label={titleCase(t('programHoursRemainingText'))} value={leaveBalance.hoursRemain}/>
                    </Col>
                    <Col sm={6} md={1} size={6} className='mb-2'>
                      {/* @ts-ignore */}
                      <ReviewField label={titleCase(t('qualified'))} value={leaveBalance.lvePgmQualdCde === 'QF' ? 'Yes' : 'No'}/>
                    </Col>
                    <Col sm={6} md={2} size={6} className='mb-2'>
                      {/* @ts-ignore */}
                      <ReviewField label={titleCase(t('qualifiedThrough'))} value={checkDate(leaveBalance.lvePgmQualdThruDte)}/>
                    </Col>
                    <Col sm={6} md={1} size={6} className='mb-2'>
                      {/* @ts-ignore */}
                      <ReviewField label={titleCase(t('eligible'))} value={leaveBalance.lvePgmEligCde === 'EL' ? 'Yes' : 'No'}/>
                    </Col>
                    <Col sm={6} md={2} size={6} className='mb-2'>
                      {/* @ts-ignore */}
                      <ReviewField label={titleCase(t('eligibleThrough'))} value={checkDate(leaveBalance.lvePgmEligThruDte)}/>
                    </Col>
                    {leaveBalance.program !== "Federal" &&
                    <Col sm={12} md={1} size={12} className='mb-2'>
                        {/* @ts-ignore */}
                        <ReviewField label={titleCase(t('state'))} value={leaveBalance.lvePgmStateCde}/>
                      </Col>}
                  </Row>
                </Card>
              </>);
        })}
          {numEventsToDisplay < leaveBalanceList?.length && (<Button style={{ justifyContent: 'center' }} buttonType='text' onClick={() => setNumEventsToDisplay(numEventsToDisplay + (EVENT_INCREMENT))}>
              Show more
            </Button>)}
          {numEventsToDisplay >= leaveBalanceList?.length && numEventsToDisplay > (EVENT_INCREMENT) && (<Button style={{ justifyContent: 'center' }} buttonType='text' onClick={() => setNumEventsToDisplay(numEventsToDisplay - (EVENT_INCREMENT))}>
              Show less
            </Button>)}
        </div>
      </div>);
};
export default LeaveBalanceCard;
